import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import LogoImg from "../../assets/images/svg/logo.svg"

import { setSelectedUserGroupId } from "../../redux/reducer/authReducer";


//style
import Styles from './style'
import { EmailIcon, KeyIcon } from "../../assets/svg";

//Components
import { Button, TextField, Link } from "../../components";

//helpers
import { commonValidation, emailToLower, getLoggedInUser, isEmailValid, isRequired } from "../../helpers";

// epic
import { loginRequest } from "../../redux/reducer/authReducer";
import { Visibility, VisibilityOff } from "@mui/icons-material";


function Login(props) {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { className } = props;

    // reducer
    const { isLoggedIn } = useSelector(s => (s.auth))

    //set state
    const [validate, setValidate] = useState(false);
    const [password, showPassword] = useState(false);
    const [loginForm, setLoginForm] = useState({
        email: "",
        password: ""
    });

    //submit
    const handleSubmit = () => {
        //get validations
        const validation = commonValidation(loginForm, "login");

        setValidate(true)
        if (!validation) {
            dispatch(loginRequest({ ...loginForm, email: emailToLower(loginForm.email), user_group_id: ["2", "3"] }))
            setValidate(false)
        }
    }

    // redirect to application if logged in
    const userDetails = getLoggedInUser();
    if (isLoggedIn && userDetails) {
        if (userDetails.user_group === "CITY_STAFF") {
            return <Navigate to="/dashboard" />
        } else {
            return <Navigate to="/dashboard" />
        }
    }


    return (
        <Grid className={`${className} loginpageCont`}>
            <Grid className="logoContainer">
                <img src={LogoImg} className="Jersey City Logo" alt="Logo" />
                <Typography variant="h2">
                    Welcome to the<br /> City of Jersey City
                </Typography>
            </Grid>
            <form>
                <TextField
                    id={"email"}
                    name={"email"}
                    placeholder={"Email ID"}
                    aria-label="Enter email id"
                    startIcon={<EmailIcon />}
                    value={loginForm.email}
                    handleChange={(e) => {
                        setLoginForm({ ...loginForm, [e.name]: e.value ? e.value.trim() : "" })
                    }}
                    validation={isEmailValid(validate, loginForm.email)}
                />
                <TextField
                    id={"password"}
                    name={"password"}
                    type={password ? "text" : "password"}
                    value={loginForm.password}
                    className={"pt-2"}
                    placeholder={"Password"}
                    aria-label="Enter Password"
                    startIcon={<KeyIcon />}
                    handleChange={(e) => {
                        setLoginForm({ ...loginForm, [e.name]: e.value })
                    }}
                    validation={isRequired(validate, loginForm.password)}
                    InputProps={
                        {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <KeyIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => { showPassword(!password) }}
                                        edge="end"
                                    >
                                        {password ? <Tooltip arrow title="Hide Password"><Visibility /></Tooltip> : <Tooltip arrow title="Show Password"><VisibilityOff /></Tooltip>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    }
                />
                <Grid  container  justifyContent={"space-between"} alignItems="center" className="pt-4">
                    <Button variant="contained" size="large" type='submit' className={"loginBtn"}
                         onClick={(e) => { e.preventDefault(); handleSubmit    () }} aria-label="Login">
                            Login
                    </Button>
                    <Link href="#/forgotpassword" underline="none" aria-label="Forgot Password">
                            Forgot Password?
                    </Link>
                </Grid>
            </form>
            <Grid container justifyContent={"space-between"} alignItems="center" className="pt-4">
                <Link href="#/signup" underline="none" aria-label="Signup as a Property owner" onClick={() => dispatch(setSelectedUserGroupId("4"))}>
                    Signup as a<br /> Property Owner
                </Link>

                <Link href="#/signup" underline="none" aria-label="Signup as a Property manager" onClick={() => dispatch(setSelectedUserGroupId("2"))}>
                    Signup as a<br /> Property Manager
                </Link>
            </Grid>
        </Grid >
    );
}

// default props
Login.defaultProps = {
    classes: {},
};

// prop types
Login.propTypes = {
    classes: PropTypes.object,
};

export default styled(Login)(Styles)