import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import FunnelChart from '../charts-metrics/funnelChart';
import { useDispatch, useSelector } from 'react-redux';
import { getAffordabilityLevelDistributionRequest } from '../../../redux/reducer/applicantsDashboardReducer';

const StyledBox = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  display: 'flex',
  flexDirection: 'column',
}));

const ChartContainer = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const LegendContainer = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap', // Allow items to wrap into the next line
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'left', // Ensure text is left-aligned
  marginLeft: '-10px',
  marginTop:"-10px",
  width: "300px"
});

const LegendItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.2rem',
  flexDirection: 'row',
  justifyContent: 'flex-start',
});

const AffordabilityDistribution = () => {
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const dispatch = useDispatch();
  const { applicants } = useSelector((s) => s.applicantsDashboard);
  const affordabilityData = applicants.affordability_level;

  useEffect(() => {
    dispatch(getAffordabilityLevelDistributionRequest());
  }, [dispatch]);

  const data = affordabilityData ? [
    { name: 'Very Low (Below 30%)', value: affordabilityData['Very Low'], color: '#FCC390' },
    { name: 'Low (30 - 50%)', value: affordabilityData['Low'], color: '#9A9BFF' },
    { name: 'Moderate (50 - 80%)', value: affordabilityData['Moderate'], color: '#FC8C99' },
    { name: 'Workforce (80 - 120%)', value: affordabilityData['Workforce'], color: '#A2F0C6' },
  ] : [];

  useEffect(() => {
    let totalValue = 0;
    for (let i of data) {
      totalValue += i?.value;
    }
    if (totalValue > 0) {
      setIsDataAvailable(true);
    } else {
      setIsDataAvailable(false);
    }
  }, [data]);

  const isPropertyManager = localStorage.getItem("user_group_id") == 2

  return (
    <Box className='container'>
      <Typography variant="h4" sx={{ fontWeight: 700, marginBottom: 2 ,fontSize:"16px"}}>
        Affordability Level Distribution
      </Typography>
      {isDataAvailable ? (
        <ChartContainer>
          {data.length > 0 ? (
            <Box sx={{ height: "190px" }}>
              <FunnelChart data={data} width={300} height={280} />
            </Box>
          ) : (
            <Typography variant="body1">Loading...</Typography>
          )}
        </ChartContainer>
      ) : (
        <Typography variant="h2" sx={{ height: "60%", display: "grid", placeItems: "center",fontSize:"16px" }}>
          No Data Found
        </Typography>
      )}
      <LegendContainer container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={6} key={index} sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1, marginInline:"auto" }}>
            <LegendItem>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: item.color,
                
                }}
              />
              <Typography fontSize="11px" variant="body2">{item.name}</Typography>
            </LegendItem>
          </Grid>
        ))}
      </LegendContainer>
    </Box>
  );
};

export default AffordabilityDistribution;