import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Style from './style'

//Components
import { Receiver, Sender, TextField } from "../../components";

// reducer
import { deletePOCommentRequest, getPOCommentsRequest, updateCurrentComment, updatePOCommentsRequest } from "../../redux/reducer/propertyOwnerReducer";
import { SendIcon } from "../../assets/svg";

// reducer
import { errorMessage } from "../../redux/reducer/uiReducer";

const Comments = () => {
    //define dispatch
    const dispatch = useDispatch();

    // reducer
    const { currentComment, comments } = useSelector(s => (s.propertyOwner));
    const { loggedUser } = useSelector(s => (s.profile))

    // get selected owner comments
    useEffect(() => {
        if(loggedUser.id){
        dispatch(getPOCommentsRequest({ id: loggedUser.id }))
        }
    }, [dispatch, loggedUser])

    const updateMessage = (data) => {
        dispatch(updatePOCommentsRequest({
            message: data.message,
            message_from: data.message_from_id,
            message_to: data.message_to_id,
            id: data.id
        }))
    }

    // send new messages
    const sendMessage = () => {
        if(currentComment.message){
            dispatch(updatePOCommentsRequest({
                ...currentComment,
                message_from: loggedUser.id,
                message_to: loggedUser.id,
                id: null
            }))
        }else{
            dispatch(errorMessage("Please enter comments"))
        }
    }

    return (
        <Grid className="commentsContainer">
            <Grid className="commentsList">
                {comments && comments.map((item, index) => (
                    <Grid key={`sdfer-${index}`}>
                        {item.message_from_id === loggedUser.id ?
                            <Sender
                                key={`sender-${index}`}
                                data={item}
                                loggedUser={loggedUser}
                                updateMessage={updateMessage}
                                handleDelete={() => { dispatch(deletePOCommentRequest(item)) }}
                            />
                            :
                            <Receiver
                                key={`receiver-${index}`}
                                data={item}
                            />
                        }
                    </Grid>
                ))}
            </Grid>
            <Grid className="commentType">
                <TextField
                    placeholder={"Write a comment here"}
                    aria-label="Write a comment here"
                    value={currentComment.message}
                    handleChange={(e) => { dispatch(updateCurrentComment(e.value)) }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <Tooltip arrow title="Send">
                                <IconButton
                                    onClick={() => { sendMessage() }}
                                    aria-label="Send"
                                >
                                    <SendIcon />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>,
                    }}
                    onKeyPress={(e) => {
                        if (e.charCode == 13 || e.keyCode == 13) {
                            sendMessage();
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}

// default props
Comments.defaultProps = {
    classes: {},
};

// prop types
Comments.propTypes = {
    classes: PropTypes.object,
};

export default styled(Comments)(Style);