import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import BarChart from "../charts-metrics/barChart";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationsStatusRequest } from "../../../redux/reducer/applicantsDashboardReducer";

const ApplicationsStatus = () => {
  const [isDataAvailable, setIsDataAvailable] = useState(true)
  const dispatch = useDispatch();
  const { applicants } = useSelector((s) => s.applicantsDashboard);

  useEffect(() => {
    dispatch(getApplicationsStatusRequest());
  }, [dispatch]);


  const { loggedUser: { user_group_id } } = useSelector(s => (s.profile))
  const isPropertyManager = user_group_id === 2



  const data = applicants?.application_status
    ? Object.entries(applicants.application_status).map(([key, value]) => ({
        name: key, 
        value: value, 
      }))
    : [];


  useEffect(()=>{
    var totalValue = 0
    for(let i of data){
      totalValue += i?.value
    }
    if(totalValue > 0){
      setIsDataAvailable(true)
    }else{
      setIsDataAvailable(false)
    }


  }, [data])

  return (
    <Box className="container">
      <Typography variant="h4" sx={{ fontWeight: 700 ,fontSize:"16px", marginBottom:"15px"}}>
        Applications and Status
      </Typography>

    { isDataAvailable 
    ? isPropertyManager
    ? <BarChart data={data} width={740} height={300} rotateLabels={true} application_status={true} />
    :  <BarChart data={data} width={1000} height={180} rotateLabels={true} application_status={true} />

    :  <Typography variant="h2" sx={{ height:"80%", display:"grid", placeItems:"center" ,fontSize:"16px"}}>
    No Data Found
   </Typography>
  }
    </Box>
  );
};

export default ApplicationsStatus;