import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import StackedBarChart from "../charts-metrics/stackedBarChart";
import PieChart from "../charts-metrics/pieChart";
import { getApplicantAgeSegmentationRequest } from '../../../redux/reducer/applicantsDashboardReducer';

const ApplicationAgeSegmentation = () => {
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const dispatch = useDispatch();
  const { applicants, applicants: { total_applicants } } = useSelector((s) => s.applicantsDashboard);

  useEffect(() => {
    dispatch(getApplicantAgeSegmentationRequest());
  }, [dispatch]);

  // Process the API response data
  const processedData = [];
  if (applicants?.gender_age) {
    const genders = Object.keys(applicants.gender_age); // E.g., Male, Female, Non-Binary, etc.
    const ageGroups = ["18-25", "26-35", "36-45", "46-55", "Others"]; // Age categories

    genders.forEach((gender) => {
      ageGroups.forEach((ageGroup) => {
        processedData.push({
          state: gender,
          age: ageGroup,
          population: applicants.gender_age[gender][ageGroup] || 0, // Use 0 if no data
        });
      });
    });
  }

  const ageColors = {
    "18-25": ["#9A9BFF", "#e0e0e0"],
    "26-35": ["#FC8C99", "#e0e0e0"],
    "36-45": ["#FCC390", "#e0e0e0"],
    "46-55": ["#A2F0C6", "#e0e0e0"],
    "Others": ["#cccccc", "#e0e0e0"]
  };

  // Prepare chart data
  const chartData = Object.values(
    processedData.reduce((acc, { age, population }) => {
      if (!acc[age]) {
        acc[age] = { value: 0, ageRange: age, colors: ageColors[age] || ["#cccccc", "#e0e0e0"] };
      }
      acc[age].value += population;
      return acc;
    }, {})
  );

  // Determine if any data is available
  useEffect(() => {
    const totalValue = processedData.reduce((acc, item) => acc + (item?.population || 0), 0);
    setIsDataAvailable(totalValue > 0);
  }, [processedData]);

  return (
    <Box className="container">
      <Typography variant="h4" sx={{ fontWeight: 700, marginBottom: 2,fontSize:"16px" }}>
        Applicant Age Segmentation
      </Typography>

      {isDataAvailable ? (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap:"20px" , marginTop:"30px"}}>
          {chartData.map((data, index) => {
            const percentage = parseFloat(((data.value / total_applicants) * 100).toFixed(2));
            return (
              // data?.value > 0 && (
                <PieChart
                  key={index}
                  percentage={percentage}
                  width={80}
                  height={80}
                  value={data.value}
                  colors={data.colors}
                  ageRange={data.ageRange}
                />
              // )
            );
          })}
        </div>
      ) : (
        <Typography variant="h2" sx={{ height:"80%", display:"grid", placeItems:"center" ,fontSize:"16px"}}>
     No Data Found
    </Typography>
      )}
    </Box>
  );
};

export default styled(ApplicationAgeSegmentation)(() => ({}));