import React, { useEffect , useState} from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import StackedBarChart from "../charts-metrics/stackedBarChart";
import { getApplicantAgeSegmentationRequest } from '../../../redux/reducer/applicantsDashboardReducer';

const AgeSegmentation = () => {
  const [isDataAvailable, setIsDataAvailable] = useState(true)
  const dispatch = useDispatch();
  const { applicants } = useSelector((s) => s.applicantsDashboard);

  useEffect(() => {
    dispatch(getApplicantAgeSegmentationRequest());
  }, [dispatch]);

  // Process the API response data
  const processedData = [];
  if (applicants?.gender_age) {
    const genders = Object.keys(applicants.gender_age); // E.g., Male, Female, Non-Binary, etc.
    const ageGroups = ["18-25", "26-35", "36-45", "46-55", "Others"]; // Age categories

    genders.forEach((gender) => {
      ageGroups.forEach((ageGroup) => {
        processedData.push({
          state: gender,
          age: ageGroup,
          population: applicants.gender_age[gender][ageGroup] || 0, // Use 0 if no data
        });
      });
    });
  }


  useEffect(()=>{
    var totalValue = 0
    for(let i of processedData){
      totalValue += i?.population
    }
    if(totalValue > 0){
      setIsDataAvailable(true)
    }else{
      setIsDataAvailable(false)
    }

  }, [processedData])



  const ageGroupsToDisplay = ["18-25", "26-35", "36-45", "46-55"];
  const colors = ["#0084d5", "#01a0cd", "#00cab3", "#ff9828"]; // Matching chart colors

  return (
    <Box className="container">
      <Typography variant="h4" sx={{ fontWeight: 700, marginBottom: 2 }}>
        Applicant Age Segmentation
      </Typography>

      {
        isDataAvailable 
        ? <StackedBarChart data={processedData} colors={colors} /> 
        :  <Typography variant="h2" sx={{ height:"80%", display:"grid", placeItems:"center" }}>
                No Data Found
          </Typography>
      } 


      <Grid container spacing={2} justifyContent="center" sx={{ marginTop: 0 }}>
        {ageGroupsToDisplay.map((age, index) => (
          <Grid item key={index}>
            <Box display="flex" alignItems="center" gap={1} justifyContent="center">
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: colors[index],
                }}
              />
              <Typography variant="body2">Age {age}</Typography> {/* Prepend 'Age' */}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default styled(AgeSegmentation)(() => ({}));