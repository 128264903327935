import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import moment from "moment";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

//style
import Styles from './style'

//Components
import { Avatar, Button, LeftPanel, TabHeader, TabPanel, NoResult, Dialog, AutoComplete } from "../../components";
import Detail from "./detail";
import TopPanel from "./topPanel";
import Comments from "./comments";
import Logs from "./logs";
import Documents from "./documents";

// reducer
import {
    getTenantsCountRequest, getTenantsLeftPanelListRequest, getTenantsDetailRequest,
    updateTenantStatusRequest, updateTenantsFlagRequest
} from "../../redux/reducer/tenantsReducer"

// helpers
import { moveOutReasonOptions, tenantsFieldMap, tenantsStatusOptions, tenantsStatusPlOptions, gets3URL } from "../../helpers";
import { errorMessage } from "../../redux/reducer/uiReducer";

function PanelView(props) {
    //define dispatch
    const dispatch = useDispatch();

    // navigator
    const navigate = useNavigate();
    const { search } = useLocation()
    const [searchParams] = useSearchParams()

    // props
    const { className } = props;

    // state
    const [tabIndex, setTabIndex] = useState(0);
    const [moveOutDialog, setMoveOutDialog] = useState(false);
    const [moveOutReason, setMoveOutReason] = useState("")

    //redux
    const { panelView: { data, pagination, pagination: { count, sortField, sortBy } }, tenantDetail: { details: tenantDetail }, filter, viewType, isLazyLoading } = useSelector(s => s.tenants)
    const { loggedUser: { user_group_id }, loggedUser } = useSelector(s => (s.profile))

    //variables
    const isPropertyManager = user_group_id === 2
    const isPropertyOwner = user_group_id === 4

    // get left panel data
    useEffect(() => {
        let searchQuery = {}
        let redirect = false
        if (search && search.includes("?id=")) {
            searchQuery = {
                application_id: searchParams.get("id")
            }
            redirect = true
        }
        if(search && search.includes("comment")){
            setTabIndex(2)
            redirect = true
        }

        //clear search query if any
        if(redirect){
            navigate("/tenants")
        }
        // get total count for pagination and then call the data in the epic
        dispatch(getTenantsCountRequest({ ...pagination, skip: 0, isFirst: true, filter: { ...filter, ...searchQuery }, viewType: viewType }))
    }, [dispatch])

    // show hide prev button
    const hasPrev = () => {
        return tenantDetail && tenantDetail.id && data && data[0] && data[0].id !== tenantDetail.id ? true : false
    }

    const hasNext = () => {
        const curIndex = data.findIndex(f => (f.id === tenantDetail.id))
        return curIndex < data.length - 1
    }

    const handleFilter = () => {
        dispatch(getTenantsCountRequest({ ...pagination, skip: 0, isFirst: true, filter }))
    }

    const handleSorting = ({ sortField, sortBy }) => {
        dispatch(getTenantsCountRequest({ ...pagination, sortField, sortBy, skip: 0, isFirst: true, filter }))
    }

    const updateTenantStatus = (value, id) => {
        dispatch(updateTenantStatusRequest({
            approval_status: value,
            tenant_id: id,
            reason: {
                message: moveOutReason,
                message_from: loggedUser.id,
                message_to: tenantDetail.user_id,
                application_id: tenantDetail.application_id,
                is_log: true
            }
        }))
        setMoveOutDialog(false)
        setMoveOutReason("")
    }

    return (
        <>
            <Grid container className={className}>
                <Grid item xs={12}>
                    <TopPanel
                        handleFilter={handleFilter}
                        sortValue={`${sortField}-${sortBy}`}
                        handleSorting={handleSorting}
                        IconsList={["VIEW_TYPE", "CHILDREN", "DOWNLOAD", "FILTER_LIST", hasPrev() ? "PREV" : null, hasNext() ? "NEXT" : null]}
                    />
                </Grid>
                <Grid item xs={12} className="bodyContainer">
                    {(count > 0 && tenantDetail && tenantDetail.id) ?
                        <Grid container spacing={2}>
                            <Grid item xs={4} lg={3} className={"sideBarList"}>
                                <LeftPanel
                                    viewId={tenantDetail.id || null}
                                    fieldMap={tenantsFieldMap}
                                    handleStatusChange={(name, value, data) => {
                                        if (value === "3") {
                                            setMoveOutDialog(true)
                                        } else {
                                            updateTenantStatus(value, data.id)
                                        }
                                    }}
                                    data={data || []}
                                    totalCount={count}
                                    disableStatusChange={(data) => { return (isPropertyManager && data.approval_status !== 3 ? false : true) }}
                                    apicallback={() => {
                                        dispatch(getTenantsLeftPanelListRequest({...pagination, filter:filter}))
                                    }}
                                    statusOptions={(data) => {
                                        let tmpStatus = { ...tenantsStatusOptions }
                                        if (data.approval_status === 1) {
                                            delete tmpStatus["3"]
                                        } else if (data.approval_status === 2) {
                                            delete tmpStatus["2"]
                                        }
                                        return tmpStatus
                                    }}
                                    statusViewOption={tenantsStatusPlOptions}
                                    handleView={(data) => {
                                        dispatch(getTenantsDetailRequest(data))
                                        setTabIndex(0)
                                    }}
                                    handleFlag={(data) => {
                                        dispatch(updateTenantsFlagRequest({ ...data, is_flag: data.is_flag ? 0 : 1 }))
                                    }}
                                    isLoading = {isLazyLoading}
                                />
                            </Grid>
                            <Grid item xs={8} lg={9} className={"detailView"}>
                                <Grid className="managerDetail">
                                    <Grid className="header">
                                        <Grid className="managerTitle">
                                            <Avatar
                                                src={gets3URL(tenantDetail.profile_photo)}
                                                alt={tenantDetail.name}
                                            />
                                            <Grid className="pl-1">
                                                <Typography variant="h2" className="fw-500">{tenantDetail.name || ""}</Typography>
                                                <Grid className="dflex alignCenter">
                                                    <Typography variant="body1" className="fw-500">{tenantDetail.property_name || ""}</Typography>
                                                    {[2, 3].includes(tenantDetail.approval_status) &&
                                                        <Typography
                                                            variant="body1"
                                                            className="fw-500 pl5"
                                                            color="textSecondary"
                                                        >
                                                            {tenantDetail.approval_status === 2 ? `Moved-in on ${moment(tenantDetail.move_in_at).format('LL')}` : `Moved-out on ${moment(tenantDetail.move_out_at).format('LL')}`}
                                                        </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {isPropertyManager &&
                                            <Grid className="detailAction">
                                                {tenantDetail.approval_status === 1 && <Button size={"medium"} variant={"contained"} color="secondary" onClick={() => { updateTenantStatus(2, tenantDetail.id) }} sx={{ color: '#fff' }} aria-label="Move-In">Move-In</Button>}
                                                {tenantDetail.approval_status === 2 && <Button size={"medium"} variant={"contained"} color="secondary" onClick={() => { setMoveOutDialog(true) }} sx={{ color: '#fff' }} aria-label="Move-Out">Move-out</Button>}
                                            </Grid>
                                        }
                                    </Grid>

                                    <Grid
                                        container
                                        wrap="nowrap"
                                        className={'tabHeaderContainer'}
                                    >
                                        <TabHeader
                                            tabStyle={'inline'}
                                            tabIndex={tabIndex}
                                            tabList={
                                                isPropertyManager
                                                    ? ['Details', 'Documents', 'Comments', 'logs']
                                                    : isPropertyOwner
                                                        ? ['Details', 'logs']
                                                        : ['Details', 'Comments', 'logs']
                                            }
                                            onTabChange={(newValue) => setTabIndex(newValue)}
                                        />
                                    </Grid>
                                    <Grid className={'tabsBodySection'}>
                                        <Grid className={"tabsBody"}>
                                            {tabIndex === 0 &&
                                                <TabPanel value={0} index={tabIndex} height="calc(100vh - 300px)" sx={{ overflowY: 'auto' }}>
                                                    <Detail />
                                                </TabPanel>
                                            }
                                            {tabIndex === 1 &&
                                                <TabPanel boxClass="commentBox commentsSection" value={1} index={tabIndex} height="calc(100vh - 300px)">
                                                    {isPropertyManager ? <Documents /> : isPropertyOwner ? <Logs /> : <Comments />}
                                                </TabPanel>
                                            }
                                            {tabIndex === 2 &&
                                                <TabPanel boxClass="commentBox commentsSection" value={2} index={tabIndex} height="calc(100vh - 300px)">
                                                    {isPropertyManager ? <Comments /> : <Logs />}
                                                </TabPanel>
                                            }
                                            {(tabIndex === 3 && isPropertyManager) &&
                                                <TabPanel boxClass="commentBox" value={3} index={tabIndex} height="calc(100vh - 300px)">
                                                    <Logs />
                                                </TabPanel>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid> :
                        <>{!isLazyLoading && <NoResult text="No Tenant Found" />}</>
                    }
                </Grid>
            </Grid>
            <Dialog
                open={moveOutDialog}
                failButton={"No, Cancel"}
                successButton={"Yes"}
                handleDialogClose={() => { setMoveOutDialog(false); setMoveOutReason("") }}
                confirmHandle={() => {
                    if (moveOutReason) {
                        updateTenantStatus(3, tenantDetail.id)
                    } else {
                        dispatch(errorMessage("Please choose reject reason"))
                    }
                }}
            >
                <Typography variant='h4' className="pb-1 fw-500">Are you sure?</Typography>
                <Typography variant='body1' className="pb-1 fw-500">The lease is not ended yet. Do you want this tenant to move-out?</Typography>
                <Grid item xs={12} className="pb-2 pt-1">
                    <AutoComplete
                        id={"moveOutReason"}
                        name={"moveOutReason"}
                        placeholder="Select a reason"
                        className="sm"
                        fullWidth
                        display={"name"}
                        value={{ name: moveOutReason }}
                        handleChange={(e) => { setMoveOutReason(e.value && e.value.name ? e.value.name : "") }}
                        options={moveOutReasonOptions}
                    />
                </Grid>
            </Dialog>
        </>
    );
}

// default props
PanelView.defaultProps = {
    classes: {},
};

// prop types
PanelView.propTypes = {
    classes: PropTypes.object,
};

export default styled(PanelView)(Styles);