import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";

export default function BarChart({
  data,
  rotateLabels = false,
  showDollarSymbol = false,
  application_status,
  isHouseholdIncome = false,
  centerXAxisLabels,
}) {
  const svgRef = useRef();
  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const {
    loggedUser: { user_group_id },
  } = useSelector((state) => state.profile);

  const isCityStaff = user_group_id == 3;

  // Resize observer to dynamically update dimensions
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const { width, height } = dimensions;

    if (width === 0 || height === 0 || data.length === 0) return;

    // Define margins
    const margin = { top: 20, right: 20, bottom: 50, left: 50 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.name))
      .range([0, chartWidth])
      .padding(isHouseholdIncome ? 0.7 : 0.5);

    const yMax = d3.max(data, (d) => d.value) || 0;
    const tickStep = yMax / 5; // Divide range into 5 intervals for 6 ticks

    const yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([chartHeight, 0]);

    const filteredData = application_status
      ? data
      : data.filter((d) => d.value !== 0);

    // Add chart group
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Add grid lines BEFORE bars
    const gridLines = chart.append("g").attr("class", "grid-lines");

    gridLines
      .selectAll("line")
      .data(d3.range(0, yMax + tickStep, tickStep)) // Use tickStep for exact 6 ticks
      .enter()
      .append("line")
      .attr("x1", 0)
      .attr("x2", chartWidth)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#f2f2f2")
      .attr("stroke-width", "1px");

    // Add bars
    chart
      .selectAll(".bar")
      .data(filteredData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.name))
      .attr("y", (d) => yScale(d.value))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => chartHeight - yScale(d.value))
      .attr("fill", "#FC8C99")
      .on("mouseover", function () {
        d3.select(this).attr("fill", d3.rgb("#FC8C99").darker(-0.5));
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", "#FC8C99");
      });

    // Add count values above bars
    chart
      .selectAll(".count")
      .data(filteredData)
      .enter()
      .append("text")
      .attr("class", "count")
      .attr("x", (d) => xScale(d.name) + xScale.bandwidth() / 2)
      .attr("y", (d) => yScale(d.value) - 5)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("font-weight", "700")
      .style("fill", "#000")
      .text((d) => (d.value ? (showDollarSymbol ? `$${d.value}` : d.value) : ""));

    // X Axis
    const xAxis = d3.axisBottom(xScale).tickSize(0); // Remove tick lines
    const xAxisGroup = chart
      .append("g")
      .attr("transform", `translate(0, ${chartHeight})`)
      .call(xAxis);

    // Remove X-axis line
    xAxisGroup.select(".domain").style("display", "none");

    // Rotate X-axis labels if needed
    if (rotateLabels) {
      xAxisGroup
        .selectAll("text")
        .style("font-size", "11px")
        .attr("transform", "rotate(-45)")
        .style("text-anchor", "end")
        .attr("dx", "-0.8em")
        .attr("dy", "0.15em")
        .each(function () {
          const text = d3.select(this);
          const words = text.text().split(" ");
          text.text("");

          words.forEach((word, i) => {
            text
              .append("tspan")
              .text(word)
              .attr("x", 0)
              .attr("y", i * 20)
              .attr("dy", "1.05em");
          });
        });
    } else {
      xAxisGroup.selectAll("text").style("font-size", "13px");
      xAxisGroup.attr("dy", "1.05em");
    }

    if (isHouseholdIncome) {
      xAxisGroup.selectAll("text").style("font-size", "13px").attr("dy", "2em");
    }

    // Y Axis
    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(d3.range(0, yMax + tickStep, tickStep)) // Use tickStep for exact 6 ticks
      .tickSize(0) // Remove tick lines
      .tickFormat(isHouseholdIncome ? (d) => `$${d.toFixed(1)}` : (d) => d.toFixed(1));

    const yAxisGroup = chart.append("g").call(yAxis);

    // Remove Y-axis line
    yAxisGroup.select(".domain").style("display", "none");

    // Style Y-axis labels
    yAxisGroup.selectAll("text").style("font-size", "12px");
    yAxisGroup.selectAll("text").attr("transform", `translate(${10}, 0)`) 
  }, [
    data,
    dimensions,
    rotateLabels,
    showDollarSymbol,
    isHouseholdIncome,
    application_status,
  ]);

  return (
    <div
      ref={containerRef}
      style={{
        width: "90%",
        height: "80%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg ref={svgRef} width={dimensions.width+40} height={dimensions.height+20}></svg>
    </div>
  );
}
