import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import LineChart from "../charts-metrics/lineChart";
import { getAverageHabitationLengthRequest } from "../../../redux/reducer/propertiesDashboardReducer";

const HabitationAverageLength = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [propertyLabels, setPropertyLabels] = useState([]);
  const { properties } = useSelector((s) => s.dashboard);

  useEffect(() => {
    dispatch(getAverageHabitationLengthRequest());
  }, [dispatch]);

  useEffect(() => {
    const convertedData = properties?.avg_habitation_length?.map(
      (item, index) => ({
        x: index + 1,
        y: item.average_stay_duration,
        property_name: item.property_name,
      })
    );

    setData(convertedData);

    const propertyNames = [
      ...new Set(convertedData.map((item) => item.property_name)),
    ];
    setPropertyLabels(propertyNames);
  }, [properties?.avg_habitation_length]);

  const isDataAvailable = data && data.some((item) => item.y > 0);

  return (
    <Box className="container" sx={{ position: "relative", paddingLeft: "40px"}} >
      <Typography variant="h4" sx={{ fontWeight: 700, mb: 5, marginBottom: "20px" ,fontSize:"16px"}}>
            Average Length of Habitation
      </Typography>
      {isDataAvailable ? (
        <>
          <Typography
            variant="body1"
            sx={{
              position: "absolute",
              left: 25,
              top: "50%",
              transform: "translateY(-50%) rotate(-90deg)",
              transformOrigin: "left center",
              fontWeight: "bold",
            }}
          >
            Years
          </Typography>
          <Box sx={{ marginLeft: "20px" }}>
            <LineChart
              data={data ?? []}
              width={650}
              height={300}
              labels={propertyLabels ?? []}
            />
          </Box>
        </>
      ) : (
        <Typography variant="h2" sx={{ height:"80%", display:"grid", placeItems:"center" ,fontSize:"16px"}}>
     No Data Found
    </Typography>
      )}
    </Box>
  );
};

export default HabitationAverageLength;