import { postRequest } from "../../helpers";

async function getTotalApplicants(filters = []) {
  const response = await postRequest(`dashboard/applications/total-applicants/`, filters);
  return response;
}

async function getTotalTenants(filters = []) {
  const response = await postRequest(`dashboard/applications/total-tenants/`, filters);
  return response;
}

async function getApplicationsStatus(filters = []) {
  const response = await postRequest(`dashboard/applications/application-status/`, filters);
  return response;
}

async function getAffordabilityLevel(filters = []) {
  const response = await postRequest(`dashboard/applications/affordability-level/`, filters);
  return response;
}

async function getIncomeRent(filters = []) {
  const response = await postRequest(`dashboard/applications/income-rent/`, filters);
  return response;
}

async function getRaceEthnicity(filters = []) {
  const response = await postRequest(`dashboard/applications/race-ethnicity/`, filters);
  return response;
}

async function getAgeGender(filters = []) {
  const response = await postRequest(`dashboard/applications/gender-age-distribution/`, filters);
  return response;
}

async function getApplicationsZipcode(filters = []) {
  const response = await postRequest(`dashboard/applications/applications-map/`, filters);
  return response;
}

// Export all API functions as part of applicantsDashboardService
export const applicantsDashboardService = {
  getTotalApplicants,
  getTotalTenants,
  getApplicationsStatus,
  getAffordabilityLevel,
  getIncomeRent,
  getRaceEthnicity,
  getAgeGender,
  getApplicationsZipcode
};