import React from 'react';
import PropTypes from "prop-types";
import { Button, Grid, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";

// Imports Styles
import Style from "./style";

//images

const MenuComponent = (props) => {
    // location
    const navigate = useLocation();
    const { pathname } = navigate;

    // props
    const { className, loggedUser } = props;

    // variable
    const isCityStaff = loggedUser.user_group_id === 3;
    const isPM = loggedUser.user_group_id === 2;
    const isPO = loggedUser.user_group_id === 4;

    return (
        <Grid className={className}>
            <Box className='menuItems' sx={{ width: '100%' }}>
                {(isCityStaff || isPM || isPO) && <Button variant='text' size='large' href="#/dashboard" className={pathname.includes("/dashboard") ? "active" : "inactive"} aria-label={"Dashboard"} accessKey="m">Dashboard</Button>}
                {isCityStaff && <Button variant='text' size='large' href="#/property-manager" className={pathname.includes("/property-manager") ? "active" : "inactive"} aria-label={"Property Managers"} accessKey="m">Property Managers</Button>}
                {isCityStaff && <Button variant='text' size='large' href="#/property-owner" className={pathname.includes("/property-owner") ? "active" : "inactive"} aria-label={"Property Owners"} accessKey="o">Property Owners</Button>}
                <Button variant='text' size='large' href="#/properties" className={pathname.includes("/properties") ? "active" : "inactive"} aria-label={"Properties"} accessKey='p'>Properties</Button>
                <Button variant='text' size='large' href="#/applications" className={pathname.includes("/applications") ? "active" : "inactive"} aria-label={"Applications"} accessKey="a">Applications</Button>
                {(isPM || isPO) && <Button variant='text' size='large' href="#/waiting-list" className={pathname.includes("/waiting-list") ? "active" : "inactive"} aria-label={"Waiting Lists"} accessKey="w">Waiting Lists</Button>}
                <Button variant='text' size='large' href="#/tenants" className={pathname.includes("/tenants") ? "active" : "inactive"} aria-label={"Tenants"} accessKey="t">Tenants</Button>
                <Button variant='text' size='large' href="#/reports" className={pathname.includes("/reports") ? "active" : "inactive"} aria-label={"Reports"} accessKey="r">Reports</Button>
                {(isPM || isPO) && <Button variant='text' size='large' href="#/references" className={pathname.includes("/references") ? "active" : "inactive"} aria-label={"Reports"} accessKey="r">References</Button>}
            </Box>
        </Grid>
    )
}

// default props
MenuComponent.defaultProps = {
    className: "",
    loggedUser: {}
};

// prop types
MenuComponent.propTypes = {
    className: PropTypes.string,
    loggedUser: PropTypes.object
};

// export component
export const MenuTabs = styled(MenuComponent)(Style);
