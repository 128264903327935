import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const StackedBarChart = ({ data, colors }) => {
  const svgRef = useRef();
  

  const [tooltipText, setTooltipText] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const width = 475; // Static width
    const height = 325; // Static height
    const marginTop = 30;
    const marginRight = 20;
    const marginBottom = 0;
    const marginLeft = 90;

    const series = d3
      .stack()
      .keys(d3.union(data.map((d) => d.age)))
      .value(([, D], key) => D.get(key).population) 
      .offset(d3.stackOffsetExpand)(
      d3.index(
        data,
        (d) => d.state,
        (d) => d.age
      )
    ); 


    const x = d3
      .scaleLinear()
      .domain([0, d3.max(series, (d) => d3.max(d, (d) => d[1]))])
      .range([marginLeft, width - marginRight]);

    const y = d3
      .scaleBand()
      .domain(
        d3.groupSort(
          data,
          (D) => -d3.sum(D, (d) => d.population),
          (d) => d.state
        )
      )
      .range([marginTop, height - marginBottom])
      .padding(0.08);

    const color = d3.scaleOrdinal() 
      .domain(series.map((d) => d.key))
      .range(colors) 
      .unknown("#ccc");

    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`);

    svg
      .append("g")
      .selectAll("g")
      .data(series)
      .join("g")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((D) => D.map((d) => ((d.key = D.key), d)))
      .join("rect")
      .attr("x", (d) => x(d[0]))
      .attr("y", (d) => y(d.data[0]))
      .attr("height", y.bandwidth())
      .attr("width", (d) => x(d[1]) - x(d[0]))
      .on("mouseover", (event, d) => {
        setTooltipText(`${d.key} Age Group: ${d.data[0]}\nCount: ${d.data[1].get(d.key).population}`);
        const svgRect = svgRef.current.getBoundingClientRect();
        const xPos = event.clientX - svgRect.left + 10; 
        const yPos = event.clientY - svgRect.top + 10;
        setTooltipPosition({ top: yPos, left: xPos });
      })
      .on("mousemove", (event) => {
        const svgRect = svgRef.current.getBoundingClientRect();
        const xPos = event.clientX - svgRect.left + 10;
        const yPos = event.clientY - svgRect.top + 10;
        setTooltipPosition({ top: yPos, left: xPos });
      })
      .on("mouseout", () => {
        setTooltipText("");
      });

    svg
      .append("g")
      .attr("transform", `translate(0,${marginTop})`)
      .call((g) => g.selectAll(".domain").remove());

    svg
      .append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y).tickSizeOuter(0))
      .call((g) => g.selectAll(".domain").remove());
  }, [data, colors]);

  return (
    <div style={{ position: "relative" }}>
      <svg
        ref={svgRef}
        width="500"
        height="325"
        style={{ display: "block" }} 
      />
      {tooltipText && (
        <div
          className="tooltip"
          style={{
            position: "absolute",
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`, 
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ddd",
            borderRadius: "3px",
            boxShadow: "0 0 3px rgba(0, 0, 0, 0.2)",
            fontSize: "12px",
            fontWeight: "bold",
            zIndex: 10,
            width: "120px", 
            pointerEvents: "none", 
            whiteSpace: "pre-line",
          }}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default StackedBarChart;