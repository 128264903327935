import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

//style
import Styles from './style'

//Components
import { Avatar, Button, Dialog, LeftPanel, TabHeader, AutoComplete, TabPanel, TextField, NoResult } from "../../components";
import Detail from "./detail";
import TopPanel from "./topPanel";
import Comments from "./comments";
import Logs from "./logs";

// reducer
import {
    getApplicationsCountRequest, getApplicationsLeftPanelListRequest, getApplicationsDetailRequest,
    applicationsApprovalRequest, updateApplicationsFlagRequest, updateApplicationsCommentsRequest,
    rejectApplicationRequest, updateTempApplicationsFilter,
} from "../../redux/reducer/applicationsReducer";
import { errorMessage } from "../../redux/reducer/uiReducer";

// helpers
import { applicationsFieldMap, applicationsStatusOptions, applicationsStatusPlOptions, globalDate, preapprovalRejectionReasonOptions, jsonParser } from "../../helpers";
import Documents from "./documents";

function PanelView(props) {
    //define dispatch
    const dispatch = useDispatch();

    // navigator
    const navigate = useNavigate();
    const { search } = useLocation()
    const [searchParams] = useSearchParams()

    // props
    const { className } = props;

    // state
    const [tabIndex, setTabIndex] = useState(0);
    const [rejectDialog, setRejectDialog] = useState(false);
    const [comment, setComment] = useState("")
    const [rejectReason, setRejectReason] = useState("")

    //redux
    const { panelView: { data, pagination, pagination: { count, sortField, sortBy } }, currentApplication: { general: applicationsDetail }, filter, viewType, isLazyLoading } = useSelector(s => s.applications)
    const { loggedUser: { user_group_id }, loggedUser } = useSelector(s => (s.profile))

    //variables
    const isPropertyManager = user_group_id === 2
    const isPropertyOwner = user_group_id === 4
    const showDocumentsTab = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14].includes(applicationsDetail.approval_status)

    // get left panel data
    useEffect(() => {
        let searchQuery = {}
        let redirect = false
        if (search && search.includes("?id=")) {
            searchQuery = {
                application_id: searchParams.get("id")
            }
            redirect = true
        }
        if(search && search.includes("comment")){
            showDocumentsTab? setTabIndex(2):setTabIndex(1)
            redirect = true
        }

        //clear search query if any
        if(redirect){
            navigate("/applications")
        }

        // get total count for pagination and then call the data in the epic
        dispatch(getApplicationsCountRequest({ ...pagination, skip: 0, isFirst: true, filter: { ...filter, ...searchQuery }, viewType: viewType }))
    }, [dispatch])

    // approve or reject manager
    const handleApproval = (approval) => {
        dispatch(applicationsApprovalRequest({
            approval_status: approval,
            application_id: applicationsDetail.id
        }))
    }

    // show hide prev button
    const hasPrev = () => {
        return applicationsDetail && applicationsDetail.id && data && data[0] && data[0].id !== applicationsDetail.id ? true : false
    }

    const hasNext = () => {
        const curIndex = data.findIndex(f => (f.id === applicationsDetail.id))
        return curIndex < data.length - 1
    }

    const handleFilter = () => {
        //update temporary filters on applying filters. This can be used to reset them on opening the filter drawer again
        dispatch(updateTempApplicationsFilter())
        dispatch(getApplicationsCountRequest({ ...pagination, skip: 0, isFirst: true, filter }))
    }

    const handleSorting = ({ sortField, sortBy }) => {
        dispatch(getApplicationsCountRequest({ ...pagination, sortField, sortBy, skip: 0, isFirst: true, filter }))
    }

    const handleRejection = () => {
        if (!rejectReason) {
            dispatch(errorMessage("Please select reject reason"))
        } else {
            const rejects = {
                message: rejectReason,
                message_from: loggedUser.id,
                message_to: applicationsDetail.applicant_id,
                application: applicationsDetail.id,
                is_log: true
            }
            const comments = {
                message: comment,
                message_from: loggedUser.id,
                message_to: applicationsDetail.applicant_id,
                application: applicationsDetail.id,
                is_log: false
            }
            dispatch(rejectApplicationRequest({ approval_status: 3, rejects: rejects, comments: comments, application_id: applicationsDetail.id }))
            setRejectReason("")
            setComment("")
            setRejectDialog(false)
        }
    }

    // remove reject from the status dropdown if the application is rejected
    const getStatusOptions = (data) => {
        if(data.approval_status != 3){
            return applicationsStatusOptions
        }
        else{
            let filteredStatuses = {...jsonParser(applicationsStatusOptions)}
            delete filteredStatuses['3']
            return filteredStatuses
        }
    }

    return (
        <Grid container className={className}>
            <Grid item xs={12}>
                <TopPanel
                    handleFilter={handleFilter}
                    sortValue={`${sortField}-${sortBy}`}
                    handleSorting={handleSorting}
                    IconsList={["VIEW_TYPE", "CHILDREN", "DOWNLOAD", "FILTER_LIST", hasPrev() ? "PREV" : null, hasNext() ? "NEXT" : null]}
                />
            </Grid>
            <Grid item xs={12} className="bodyContainer">
                {(count > 0 && applicationsDetail && applicationsDetail.id) ?

                    <Grid container spacing={2}>
                        <Grid item xs={4} lg={3} className={"sideBarList"}>
                            <LeftPanel
                                viewId={applicationsDetail.id || null}
                                fieldMap={applicationsFieldMap}
                                handleStatusChange={(name, value, data) => {
                                    if (Number(value) === 3) {
                                        setRejectDialog(true)
                                    } else {
                                        dispatch(applicationsApprovalRequest({
                                            approval_status: value,
                                            application_id: data.id
                                        }))
                                    }
                                }}
                                data={data || []}
                                totalCount={count}
                                disableStatusChange={(data) => { return (isPropertyManager && (data.approval_status === 1 || data.approval_status === 3) ? false : true) }}
                                apicallback={() => {
                                    dispatch(getApplicationsLeftPanelListRequest({ ...pagination, filter: filter }))
                                }}
                                statusOptions={getStatusOptions}
                                statusViewOption={applicationsStatusPlOptions}
                                handleView={(data) => {
                                    dispatch(getApplicationsDetailRequest(data))
                                    setTabIndex(0)
                                }}
                                handleFlag={(data) => {
                                    dispatch(updateApplicationsFlagRequest({ ...data, is_flag: data.is_flag ? 0 : 1 }))
                                }}
                                isLoading = {isLazyLoading}
                            />
                        </Grid>
                        <Grid item xs={8} lg={9} className={"detailView"}>
                            <Grid className="managerDetail">
                                <Grid className="header">
                                    <Grid className="managerTitle">
                                        <Avatar
                                            src={applicationsDetail.profile_photo}
                                            alt={applicationsDetail.name}
                                        />
                                        <Grid className="pl-1">
                                            <Typography variant="h2" className="fw-500">{applicationsDetail.name || ""}</Typography>
                                            <Grid className="dflex alignCenter">
                                                <Typography variant="body1" className="fw-500">{applicationsDetail.property_name || ""}</Typography>
                                                <Typography variant="body1" className="fw-500 pl5" color="textSecondary">Submitted on {globalDate(applicationsDetail.submitted_at)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {isPropertyManager && (applicationsDetail.approval_status === 1 || applicationsDetail.approval_status === 3) &&
                                        <Grid className="detailAction">
                                            {applicationsDetail.approval_status !== 3 && <Button size={"small"} variant={"contained reject mr-1"} onClick={() => { setRejectDialog(true) }}  aria-label="Reject">Reject</Button>}
                                            <Button size={"small"} variant={"contained approve"} onClick={() => { handleApproval("2") }}  aria-label="Move to Pre- approved">Move to Pre-Approved</Button>
                                        </Grid>
                                    }
                                </Grid>

                                <Grid
                                    container
                                    wrap="nowrap"
                                    className={'tabHeaderContainer'}
                                >
                                    <TabHeader
                                        tabStyle={'inline'}
                                        tabIndex={tabIndex}
                                        tabList={
                                            isPropertyOwner
                                                ? ['Details', 'Logs'] 
                                                : showDocumentsTab
                                                    ? ['Details', 'Documents', 'Comments', 'Logs']
                                                    : ['Details', 'Comments', 'Logs']
                                        }
                                        onTabChange={(newValue) => setTabIndex(newValue)}
                                    />
                                </Grid>
                                <Grid className={'tabsBodySection'}>
                                    <Grid className={"tabsBody"}>
                                        {
                                            tabIndex === 0 &&
                                            <TabPanel value={0} index={tabIndex} height="calc(100vh - 300px)" sx={{ overflowY: 'auto' }}>
                                                <Detail />
                                            </TabPanel>
                                        }
                                        {
                                            tabIndex === 1 &&
                                            <TabPanel boxClass="commentBox commentsSection" value={1} index={tabIndex} height="calc(100vh - 300px)">
                                                {isPropertyOwner 
                                                    ? <Logs /> 
                                                    : showDocumentsTab 
                                                        ? <Documents /> 
                                                        : <Comments />
                                                }
                                            </TabPanel>
                                        }
                                        {
                                            tabIndex === 2 &&
                                            <TabPanel boxClass="commentBox commentsSection" value={2} index={tabIndex} height="calc(100vh - 300px)">
                                                {showDocumentsTab ? <Comments /> : <Logs />}
                                            </TabPanel>
                                        }
                                        {
                                            (tabIndex === 3 && showDocumentsTab) &&
                                            <TabPanel boxClass="commentBox" value={3} index={tabIndex} height="calc(100vh - 300px)">
                                                <Logs />
                                            </TabPanel>
                                        }
                                    </Grid>
                                </Grid>

                                <Dialog
                                    open={rejectDialog}
                                    handleDialogClose={() => { setRejectDialog(false); setComment("") }}
                                    confirmHandle={() => {
                                        handleRejection()
                                    }}
                                >
                                    <Typography variant='h6' className="pb-1">Please select the rejection reason</Typography>
                                    <Grid item xs={12} className="pb-2 pt-1">
                                        <AutoComplete
                                            id={"unitTypeFilter"}
                                            name={"unitTypeFilter"}
                                            placeholder="Select a rejection reason"
                                            className="sm"
                                            fullWidth
                                            display={"name"}
                                            value={{ name: rejectReason }}
                                            handleChange={(e) => { setRejectReason(e.value && e.value.name ? e.value.name : "") }}
                                            options={preapprovalRejectionReasonOptions}
                                        />
                                    </Grid>
                                    <TextField
                                        placeholder={"Comments"}
                                        aria-label="Enter the comments"
                                        multiline
                                        value={comment}
                                        handleChange={e => { setComment(e.value) }}
                                    />
                                </Dialog>

                            </Grid>

                        </Grid>
                    </Grid>
                    :
                    <>{!isLazyLoading && <NoResult text="No Application Found" />}</>  
                }
            </Grid>
        </Grid>
    );
}

// default props
PanelView.defaultProps = {
    classes: {},
};

// prop types
PanelView.propTypes = {
    classes: PropTypes.object,
};

export default styled(PanelView)(Styles);