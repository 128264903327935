import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import ReactLeaflet from "../charts-metrics/reactLeaflet";
import { getPropertyDistributionRequest } from "../../../redux/reducer/propertiesDashboardReducer";

const PropertyDistribution = () => {
  const dispatch = useDispatch();
  const { properties } = useSelector((s) => s.dashboard);
  useEffect(() => {
    dispatch(getPropertyDistributionRequest());
  }, [dispatch]);
  return (
    <Box className="container">
      <Typography variant="h4" sx={{ fontWeight: 700, mb: 2, fontSize:"16px"}}>
        Property Distribution By Geography
      </Typography>
      <ReactLeaflet zips={properties?.property_distribution ?? []} />
    </Box>
  );
};

export default PropertyDistribution;
