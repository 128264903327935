import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Responsive, WidthProvider } from "react-grid-layout";
import { styled } from "@mui/material/styles";

import Style from "./style"; // style import

// Charts and Metrics
import CountMetrics from "./charts-metrics/countMetrics";

// Property Widgets
import PropertyApprovalStatus from "./widgets-properties/propertyApprovalStatus";
import AverageHouseholdIncome from "./widgets-properties/averageHouseholdIncome";
import HabitationAverageLength from "./widgets-properties/habitationAverageLength";
import TenantTrends from "./widgets-properties/tenantTrends";
import UnitTypeDistribution from "./widgets-properties/unitTypeDistribution";
import PropertyDistribution from "./widgets-properties/propertyDistribution";

// Icons
import {
  PropertiesIcon,
  OccupiedUnitsIcon,
  VacantUnitsIcon,
} from "../../assets/svg";
import {
  getPropertiesRequest,
  getUnitsOccupancyRequest,
} from "../../redux/reducer/propertiesDashboardReducer";

const ResponsiveGridLayout = WidthProvider(Responsive);

const PropertiesDashboard = () => {
  const dispatch = useDispatch();
  const { properties } = useSelector((s) => s.dashboard);

  useEffect(() => {
    dispatch(getPropertiesRequest());
    dispatch(getUnitsOccupancyRequest());
  }, [dispatch]);

  const propertiesLayouts = {
    lg: [
      { i: "Properties", x: 0, y: 0, w: 3, h: 1.5, static: true },
      { i: "VacantUnits", x: 0, y: 1.5, w: 3, h: 1.5, static: true },
      { i: "OccupiedUnits", x: 0, y: 3, w: 3, h: 1.5, static: true },
      { i: "PropertyApprovalStatus", x: 3, y: 0, w: 3, h: 4.5, static: true },
      { i: "TenantTrends", x: 0, y: 4.5, w: 9, h: 6, static: true },
      { i: "UnitTypeDistribution", x: 9, y: 4.5, w: 3, h: 6, static: true },
      { i: "PropertyDistribution", x: 0, y: 10.5, w: 6, h: 5, static: true },
      { i: "AverageHouseholdIncome", x: 6, y: 0, w: 6, h: 4.5, static: true },
      { i: "HabitationAverageLength", x: 6, y: 10.5, w: 6, h: 5, static: true },
      { i: "spacer", x: 0, y: 15.5, w: 12, h: 0.4, static: true },
    ],
    md: [
      { i: "Properties", x: 0, y: 0, w: 2.5, h: 1.5, static: true },
      { i: "VacantUnits", x: 0, y: 1.5, w: 2.5, h: 1.5, static: true },
      { i: "OccupiedUnits", x: 0, y: 3, w: 2.5, h: 1.5, static: true },
      { i: "PropertyApprovalStatus", x: 2.5, y: 0, w: 2.5, h: 4.5, static: true },
      { i: "AverageHouseholdIncome", x: 5, y: 0, w: 5, h: 4.5, static: true },
      { i: "TenantTrends", x: 0, y: 4.5, w: 7, h: 6, static: true },
      { i: "UnitTypeDistribution", x: 7, y: 4.5, w: 3, h: 6, static: true },
      { i: "PropertyDistribution", x: 0, y: 10.5, w: 5, h: 5, static: true },
      { i: "HabitationAverageLength", x: 5, y: 10.5, w: 10, h: 5, static: true },
      { i: "spacer", x: 0, y: 15.5, w: 12, h: 0.4, static: true },
    ],
    sm:[
      { i: "Properties", x: 0, y: 0, w: 3, h: 1.5, static: true },
      { i: "VacantUnits", x: 0, y: 1.5, w: 3, h: 1.5, static: true },
      { i: "OccupiedUnits", x: 0, y: 3, w: 3, h: 1.5, static: true },
      { i: "PropertyApprovalStatus", x: 3, y: 0, w: 3, h: 4.5, static: true },
      { i: "TenantTrends", x: 0, y: 4.5, w: 6, h: 6, static: true },
      { i: "PropertyDistribution", x: 5, y: 10.5, w: 6, h: 6, static: true },
      { i: "AverageHouseholdIncome", x: 0, y: 10.5, w: 6, h: 6, static: true },
      { i: "UnitTypeDistribution", x: 0, y: 16.5, w: 3, h: 6, static: true },
      { i: "HabitationAverageLength", x: 3, y: 16.5, w: 3, h: 6, static: true },
    ],
    xs:[
      { i: "Properties", x: 0, y: 0, w: 4, h: 1.5, static: true },
      { i: "VacantUnits", x: 0, y: 1.5, w: 4, h: 1.5, static: true },
      { i: "OccupiedUnits", x: 0, y: 3, w: 4, h: 1.5, static: true },
      { i: "PropertyApprovalStatus", x: 0, y: 4.5, w: 4, h: 4.5, static: true },
      { i: "TenantTrends", x: 0, y: 9, w: 4, h: 6, static: true },
      { i: "PropertyDistribution", x: 0, y: 15, w: 4, h: 6, static: true },
      { i: "AverageHouseholdIncome", x: 0, y: 21, w: 4, h: 6, static: true },
      { i: "UnitTypeDistribution", x: 0, y: 27, w: 4, h: 6, static: true },
      { i: "HabitationAverageLength", x: 0, y: 33, w: 4, h: 5, static: true },
    ]
  };
  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={propertiesLayouts}
      breakpoints={{ lg: 1339, md: 1000, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      rowHeight={60}
      margin={[15, 15]}
    >
      <Box key="Properties">
        <CountMetrics
          count={properties?.properties ?? 0}
          content={"No. of Properties"}
          PropertiesIcon={PropertiesIcon}
        />
      </Box>
      
      <Box key="VacantUnits">
        <CountMetrics
          count={properties?.vacant_units ?? 0}
          content={"No. of Vacant Units"}
          PropertiesIcon={VacantUnitsIcon}
        />
      </Box>
      <Box key="OccupiedUnits">
        <CountMetrics
          count={properties?.occupied_units ?? 0}
          content={"No. of Occupied Units"}
          PropertiesIcon={OccupiedUnitsIcon}
        />
      </Box>
      <Box key="PropertyApprovalStatus">
        <PropertyApprovalStatus />
      </Box>
      <Box className="tenant-trends-box" key="TenantTrends" >
        <TenantTrends />
      </Box>
      <Box key="UnitTypeDistribution" >
        <UnitTypeDistribution />
      </Box>
      <Box key="PropertyDistribution">
        <PropertyDistribution />
      </Box>
      <Box key="AverageHouseholdIncome" >
        <AverageHouseholdIncome />
      </Box>
      <Box key="HabitationAverageLength" >
        <HabitationAverageLength />
      </Box>
      <Box key="spacer"  />
     
    </ResponsiveGridLayout>
  );
};

export default styled(PropertiesDashboard)(Style);
