import { createSlice } from '@reduxjs/toolkit';

//import helpers
import { setToken, getErrorMessageRecursion, removeToken, setSession, removeSession, getSession, getToken } from "../../helpers";

const initialState = {
    isLoggedIn: false,
    isLoading: false,
    signupForm: {
        email: "",
        first_name: "",
        last_name: "",
        user_group_id: "2",
        password: "",
        company: "",
        address: "",
        phone: "",
        city: {},
        state: {},
        zipcode: ""
    },
    selectedUserGroupId: "2",
    isSignup: false,
    passwordLinkValid: true,
    activationMessage: "",
    redirectToResetPwd: false,
    passwordSubmit: false,
}


const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        //login
        loginRequest(state) {
            state.isLoggedIn = false
            state.isLoading = true
            state.loginError = ''
        },
        loginSuccess(state, action) {
            state.isLoading = false
            if (action.payload && action.payload.token && action.payload.token.access) {
                setToken("sessionIdd",action.payload.email)
                setSession("sessionIdd",action.payload.email)
                setToken("user", JSON.stringify({
                    ...action.payload,
                    token: action.payload.token.access
                }))
                state.isLoggedIn = true
            }
            state.loginError = ''
        },
        loginFailure(state, action) {
            const response = action && action.payload && action.payload.response ? action.payload.response : {}
            const msgData = response.data.message ? response.data.message : response.data ? response.data : ""
            let message = getErrorMessageRecursion(msgData);
            state.isLoggedIn = false
            state.isLoading = false
            state.loginError = message
        },

        //forget password
        forgetPasswordRequest(state) {
            state.isLoading = true
        },
        forgetPasswordSuccess(state) {
            state.isLoading = false
        },
        forgetPasswordFailure(state) {
            state.isLoading = false
        },

        //signup
        updateSignupData(state, action) {
            state.signupForm = action.payload
        },
        signupRequest(state) {
            state.isLoading = true
        },
        signupSuccess(state) {
            state.isLoading = false
            state.signupForm = initialState.signupForm
            state.isSignup = true
        },
        signupFailure(state) {
            state.isLoading = false
        },
        updateIsSignup(state, action) {
            state.isLoggedIn = false
            state.isSignup = false
        },

        //reset password
        resetPasswordRequest(state) {
            state.isLoading = true
        },
        resetPasswordSuccess(state) {
            state.isLoading = false
            state.passwordSubmit = true
        },
        resetPasswordFailure(state) {
            state.isLoading = false
        },

        // reset password link veriication
        passwordLinkVerifyRequest(state) {
            state.isLoading = true
            state.passwordLinkValid = true
        },
        passwordLinkVerifySuccess(state, action) {
            state.isLoading = false
            state.passwordLinkValid = action.payload
        },
        passwordLinkVerifyFailure(state) {
            state.isLoading = false
            state.passwordLinkValid = false
        },

        //user activation
        userActivationRequest(state) {
            state.isLoading = true
            state.redirectToResetPwd = false
        },
        userActivationSuccess(state, action) {
            state.isLoading = false
            if (action.payload.data) {
                state.redirectToResetPwd = true
            } else {
                state.redirectToResetPwd = false
                state.activationMessage = action.payload && action.payload.message ? action.payload.message : ""
            }
        },
        userActivationFailure(state, action) {
            state.isLoading = false
            state.redirectToResetPwd = false
            state.activationMessage = action.payload && action.payload.data && action.payload.data.message ? action.payload.data.message : "Invalid Url"
        },

        // logout
        logoutRequest(state) {
            state.isLoggedIn = false
            removeToken("user")
            removeToken("sessionIdd")
            removeSession("sessionIdd")
        },
        resetPasswordData(state) {
            state.passwordSubmit = false
        },

        // reset current session in state
        resetSession(state){
            state.isLoggedIn = false
            removeSession("sessionIdd")
        },

        setSelectedUserGroupId(state, action) {
            state.selectedUserGroupId = action.payload;
            state.signupForm.user_group_id = action.payload; // Update signup form
        },
    }
})

export const {
    loginRequest, loginSuccess, loginFailure,
    forgetPasswordRequest, forgetPasswordSuccess, forgetPasswordFailure,
    updateSignupData, updateIsSignup,
    signupRequest, signupSuccess, signupFailure,
    resetPasswordRequest, resetPasswordSuccess, resetPasswordFailure,
    passwordLinkVerifyRequest, passwordLinkVerifySuccess, passwordLinkVerifyFailure,
    userActivationRequest, userActivationSuccess, userActivationFailure,
    logoutRequest, resetPasswordData, setSelectedUserGroupId,
    resetSession
} = authSlice.actions;
export default authSlice.reducer;