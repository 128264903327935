import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from "react-router-dom"

//style
import Style from './style'

//Components
import Detail from "./detail";
import Comments from "./comments";
import PropertyOwnerComments from "./poComments";
import { Button, Dialog, TabHeader, TabPanel } from "../../components";

// helpers
import { buildFormData, getUserType, profileValidation } from "../../helpers";

// epic
import { updateProfileRequest } from "../../redux/reducer/profileReducer";
import { errorMessage } from "../../redux/reducer/uiReducer";

function Profile(props) {
    //define dispatch
    const dispatch = useDispatch();

    // define navigate
    const navigate = useNavigate()
    // location
    const { search } = useLocation()

    // props
    const { className } = props;

    // reducer
    const { userProfile, loggedUser: { user_group_id }, isDetailEdited } = useSelector(s => (s.profile))

    // tab state
    const [tabIndex, setTabIndex] = useState(0);
    const [validate, setValidate] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(()=>{
        if(search.includes("comment")){
            setTabIndex(1)
        }
    },[dispatch])

    const isCityStaff = user_group_id === 3
    const isPropertyOwner = user_group_id === 4

    const handleSubmit = () => {
        setValidate(true);
        const params = {
            ...userProfile,
            profile: {
                ...userProfile.profile,
                city: userProfile.profile.city && userProfile.profile.city.id ? userProfile.profile.city.id : "",
                state: userProfile.profile.state && userProfile.profile.state.id ? userProfile.profile.state.id : "",
            },
        };
        const validation = profileValidation({ ...params, ...params.profile }, getUserType(user_group_id))

        if (!validation) {
            let formData = new FormData();
            formData = buildFormData(formData, params, null, true);
            dispatch(updateProfileRequest(formData));
            if (isCityStaff){
                navigate("/property-manager")
            } else{
                navigate("/properties")
            }
        } else {
            dispatch(errorMessage(validation));
        }
    }

    return (
        <>
            <Grid className={className}>
                <Grid item className="titleContainer">
                    <Typography variant="h3" className="fw-500">My Profile</Typography>
                    {tabIndex === 0 &&
                        <Grid className="dflex alignCenter">
                            <Button variant={"text"} color="primary" size="medium" className={"mr-1"}
                                onClick={() => {
                                    if (isDetailEdited) {
                                        setOpenPopup({ target: true })
                                    } else {
                                        navigate(-1)
                                    }
                                }}
                                aria-label="Cancel"
                            >Cancel</Button>
                            <Button variant={"contained"} color="primary" onClick={() => { handleSubmit() }} size="medium"  aria-label="Save">Save</Button>
                        </Grid>
                    }
                </Grid>
                <Grid className="bodyContainer">
                    <Grid className="bodyInner">
                        <Grid
                            container
                            wrap="nowrap"
                            className={'tabHeaderContainer'}
                        >
                            <TabHeader
                                tabStyle={'inline'}
                                tabIndex={tabIndex}
                                tabList={getUserType(user_group_id) === "PROPERTY_MANAGER" || "PROPERTY_OWNER" ? ['Details', 'Comments'] : ['Details']}
                                onTabChange={(newValue) => {
                                    if (isDetailEdited && newValue === 1) {
                                        setOpenPopup({ target: true, triggerFrom: "tab" });
                                    } else {
                                        setTabIndex(newValue)
                                    }
                                }}
                            />
                        </Grid>
                        <Grid className={'tabsBodySection'}>
                            <Grid className={"tabsBody"}>
                                {
                                    tabIndex === 0 &&
                                    <TabPanel boxClass="detailsBox" value={0} index={tabIndex} height="calc(100vh - 215px)" sx={{ overflowY: 'auto' }}>
                                        <Detail
                                            validate={validate}
                                        />
                                    </TabPanel>
                                }
                                {
                                    tabIndex === 1 &&
                                    <TabPanel boxClass="commentBox" value={1} index={tabIndex} height="calc(100vh - 215px)" sx={{ overflowY: 'auto' }}>
                                      {  isPropertyOwner ? <PropertyOwnerComments /> :<Comments />}
                                    </TabPanel>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(isDetailEdited && openPopup) &&
                <Dialog
                    open={!!openPopup}
                    handleDialogClose={() => {
                        setOpenPopup(false);
                        if(openPopup && openPopup.triggerFrom === "tab"){
                            setTabIndex(1)
                        }else{
                            navigate(-1);
                        }
                    }}
                    confirmHandle={() => {
                        handleSubmit();
                        setOpenPopup(false)
                    }}
                    successButton={"Yes"}
                    failButton={"No"}
                >
                    <Typography variant='h6'>You have unsaved changes. Do you want to go back and save your changes?</Typography>
                </Dialog>
            }
        </>
    );
}

// default props
Profile.defaultProps = {
    classes: {},
};

// prop types
Profile.propTypes = {
    classes: PropTypes.object,
};

export default styled(Profile)(Style)