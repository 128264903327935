import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

//import service
import { propertyOwnerService } from '../service';

//import actions
import { successMessageApi, errorMessageApi } from "../reducer/uiReducer";
import {
    getPOLeftPanelListRequest, getPOLeftPanelListSuccess, getPOLeftPanelListFailure,
    getPODetailRequest, getPODetailSuccess, getPODetailFailure,
    updatePOFlagRequest, updatePOFlagSuccess, updatePOFlagFailure,
    ownerApprovalRequest, ownerApprovalSuccess, ownerApprovalFailure,
    getPOListRequest, getPOListSuccess, getPOListFailure,
    getPOCountRequest, getPOCountSuccess, getPOCountFailure,
    getPOInviteRequest,getPOInviteSuccess, getPOInviteFailure,
    getPOCommentsRequest, getPOCommentsSuccess, getPOCommentsFailure,
    updatePOCommentsRequest, updatePOCommentsSuccess, updatePOCommentsFailure,
    deletePOCommentRequest, deletePOCommentSuccess, deletePOCommentFailure,
    downloadPORequest, downloadPOSuccess, downloadPOFailure,
    updateOverallPOLoaders
} from "../reducer/propertyOwnerReducer";

/**get PO left Panel List */
const getPOleftPanelList = (action$) => action$.pipe(
    ofType(getPOLeftPanelListRequest),
    mergeMap(({ payload }) => {
        return from(propertyOwnerService.getPOLeftPanelList(payload)).pipe(
            concatMap((res) => {
                const data = res.data && res.data.data ? res.data.data : []
                if (payload.isFirst && data && data[0]) {
                    return of(getPOLeftPanelListSuccess(res.data), getPODetailRequest(data[0], updateOverallPOLoaders()))
                } else {
                    return of(getPOLeftPanelListSuccess(res.data), updateOverallPOLoaders())
                }
            }),
            catchError((res) => of(getPOLeftPanelListFailure(), updateOverallPOLoaders(), errorMessageApi(res))),
        )
    })
)

/**get Property owner data */
const getPODetail = (action$) => action$.pipe(
    ofType(getPODetailRequest),
    mergeMap(({ payload }) => {
        return from(propertyOwnerService.getPODetail(payload)).pipe(
            concatMap((res) => of(getPODetailSuccess(res.data.data), updateOverallPOLoaders())),
            catchError((res) => of(getPODetailFailure(), updateOverallPOLoaders(), errorMessageApi(res))),
        )
    }),
)

/**update property owner flag*/
const updatePOFlag = (action$) => action$.pipe(
    ofType(updatePOFlagRequest),
    mergeMap(({ payload }) => {
        return from(propertyOwnerService.updatePOFlag(payload)).pipe(
            concatMap((res) => of(updatePOFlagSuccess({ data: res.data.data, payload: payload }))),
            catchError((res) => of(updatePOFlagFailure(), errorMessageApi(res))),
        )
    })
)

/**property owner Approval */
const propertyOwnerApproval = (action$) => action$.pipe(
    ofType(ownerApprovalRequest),
    mergeMap(({ payload }) => {
        return from(propertyOwnerService.propertyOwnerApproval(payload)).pipe(
            concatMap((res) => of(ownerApprovalSuccess({ data: res.data.data, payload: payload }),getPODetailRequest({id: payload.user_id}), updateOverallPOLoaders(), successMessageApi(res))),
            catchError((res) => of(ownerApprovalFailure(), updateOverallPOLoaders(), errorMessageApi(res))),
        )
    }),
)

/**property owner list */
const getPOList = (action$) => action$.pipe(
    ofType(getPOListRequest),
    mergeMap(({ payload }) => {
        return from(propertyOwnerService.getPOList(payload)).pipe(
            concatMap((res) => of(getPOListSuccess(res.data), updateOverallPOLoaders())),
            catchError((res) => of(getPOListFailure(), updateOverallPOLoaders(), errorMessageApi(res))),
        )
    }),
)

/** get property owner total count  */
const getPOCount = (action$, state$) => action$.pipe(
    ofType(getPOCountRequest),
    mergeMap(({ payload }) => {
        const viewType = state$.value.propertyOwner.viewType
        return from(propertyOwnerService.getPOCount(payload)).pipe(
            concatMap((res) => {
                if (viewType === "panel") {
                    return of(getPOCountSuccess(res.data), getPOLeftPanelListRequest(payload), updateOverallPOLoaders())
                } else {
                    return of(getPOCountSuccess(res.data), getPOListRequest(payload), updateOverallPOLoaders())
                }
            }),
            catchError((res) => of(getPOCountFailure(), updateOverallPOLoaders(), errorMessageApi(res))),
        )
    }),
)

// send invite emails to Property Owners
const invitePropertyOwner = (action$) => action$.pipe(
    ofType(getPOInviteRequest),
    mergeMap(({ payload }) => {
        return from(propertyOwnerService.sendPOInvites(payload)).pipe(
            concatMap((res) => of(getPOInviteSuccess(res),successMessageApi(res))),
            catchError((res) => of(getPOInviteFailure(), errorMessageApi(res)))
        )
    })
)

/*get proeprty Owner Comments*/
const getPOComments = (action$) => action$.pipe(
    ofType(getPOCommentsRequest),
    mergeMap(({ payload }) => {
        return from(propertyOwnerService.getPOComments(payload)).pipe(
            concatMap((res) => of(getPOCommentsSuccess(res.data.data))),
            catchError((res) => of(getPOCommentsFailure(), errorMessageApi(res))),
        )
    })
)

/*update property Owner Comments*/
const updatePOComments = (action$) => action$.pipe(
    ofType(updatePOCommentsRequest),
    mergeMap(({ payload }) => {
        return from(propertyOwnerService.updatePOComments(payload)).pipe(
            concatMap((res) => of(updatePOCommentsSuccess(res.data.data), getPOCommentsRequest({ id: payload.message_to }))),
            catchError((res) => of(updatePOCommentsFailure(), errorMessageApi(res))),
        )
    })
)

/*delete property Owner Comments*/
const deletePOComments = (action$) => action$.pipe(
    ofType(deletePOCommentRequest),
    mergeMap(({ payload }) => {
        return from(propertyOwnerService.deletePOComments(payload)).pipe(
            concatMap((res) => of(deletePOCommentSuccess({ data: res.data.data, payload: payload }))),
            catchError((res) => of(deletePOCommentFailure(), errorMessageApi(res))),
        )
    })
)

/**download PO Report */
const downloadPOReport = (action$) => action$.pipe(
    ofType(downloadPORequest),
    mergeMap(({ payload }) => {
        return from(propertyOwnerService.downloadPOReport(payload)).pipe(
            concatMap((res) => of(downloadPOSuccess(res), successMessageApi({message: "The property owner list has been downloaded successfully"}))),
            catchError((res) => of(downloadPOFailure(), errorMessageApi(res))),
        )
    }),
)

// Export All property Owner Epic Functions
export const propertyOwnerEpic = [
    getPOleftPanelList,
    getPODetail,
    updatePOFlag,
    propertyOwnerApproval,
    getPOList,
    getPOCount,
    invitePropertyOwner,
    getPOComments,
    updatePOComments,
    deletePOComments,
    downloadPOReport,
];