import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import moment from 'moment';

// helpers
import { defaultExcelType } from '../../helpers';

const initialState = {
    isLoading:false,
    isLazyLoading:false,
    viewType: "panel",
    panelView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 30,
            count: 0,
            sortField: "viewed",
            sortBy: 'asc'
        }
    },
    filter: {
        ownerFilter: [],
        propertiesFilter: [],
        flagFilter: [],
        propertyCountFilter: [],
        statusFilter: [],
        startDate: '',
        endDate: ''
    },
    tmpfilter: {
        ownerFilter: [],
        propertiesFilter: [],
        flagFilter: [],
        propertyCountFilter: [],
        statusFilter: [],
        startDate: '',
        endDate: ''
    },
    clearfilter:false,
    listView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 9,
            count: 0,
            sortField: "name",
            sortBy: 'asc'
        }
    },
    ownerDetail: {},
    propertyOwnerInviteEmails: [],
    comments: [],
    currentComment: {
        message: ""
    },
    ownerFileName: "",
    propertyOwnerLoaders: {
        getPOCountLoader: false,
        getPOLeftPanelLoader: false,
        getPOListLoader: false,
        getPODetailLoader: false,
        approvalLoader: false
    },
    overallPOLoaders: false
}

const propertyOwnerSlice = createSlice({
    name: 'propertyOwner',
    initialState: initialState,
    reducers: {

        // get list of owner details for left panel
        getPOLeftPanelListRequest(state) {
            state.isLazyLoading = true;
            state.propertyOwnerLoaders.getPOLeftPanelLoader = true
        },
        getPOLeftPanelListSuccess(state, action) {
            const isFirst = action.payload.pagination && action.payload.pagination.skip === 1 ? true : false
            state.panelView = {
                data: isFirst ? [...(action.payload.data || [])] : [...(state.panelView.data || []), ...(action.payload.data || [])],
                pagination: {
                    ...state.panelView.pagination,
                    ...action.payload.pagination
                }
            };
            state.isLazyLoading = false;
            state.propertyOwnerLoaders.getPOLeftPanelLoader = false
        },
        getPOLeftPanelListFailure(state) {
            state.isLazyLoading = false;
            state.propertyOwnerLoaders.getPOLeftPanelLoader = false
            state.leftPanelList = initialState.leftPanelList;
        },

        // get property owner details
        getPODetailRequest(state) {
            state.propertyOwnerLoaders.getPOCount = true
        },
        getPODetailSuccess(state, action) {
            state.propertyOwnerLoaders.getPOCount = false
            state.ownerDetail = action.payload
            const id = action.payload.id;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = action.payload ? action.payload.approval_status : indexData.approval_status
                state.panelView.data[elementIndex] = indexData
            }
        },
        getPODetailFailure(state) {
            state.propertyOwnerLoaders.getPOCount = false
            state.ownerDetails = initialState.ownerDetails;
        },

        // update property owner flag
        updatePOFlagRequest(state) {
            state.isLoading = true;
        },
        updatePOFlagSuccess(state, action) {
            state.isLoading = false;
            const id = action.payload.payload.id;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.is_flag = indexData.is_flag ? 0 : 1
                state.panelView.data[elementIndex] = indexData
            }
        },
        updatePOFlagFailure(state) {
            state.isLoading = false;
        },

        // update property owner approval status
        ownerApprovalRequest(state) {
            state.propertyOwnerLoaders.approvalLoader = true
        },
        ownerApprovalSuccess(state, action) {
            const id = action.payload.payload.user_id;
            const status = Number(action.payload.payload.approval_status);
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = status
                state.panelView.data[elementIndex] = indexData
            }
            state.ownerDetail.approval_status = status
            state.propertyOwnerLoaders.approvalLoader = false
        },
        ownerApprovalFailure(state) {
            state.propertyOwnerLoaders.approvalLoader = false
        },

        // get property owner list
        getPOListRequest(state) {
            state.propertyOwnerLoaders.getPOListLoader = true
        },
        getPOListSuccess(state, action) {
            state.listView = {
                data: action.payload.data || [],
                pagination: {
                    ...state.listView.pagination,
                    ...action.payload.pagination
                }
            };
            state.propertyOwnerLoaders.getPOListLoader = false
        },
        getPOListFailure(state) {
            state.propertyOwnerLoaders.getPOListLoader = false
        },

        // get property manager total count
        getPOCountRequest(state) {
            state.propertyOwnerLoaders.getPOCountLoader = true
        },
        getPOCountSuccess(state, action) {
            state.clearfilter = false
            state.listView.pagination = {
                ...state.listView.pagination,
                count: action.payload.data.count
            };
            state.panelView.pagination = {
                ...state.panelView.pagination,
                count: action.payload.data.count
            };
            state.propertyOwnerLoaders.getPOCountLoader = false
        },
        getPOCountFailure(state) {
            state.propertyOwnerLoaders.getPOCountLoader = false
        },

        // update property owner view type
        updatePOViewType(state, action) {
            state.viewType = action.payload
        },

        // get PM invite email response 
        getPOInviteRequest(state) {
            state.isLoading = true;
        },
        getPOInviteSuccess(state) {
            state.isLoading = false;
            state.propertyOwnerInviteEmails = initialState.propertyOwnerInviteEmails
        },
        getPOInviteFailure(state) {
            state.isLoading = false;
            state.propertyOwnerInviteEmails = initialState.propertyOwnerInviteEmails
        },

        //get property manager comments
        getPOCommentsRequest(state) {
            state.isLoading = true
            state.comments = []
        },
        getPOCommentsSuccess(state, action) {
            state.isLoading = false
            state.comments = action.payload || []
        },
        getPOCommentsFailure(state) {
            state.isLoading = false
            state.comments = []
        },

        //update property manager comments
        updatePOCommentsRequest(state) {
            state.isLoading = true
        },
        updatePOCommentsSuccess(state) {
            state.isLoading = false
            state.currentComment = {
                message: ""
            }
        },
        updatePOCommentsFailure(state) {
            state.isLoading = false
        },

        // update comment text
        updateCurrentComment(state, action) {
            state.currentComment = {
                message: action.payload
            }
        },

        //delete property manager comments
        deletePOCommentRequest(state) {
            state.isLoading = true
        },
        deletePOCommentSuccess(state, action) {
            state.isLoading = false
            const id = action.payload.payload.id;
            const elementIndex = state.comments.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                state.comments.splice(elementIndex, 1)
            }

        },
        deletePOCommentFailure(state) {
            state.isLoading = false
        },

        // update list of invite emails
        updateEmailList(state, action) {
            state.propertyOwnerInviteEmails = action.payload
        },

        clearEmailList(state){
            state.propertyOwnerInviteEmails = []
        },

        downloadPORequest(state, action) {
            state.ownerFileName = `propertyowners_${moment().format("YYYY-MM-DD_h-mm-ssA")}.xlsx`
            state.isLoading = true
        },
        downloadPOSuccess(state, action) {
            state.isLoading = false
            const blob = new Blob([action.payload.data], { type: defaultExcelType });
            saveAs(blob, state.ownerFileName || "report.xlsx");
            state.ownerFileName = ''
        },
        downloadPOFailure(state) {
            state.isLoading = false
            state.ownerFileName = ''
        },

        // update property manager filter
        updatePOFilter(state, action) {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value
            }
            state.clearfilter = false
        },

        // Revert filter to temporary filter values
        revertPOFilter(state, action){
            state.filter = {
                ...state.tmpfilter
            }
        },

        //update temporary filter
        updateTempPOFilter(state, action){
            state.tmpfilter = {
                ...state.filter
            }
        },

        clearPOFilter(state) {
            state.filter = {
                ownerFilter: [],
                propertiesFilter: [],
                flagFilter: [],
                propertyCountFilter: [],
                statusFilter: [],
                startDate: '',
                endDate: ''
            }
            state.clearfilter = true
        },
        updateOverallPOLoaders(state){
            state.overallPOLoaders = Object.values(state.propertyOwnerLoaders).some(loaderValue => loaderValue)
        }
    }
})

export const {
    getPOLeftPanelListRequest, getPOLeftPanelListSuccess, getPOLeftPanelListFailure,
    getPODetailRequest, getPODetailSuccess, getPODetailFailure,
    updatePOFlagRequest, updatePOFlagSuccess, updatePOFlagFailure,
    ownerApprovalRequest, ownerApprovalSuccess, ownerApprovalFailure,
    getPOInviteRequest, getPOInviteSuccess, getPOInviteFailure, updateEmailList,
    getPOListRequest, getPOListSuccess, getPOListFailure,
    getPOCountRequest, getPOCountSuccess, getPOCountFailure,
    updatePOViewType, clearEmailList,
    getPOCommentsRequest, getPOCommentsSuccess, getPOCommentsFailure,
    updatePOCommentsRequest, updatePOCommentsSuccess, updatePOCommentsFailure,
    updateCurrentComment,
    deletePOCommentRequest, deletePOCommentSuccess, deletePOCommentFailure,
    downloadPORequest, downloadPOSuccess, downloadPOFailure,
    updatePOFilter, clearPOFilter, updateTempPOFilter, revertPOFilter,
    updateOverallPOLoaders
} = propertyOwnerSlice.actions;
export default propertyOwnerSlice.reducer;