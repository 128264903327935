const style = {
  ".container": {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    padding: "1.5rem 1.5rem",
    // boxShadow: "0px 4px 6px #0000000F",
    paddingBottom:"0",
    boxShadow: "0px 3px 20px #0000000D",
    borderRadius:"5px",
  },
  ".custom-cluster": {
    backgroundColor: "#dfb234",
    height: "2.5em",
    width: "2.5em",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontSize: "1.2rem",
    boxShadow: "0 0 0px 5px #fcde8a",
  },
  ".inner-container": {
    backgroundColor: "rgb(0 0 0 / 3%)",
    height: "225px",
    display: "flex",
    padding: "5px 10px",
    marginTop: "10px",
    borderRadius: "5px",
  },
  ".flex-align": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  ".kpi-container" :{
    height:"100%",
    display:"flex",
    gap:"15px",
    alignItems:"center"

  },
  ".ant-picker-suffix" :{
    position: "absolute",
    left: "6px",
    color:"#314BB3",
  },
  ".ant-picker-range-arrow" : {
    insetInlineStart:"500px",
  },
  ".leaflet-div-icon": {
    background: "transparent !important", /* Remove background */
    border: "none !important" /* Remove border */
  }
};

export default style;
