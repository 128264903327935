import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import MultilineChart from "../charts-metrics/multilineChart";
//style
import Style from "../style";
import { getTenantTrendsRequest } from "../../../redux/reducer/propertiesDashboardReducer";

const TenantTrends = () => {
  const dispatch = useDispatch();
  const [moveIn, setMoveIn] = useState();
  const [moveOut, setMoveOut] = useState();
  const { properties } = useSelector((s) => s.dashboard);
  useEffect(() => {
    dispatch(getTenantTrendsRequest());
  }, [dispatch]);

  useEffect(() => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const moveInData = months.map((month, index) => {
      const monthNumber = (index + 1).toString().padStart(2, "0");
      return {
        date: `2024-${monthNumber}-01`,
        value: properties?.tenant_trends?.move_in[month] ?? 0,
      };
    });
    setMoveIn(moveInData);

    const moveOutData = months.map((month, index) => {
      const monthNumber = (index + 1).toString().padStart(2, "0");
      return {
        date: `2024-${monthNumber}-01`,
        value: properties?.tenant_trends?.move_out[month] ?? 0,
      };
    });
    setMoveOut(moveOutData);
  }, [properties?.tenant_trends]);

  return (
    <Box sx={{display:"flex", flexDirection:"column"}} className="container">
      <Typography variant="h4" sx={{ fontWeight: 700, paddingBottom: "10px" ,fontSize:"16px"}}>
        Tenant Trends
      </Typography>
      <Box sx={{flexGrow:"2"}}>

      <MultilineChart moveInData={moveIn ?? []} moveOutData={moveOut ?? []} />
      </Box>

      <Grid
        container
        xs={12}
        justifyContent={"center"}
        gap={5}
        sx={{ paddingTop: "20px" }}
      >
        {[
          { color: "#fc8c99", label: "Move In" },
          { color: "#FCC390", label: "Move Out" },
        ].map((item, index) => (
          <Grid item key={index}>
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                sx={{
                  borderRadius: "50%",
                  height: "10px",
                  width: "10px",
                  backgroundColor: item.color,
                }}
              />
              <Typography>{item.label}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default styled(TenantTrends)(Style);
