import React from "react";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup ,useMap} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import { divIcon, point } from "leaflet";
import { styled } from "@mui/material/styles";
import axios from "axios";
//style
import Style from "../style";

// create custom icon
const icon = L.icon({
  iconUrl: "../map-pin-pink.svg",
  iconSize: [30, 30],
});

// Component to move zoom control to top right
const MapZoomControl = () => {
  const map = useMap();
  map.zoomControl.setPosition("topright");
  return null;
};

// custom cluster icon
const createClusterCustomIcon = function (cluster) {
  return new L.divIcon({
    html: `<span style="background-color: #9A9BFF; border: 5px solid #D5D5FF; display: flex; align-items: center; justify-content: center; color: white; font-size: 14px; width: 40px; height: 40px; border-radius: 50%; text-align: center; line-height: 33px; box-sizing: border-box; border: 5px solid #D5D5FF; outline: none;">${cluster.getChildCount()}</span>`,
    iconSize: L.point(33, 33, true),
  });
};


const ReactLeaflet = ({ height = "80%", zips = [] }) => {
  return (
    <MapContainer
      center={[40.7178, -74.0431]}
      zoom={12}
      style={{ height, width: "100%" }}
    >
      <MapZoomControl /> {/* Custom component to move zoom control */}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        // //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        // url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />

      <MarkerClusterGroup

        chunkedLoading
        iconCreateFunction={createClusterCustomIcon}
      >
        {zips.map((zip, index) => (
          <Marker className="marker-1" key={index} position={zip} icon={icon}></Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default styled(ReactLeaflet)(Style);
