import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

//style
import Style from "../style";
import { useEffect, useState } from "react";

const CountMetrics = ({ count, content, PropertiesIcon }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initialize state with current window width
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    // Define the resize event handler
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Update state on window resize
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  useEffect(() => {
    if (windowWidth < 1200) {
      setIsSmallScreen(true)      // Add your functionality here
    }
  }, [windowWidth]); // Re-run when the windowWidth changes


  return (
    <Box className="container" style={{padding:"5px"}}>
      <Grid className="kpi-container" container alignItems={"center"}>
        <Box
          sx={{
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // mb: 2,
            ml:2,
            
          }}
        >
          {PropertiesIcon && <PropertiesIcon />}
        </Box>

        <Box sx={{mt:2}}>
        <Typography
          sx={{ mb: 1, ml: 1, fontWeight: 700, fontSize: isSmallScreen? "16px" : "21px"}}
          variant="h2"
        >
          {count ? count : "N/A"}
        </Typography>
        <Typography
          sx={{ mb: 1, ml: 1, fontSize: isSmallScreen ? "13px" :"16px" }}
          className="pb-1"
        >
          {content}
        </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default styled(CountMetrics)(Style);
