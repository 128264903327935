import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';

//style
import Style from "./style";

//Components
import { NoResult, Table } from "../../components";
import TopPanel from "./topPanel";
import RejectApplication from "./rejectApplication";
import OfferUnit from "./offerUnit";

// reducer
import {
  getWaitingListCountRequest, getWaitingListRequest, updateCheckedValues, downloadDocumentsRequest,
  approveDocumentsRequest, propertyUnitDetailsRequest, moveApplicantToWlRequest, updateTempWaitingListFilter,
} from "../../redux/reducer/waitingListReducer";
import { updateApplicantsFilter } from "../../redux/reducer/applicationsReducer";

const ListView = (props) => {
  // navigator
  const navigate = useNavigate()

  // props
  const { className } = props;

  //define dispatch
  const dispatch = useDispatch();

  // state
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [openOfferPopup, setOpenOfferPopup] = useState(false)

  //redux
  const { listView: { data, pagination, pagination: { count, skip, limit, sortField, sortBy } }, filter, checkedValues } = useSelector((s) => s.waitingList);
  const { loggedUser } = useSelector(s => (s.profile));
  const isPropertyOwner = loggedUser.user_group_id === 4

  useEffect(() => {
    // get total count for pagination and then call the data in the epic
    dispatch(
      getWaitingListCountRequest({
        ...pagination,
        skip: 0,
        filter: filter,
      })
    );
  }, [dispatch]);

  const handleFilter = () => {
    //update temporary filters on applying filters. This can be used to reset them on opening the filter drawer again
    dispatch(updateTempWaitingListFilter())
    dispatch(getWaitingListCountRequest({ ...pagination, skip: 0, isFirst: true, filter: filter }))
  }

  const handleSorting = ({ sortField, sortBy }) => {
    dispatch(
      getWaitingListCountRequest({
        ...pagination,
        sortField,
        sortBy,
        skip: 0,
        isFirst: true,
        filter: filter,
      })
    );
  };

  const handleActionClick = (action, data, event) => {
    switch (action) {
      case "DOWNLOAD":
        dispatch(downloadDocumentsRequest(data))
        break;
      case "APPROVE":
        dispatch(approveDocumentsRequest({ id: data.id, docIds: [] }))
        break;
      case "REJECT":
        setOpenRejectPopup({ target: event.currentTarget, data: data })
        break;
      case "MOVE_TO_WAITINGLIST":
        dispatch(moveApplicantToWlRequest({ applicationId: data.id }))
        break;
      case "OFFER":
        dispatch(propertyUnitDetailsRequest({ id: data.property_id }))
        setOpenOfferPopup({ target: event.currentTarget, data: data })
        break;
      default:
        break;
    }
  }

  const showHideIcon = (action, data) => {
    switch (action) {
      case "DOWNLOAD":
        return data && data.doc_count ? false : true
      case "APPROVE":
        return data && data.doc_count && (data.approval_status === 6 || data.approval_status === 8) ? false : true
      case "REJECT":
        return data && data.approval_status !== 14 && !([2,3].includes(data.tenant_status))? false : true
      case "MOVE_TO_WAITINGLIST":
        return data && [5, 6, 7, 8, 9, 11, 13].includes(data.approval_status) ? false : true
      case "OFFER":
        return data && (data.approval_status === 7 || data.approval_status === 13) && data.vacant_count > 0 ? false : true
      default:
        return false
    }
  }

  const viewDetailSwitch = (e) => {
    dispatch(updateApplicantsFilter({ name: "applicationsFilter", value: [{ id: e.id, name: e.name }] }))
    navigate(`/applications`);
  }

  return (
    <>
      <Grid container className={className}>
        <Grid item xs={12}>
          <TopPanel
            IconsList={["CHILDREN", "FILTER_LIST", "DOWNLOAD", "REQUEST_DOC"]}
            sortValue={`${sortField}-${sortBy}`}
            handleSorting={handleSorting}
            handleFilter={handleFilter}
          />
        </Grid>
        <Grid item xs={12} className="bodyContainer pt-2">
          {count > 0 ?
            <Table
              header={["Applicant", "Property Address", "Unit Type", "Affordability  Level", "Lottery No", "Lottery Details", "Approval Status"]}
              fields={["name", "address_string", "unit_type_name", "aff_level", "lottery_number", "lottery_date", "ap_status"]}
              data={data}
              skip={skip}
              totalCount={count}
              limit={limit}
              showAvatar={true}
              updatePage={(e, val) => {
                dispatch(getWaitingListRequest({ ...pagination, skip: val - 1, filter: filter }));
              }}
              showCheckBox={(data) => {
                return ([4, 6].includes(data.approval_status))
              }}
              checkedValues={checkedValues}
              handleCheckboxClick={(val) => {
                dispatch(updateCheckedValues(val));
              }}
              actions={isPropertyOwner ? [] : ["DOWNLOAD", "APPROVE", "REJECT", "MOVE_TO_WAITINGLIST", "OFFER"]}
              handleLinkClick={(e) => { viewDetailSwitch(e) }}
              linkFields={["name"]}
              handleActionClick={handleActionClick}
              showHideIcon={showHideIcon}
            />
            :
            <NoResult text="No Applications Found" />
          }
        </Grid>
      </Grid>
      {openRejectPopup && <RejectApplication openRejectPopup={openRejectPopup} setOpenRejectPopup={setOpenRejectPopup} />}
      {openOfferPopup && <OfferUnit openOfferPopup={openOfferPopup} setOpenOfferPopup={setOpenOfferPopup} />}
    </>
  );
};

// default props
ListView.defaultProps = {
  classes: {},
};

// prop types
ListView.propTypes = {
  classes: PropTypes.object,
};

export default styled(ListView)(Style);
