import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { getPropertyApprovalStatusRequest } from "../../../redux/reducer/propertiesDashboardReducer";
import Style from "../style"
const PropertyApprovalStatus = () => {
  const dispatch = useDispatch();
  const { properties } = useSelector((s) => s.dashboard);
  
  useEffect(() => {
    dispatch(getPropertyApprovalStatusRequest());
  }, [dispatch]);

  return (
    <Box className="container">
      <Typography variant="h4" sx={{ fontWeight: 700, mb: 3 , fontSize:"16px"}}>
        Property Approval Status
      </Typography>

      <Box className="inner-container">
        <Grid 
          container 
          direction="column"
          spacing={2}
        >

          {/* Approved */}
          <Grid item>
            <Box sx={{ 
              marginLeft: "10px",
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '90%'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: "25px"}}>
                <CheckCircleIcon sx={{ color: "#8FDD81" }} />
                <Typography variant="h5" sx={{ color: "#8FDD81" }}>
                  Approved
                </Typography>
              </Box>
              <Typography variant="h2" sx={{ marginTop: "25px",fontSize:"16px" }}>
                {properties?.property_approval_status?.approved ?? "N/A"}
              </Typography>
            </Box>
          </Grid>

          {/* Review Pending */}
          <Grid item>
            <Box sx={{ 
              marginLeft:"10px",
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '90%'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop:"25px" }}>
                <WatchLaterIcon sx={{ color: "#D8B44D" }} />
                <Typography variant="h5" sx={{ color: "#D8B44D" }}>
                  Review Pending
                </Typography>
              </Box>
              <Typography variant="h2" sx={{ marginTop: "25px" ,fontSize:"16px"}}>
                {properties?.property_approval_status?.review_pending ?? "N/A"}
              </Typography>
            </Box>
          </Grid>

          {/* Rejected */}
          <Grid item>
            <Box sx={{ 
              marginLeft: "10px",
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '90%'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: "12.5px" }}>
                <CancelIcon sx={{ color: "#FF8686" }} />
                <Typography variant="h5" sx={{ color: "#FF8686" }}>
                  Rejected
                </Typography>
              </Box>
              <Typography variant="h2" sx={{ marginTop: "12.5px", fontSize:"16px" }}>
                {properties?.property_approval_status?.rejected ?? "N/A"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default styled(PropertyApprovalStatus)(Style);