import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker, Space } from "antd";
import Style from "./style";
import PropertiesDashboard from "./dashboard-properties";
import ApplicationsDashboard from "./dashboard-applications";
import { DownloadIcon } from "../../assets/svg";
import { useDashboardDownload } from './dashboard-download';
import { updatePropertyDashboardFilter } from "../../redux/reducer/propertiesDashboardReducer";
import { updateApplicationDashboardFilter } from "../../redux/reducer/applicantsDashboardReducer";
import { TabHeader, TabPanel, MultiSelectAsync } from "../../components";
import { propertiesService } from "../../redux/service";
import moment from 'moment';

const { RangePicker } = DatePicker;
 
const Dashboard = (props) => {
  const {
    loggedUser: { user_group_id },
  } = useSelector((s) => s.profile);
  const [tabIndex, setTabIndex] = useState(0);
  const [height, setHeight] = useState('calc(100vh - 215px)');
  const [dateRange, setDateRange] = useState([]);
 
  const [dataFilters, setDataFilters] = useState({
    startDate : null,
    endDate : null,
    propertiesData : null
  })
  const { className } = props;
  const contentRef = useRef(null);
 
  const dispatch = useDispatch();
  const {filter : propertiesFilter} = useSelector(s => (s.dashboard))
  const {filter : applicationFilter } = useSelector(s => (s.applicantsDashboard))
  const { handleDownload } = useDashboardDownload(contentRef, tabIndex, dataFilters);
 
  const getAllProperties = async () => {
    const response = await propertiesService.getAllPropertiesList()
    return response;
  }
  
  const handleChange = (event) => {
    const filterData = {
      start_date: dateRange.length ? dateRange[0].format("YYYY-MM-DD"): "",
      end_date: dateRange.length ? dateRange[1].format("YYYY-MM-DD"): "",
      properties: [...event.value]
    }
 
    setDataFilters((prevFilters)=>({
      ...prevFilters,
      propertiesData : filterData?.properties
    })
    )
 
    if(tabIndex === 0){
      dispatch(updatePropertyDashboardFilter(filterData));
    } else {
      dispatch(updateApplicationDashboardFilter(filterData));
    }
  };
 
  const handleFilter = (value) => {
    if (value === null)
      value = [];
 
    setDateRange(value);
    
    let filterData = {
      start_date: value[0]?.format("YYYY-MM-DD"),
      end_date: value[1]?.format("YYYY-MM-DD"),
    };
 
    setDataFilters((prevFilters)=>({
      ...prevFilters,
    startDate: filterData?.start_date,
    endDate : filterData?.end_date
    })
    )
  
  
    if (tabIndex === 0) {
      filterData.properties = propertiesFilter.properties;
      dispatch(updatePropertyDashboardFilter(filterData));
    } else {
      filterData.properties = applicationFilter.properties;
      dispatch(updateApplicationDashboardFilter(filterData));
    }
  };
 
  const resetFilter = (tabIndex) => {
    const resetFilter = {
      start_date: [],
      end_date: [],
      properties: []
    }
 
    dispatch(updatePropertyDashboardFilter(resetFilter));
    dispatch(updateApplicationDashboardFilter(resetFilter));
    setDateRange([]);
  }
 
    // Disable future dates
    const disabledDate = (current) => {
      // Disable dates after today
      return current && current > moment().endOf('day');
    };
 
  return (
    <Grid container className={className}>
      <Grid item xs={12} style={{ margin: "15px 15px 0px 15px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h1" className="fw-600">
              Dashboard
            </Typography>
            <Typography variant="h6" className="pb-1">
              {user_group_id === 2
                ? "Welcome to New Jersey Property Manager Portal"
                : "Welcome to New Jersey City Staff Portal"}
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ minWidth: 170, marginRight: "10px" }}>
                <MultiSelectAsync
                  variant={"outlined"}
                  id={"propertyFilter"}
                  name={"propertyFilter"}
                  placeholder={"Properties"}
                  className={`sm`}
                  value={tabIndex === 0 ? propertiesFilter.properties : applicationFilter.properties}
                  handleChange={(e) => { handleChange(e) }}
                  initialValue={tabIndex === 0 ? propertiesFilter.properties : applicationFilter.properties}
                  apiCallback={getAllProperties}
                  matchBy={"id"}
                  display={"name"}
                  // limitTags={1}
                />
              </Box>
              <Space direction="vertical" size={5}>
                <RangePicker
                  format="YYYY-MM-DD"
                  placeholder={["From Date", "To Date"]}
                  onChange={handleFilter}
                  value={dateRange}
                  disabledDate={disabledDate} // Disable future dates
                  style={{paddingLeft:"35px"}}
 
 
                />
              </Space>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{marginLeft:"35px"}}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            position:"relative",
          }}
        >
          <TabHeader
            tabStyle={"inline"}
            tabIndex={tabIndex}
            tabList={["Properties", "Applications / Applicants"]}
            onTabChange={(newValue) => {
                resetFilter(newValue)
                setTabIndex(newValue)
              }
            }
          />
          <Box sx={{position:"absolute", right:"25px"}}>

          <Button
            variant="text"
            size="small"
            startIcon={<DownloadIcon />}
            aria-label="Download"
            onClick={() => handleDownload(setHeight)}
            sx={{ zIndex: 10, marginRight: "15px" }}
            >
            Download
          </Button>
            </Box>
        </Box>
      </Grid>
 
      <Grid item xs={12} ref={contentRef}>
        <Grid className={"tabsBodySection"}>
          <Grid className={"tabsBody"}>
            {tabIndex === 0 && (
              <TabPanel
                value={0}
                index={tabIndex}
                height={height}
                // sx={{ overflowY: "auto" }}
                hideOverflow={true}
              > 
              <Box >

                <PropertiesDashboard />
              </Box>
              </TabPanel>
            )}
            {tabIndex === 1 && (
              <TabPanel
                boxClass="commentBox"
                value={1}
                index={tabIndex}
                height={height}
                sx={{ overflowY: "auto" }}
                hideOverflow={true}
              >
                <ApplicationsDashboard />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
 
export default styled(Dashboard)(Style);