import React, { useMemo, useState } from "react";
import * as d3 from "d3";

const MARGIN = 0; // You can adjust this if needed, or set it to 0 to take up all space

const PieChart = ({ width, height, value, percentage, colors, ageRange }) => {
  const [tooltipText, setTooltipText] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const radius = Math.min(width, height) / 2 - MARGIN; // Adjust radius to be half of the SVG size

  const pie = useMemo(() => {
    const pieGenerator = d3.pie().value((d) => d?.value);
    const remainingValueofChart = 100 - percentage
    return pieGenerator([
      { value: percentage }, // the percentage slice
      { value: remainingValueofChart }, // the remaining slice
    ]);
  }, [value, percentage]);

  const arcPathGenerator = d3.arc().innerRadius(radius * 0.8).outerRadius(radius); // Adjust the inner radius if needed

  const handleMouseOver = (event, d) => {
    setTooltipText(`Percentage: ${percentage}`);
    const svgRect = event.target.getBoundingClientRect();
    const x = event.clientX - svgRect.left + 10;
    const y = event.clientY - svgRect.top + 10;
    setTooltipPosition({ top: y, left: x });
  };

  const handleMouseMove = (event) => {
    const svgRect = event.target.getBoundingClientRect();
    const x = event.clientX - svgRect.left + 10;
    const y = event.clientY - svgRect.top + 10;
    setTooltipPosition({ top: y, left: x });
  };

  const handleMouseOut = () => {
    setTooltipText("");
  };


  return (
  //  value>0 &&
    (ageRange!="Others") &&
    <div style={{ textAlign: "center", position: "relative" }}>
      <svg width={width} height={height} style={{ display: "inline-block" }}>
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          {pie.map((p, i) => (
            <path
              key={i}
              d={arcPathGenerator(p)}
              fill={colors[i]}
              onMouseOver={(event) => handleMouseOver(event, pie[i])}
              onMouseMove={handleMouseMove}
              onMouseOut={handleMouseOut}
            />
          ))}
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: "14px", fill: "black", fontWeight: "bold" }}
          >
            {value}
          </text>
        </g>
      </svg>

      {/* {tooltipText && (
        <div
          className="tooltip"
          style={{
            position: "absolute",
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ddd",
            borderRadius: "3px",
            boxShadow: "0 0 3px rgba(0, 0, 0, 0.2)",
            fontSize: "12px",
            fontWeight: "bold",
            zIndex: 10,
            width: "100px",
            pointerEvents: "none",
            whiteSpace: "pre-line",
          }}
        >
          {tooltipText}%
        </div>
      )} */}

      <div style={{ fontSize: "12px", marginTop: "5px", color: "#2E384D" }}>
        {ageRange}
      </div>
    </div>
  );
};

export default PieChart;
