import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import GroupedBarChart from "../charts-metrics/groupedBarChart";
import { useDispatch, useSelector } from "react-redux";
import { getIncomeRentRequest } from "../../../redux/reducer/applicantsDashboardReducer";

const RentSpendPercentage = () => {
  const [isDataAvailable, setIsDataAvailable] = useState(false)
  const colors = ["#9A9BFF", "#FC8C99", "#FCC390", "#A2F0C6"];
  const subgroups = ["Very Low", "Low", "Moderate", "Workforce"]; 
  const dispatch = useDispatch();
  const { applicants } = useSelector((s) => s.applicantsDashboard);

  useEffect(() => {
    dispatch(getIncomeRentRequest());
  }, [dispatch]);

  const {
    loggedUser: { user_group_id },
  } = useSelector((s) => s.profile);

  const transformData = (incomeRent) => {
    const bedroomTypes = [
      { label: "Studio", key: "Studio" },
      { label: "1BR", key: "1-Bedroom" },
      { label: "2BR", key: "2-Bedroom" },
      { label: "3BR", key: "3-Bedroom" },
      { label: "4BR", key: "4-Bedroom" },
    ]; 

    return bedroomTypes.map(({ label, key }) => {
      const affordabilityData = incomeRent[key] || {}; 
      return {
        bedroomType: label,
        data: subgroups.map((subgroup) => ({
          name: subgroup,
          value: affordabilityData[subgroup] != null ? affordabilityData[subgroup] : 0,
        })),
      };
    });
  };

  const chartData = applicants?.income_rent ? transformData(applicants.income_rent) : [];


const calculateTotalAveragePercentage = (data) => 
  Object.values(data).reduce((total, bedrooms) => 
    total + Object.values(bedrooms).reduce(
      (bedTotal, { average_percentage = 0 }) => bedTotal + (average_percentage || 0), 0
    ), 0
  );

  

  useEffect(()=>{


    const totalAveragePercentage = calculateTotalAveragePercentage(applicants?.income_rent);
    if(totalAveragePercentage > 0){
      setIsDataAvailable(true)
    }else{
      setIsDataAvailable(false)
    }

  }, [applicants?.income_rent])


  return (
    <Box className="container" >
      <Box sx={{height:"95%"}}>
      <Typography variant="h4" sx={{ fontWeight: 700, marginBottom: 2,fontSize:"16px" }}>
        % of Income Spent on Rent
      </Typography>

        <Box sx={{height:"95%",display:"flex", flexDirection:"column", justifyContent:"space-evenly", marginTop : "-15px"}}>
      {
        isDataAvailable 
        ? <GroupedBarChart data={chartData} colors={colors} subgroups={subgroups} />
        :  <Typography variant="h2" sx={{ height:"80%", display:"grid", placeItems:"center" ,fontSize:"16px"}}>
                  No Data Found
           </Typography>
      }
      
      
      <Grid container spacing={2} justifyContent="center" sx={{ marginTop: user_group_id === 3 ? -7 : -5 }}>
        {subgroups.map((label, index) => (
          <Grid item key={index}>
            <Box display="flex" alignItems="center" gap={1} justifyContent="center">
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: colors[index],
                }}
              />
              <Typography variant="body2">{label}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      </Box>
      </Box>

    </Box>
  );
};

export default styled(RentSpendPercentage)(() => ({}));