import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BarChart from "../charts-metrics/barChart";
import { getAverageHouseholdIncomeRequest } from "../../../redux/reducer/propertiesDashboardReducer";

const AverageHouseholdIncome = () => {
  const dispatch = useDispatch();
  const { properties } = useSelector((s) => s.dashboard);
  
  useEffect(() => {
    dispatch(getAverageHouseholdIncomeRequest());
  }, [dispatch]);

  const [incomeType, setIncomeType] = React.useState("Unit-type");
  const [data, setData] = useState([]); // Initial state as empty array
  const [isEmptyData, setIsEmptyData] = useState(false)

  useEffect(() => {
    setData([]);
    if (incomeType === "Unit-type") {
      const unitTypeData = Object.entries(
        properties?.average_household_income?.unit_type || {}
      ).map(([key, value]) => {
        const nameMap = {
          studio: "Studio",
          "1bedroom": "1 BR",
          "2bedroom": "2 BR",
          "3bedroom": "3 BR",
          "4bedroom": "4 BR",
        };
        return { name: nameMap[key], value: value };
      });
      setData(unitTypeData);
    } else if (incomeType === "Affordability-level") {
      const affordabilityLevelData = Object.entries(
        properties?.average_household_income?.affordability_level || {}
      ).map(([key, value]) => {
        const nameMap = {
          verylow: "Very low",
          low: "Low",
          moderate: "Moderate",
          workforce: "Workforce",
        };
        return { name: nameMap[key], value: value };
      });
      setData(affordabilityLevelData);
    }
  }, [incomeType, properties?.average_household_income]);

  const handleChange = (event) => {
    setIncomeType(event.target.value); // Update income type on change
  };


  useEffect(() => {
    if (data.length > 0) {
      let totalValue = 0 
      for (let item of data) {
        totalValue += item?.value
      }
      if (totalValue > 0 ){
        setIsEmptyData(false)
      }else{
        setIsEmptyData(true)
      }
    }
  }, [data]);


  return (
    <Box className="container">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 700, mb: 3 ,fontSize:"16px"}}>
          Average Household Income
        </Typography>
        <FormControl sx={{ m: 1 }}>
          <Select
            value={incomeType}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "level" }}
            sx={{ height: 30 }}
          >
            <MenuItem value={"Unit-type"}>Unit-type</MenuItem>
            <MenuItem value={"Affordability-level"}>
              Affordability-level
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      {/* Pass the showDollarSymbol prop as true */}
      
      {
      !isEmptyData

      ? data.length > 0 &&  (
        <BarChart key={incomeType} data={data}  showDollarSymbol={true} isHouseholdIncome={true} centerXAxisLabels={true} />
      )

      : <Typography variant="h2" sx={{height:"80%", display:"grid", placeItems:"center",fontSize:"16px"}}>
     No Data Found
    </Typography>
      
      }
    </Box>
  );
};

export default AverageHouseholdIncome; 