import { Box } from "@mui/material";
import { Responsive, WidthProvider } from "react-grid-layout";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Style from "./style";
import CountMetrics from "./charts-metrics/countMetrics";
import RaceEthnicity from "./widgets-applications/raceEthnicity";
import ApplicationsStatus from "./widgets-applications/applicationsStatus";
import AgeSegmentation from "./widgets-applications/ageSegmentation";
import RentSpendPercentage from "./widgets-applications/rentSpendPercentage";
import ApplicationDistribution from "./widgets-applications/applicationDistribution";
import AffordabilityDistribution from "./widgets-applications/affordabilityDistribution";
import ApplicationAgeSegmentation  from "./widgets-applications/applicationAge";
import GenderSegmentation from "./widgets-applications/genderSegmentation";


import {
  TotalApplicantsIcon,
  TotalTenantsIcon,
  TotalMoveInsIcon,
} from "../../assets/svg";

import {
  getTotalApplicantsRequest,
  getTotalTenantsRequest,
} from "../../redux/reducer/applicantsDashboardReducer";


const ResponsiveGridLayout = WidthProvider(Responsive);

const ApplicationsDashboard = () => {
  const dispatch = useDispatch();
  const { applicants } = useSelector((s) => s.applicantsDashboard);

  useEffect(() => {
    dispatch(getTotalApplicantsRequest());
    dispatch(getTotalTenantsRequest());
  }, [dispatch]);

  const {
    loggedUser: { user_group_id },
  } = useSelector((s) => s.profile);

  const applicantsLayouts = {
    lg: (user_group_id === 2 || user_group_id === 4)
          ? [
              { i: "Applicants", x: 0, y: 0, w: 3, h: 1.5, static: true },
              { i: "Tenants", x: 0, y: 1.5, w: 3, h: 1.5, static: true },
              { i: "MoveIns", x: 0, y: 3, w: 3, h: 1.5, static: true },
              { i: "AffordabilityDistribution", x: 3, y: 0, w: 3, h: 4.5, static: true },
              { i: "RentSpendPercentage", x: 6, y: 0, w: 6, h: 4.5, static: true },
              { i: "ApplicationsStatus", x: 0, y: 4.5, w: 7.5, h: 6, static: true },
              { i: "ApplicationDistribution", x: 7.5, y: 4.5, w: 4.5, h: 6, static: true },
              { i: "spacer", x: 0, y: 10.5, w: 12, h: 0.4, static: true },
            ]
          : [
            { i: "Applicants", x: 0, y: 0, w: 3, h: 1.5, static: true },
            { i: "Tenants", x: 0, y: 1.5, w: 3, h: 1.5, static: true },
            { i: "MoveIns", x: 0, y: 3, w: 3, h: 1.5, static: true },
            { i: "AffordabilityDistribution", x: 3, y: 0, w: 3, h: 4.5, static: true },
            { i: "RaceEthnicity", x: 0, y: 9, w: 12, h: 4.5, static: true },
            { i: "ApplicationsStatus", x: 3, y: 4.5, w: 9, h: 4.5, static: true },
            { i: "RentSpendPercentage", x: 0, y: 13.5, w: 6, h: 5, static: true },
            { i: "ApplicationDistribution", x: 6, y: 13.5, w: 6, h: 5, static: true },
            { i: "GenderSegmentation", x: 6, y: 0, w: 6, h: 4.5, static: true },
            { i: "ApplicantAge", x: 0, y: 4.5, w: 3, h: 4.5, static: true },
            { i: "spacer", x: 0, y: 18.5, w: 12, h: 0.4, static: true },
            ],

    md: (user_group_id === 2 || user_group_id === 4)
          ? [
            { i: "Applicants", x: 0, y: 0, w: 2.5, h: 1.5, static: true },
            { i: "Tenants", x: 0, y: 1.5, w: 2.5, h: 1.5, static: true },
            { i: "MoveIns", x: 0, y: 3, w: 2.5, h: 1.5, static: true },
            { i: "AffordabilityDistribution", x: 2.5, y: 0, w: 3, h: 4.5, static: true },
            { i: "RentSpendPercentage", x: 5.5, y: 0, w: 4.5, h: 4.5, static: true },
            { i: "ApplicationsStatus", x: 0, y: 4.5, w: 6.5, h: 6, static: true },
            { i: "ApplicationDistribution", x: 6.5, y: 4.5, w: 3.5, h: 6, static: true },
            { i: "spacer", x: 0, y: 10.5, w: 12, h: 0.4, static: true },
            ]
          : [
            { i: "Applicants", x: 0, y: 0, w: 2.5, h: 1.5, static: true },
            { i: "Tenants", x: 0, y: 1.5, w: 2.5, h: 1.5, static: true },
            { i: "MoveIns", x: 0, y: 3, w: 2.5, h: 1.5, static: true },
            { i: "AffordabilityDistribution", x: 2.5, y: 0, w: 3, h: 4.5, static: true },
            { i: "GenderSegmentation", x: 5.5, y: 0, w: 4.5, h: 4.5, static: true },
            { i: "ApplicantAge", x: 0, y: 4.5, w: 3, h: 4.5, static: true },
            { i: "ApplicationsStatus", x: 3, y: 4.5, w: 7, h: 4.5, static: true },
            { i: "RaceEthnicity", x: 0, y: 9, w: 10, h: 4.5, static: true },
            { i: "RentSpendPercentage", x: 0, y: 13.5, w: 5, h: 5, static: true },
            { i: "ApplicationDistribution", x: 5, y: 13.5, w: 5, h: 5, static: true },
            { i: "spacer", x: 0, y: 18.5, w: 12, h: 0.4, static: true },


            ],

    sm: (user_group_id === 2 || user_group_id === 4)
          ? [
            { i: "Applicants", x: 0, y: 0, w: 2.5, h: 1.5, static: true },
            { i: "Tenants", x: 0, y: 1.5, w: 2.5, h: 1.5, static: true },
            { i: "MoveIns", x: 0, y: 3, w: 2.5, h: 1.5, static: true },
            { i: "AffordabilityDistribution", x: 2.5, y: 0, w: 3.5, h: 4.5, static: true },
            { i: "RentSpendPercentage", x: 0, y: 10.5, w: 3, h: 5, static: true },
            { i: "ApplicationsStatus", x: 0, y: 4.5, w: 6, h: 6, static: true },
            { i: "ApplicationDistribution", x: 3, y: 10.5, w: 3, h: 5, static: true },
            ]
          : [
            { i: "Applicants", x: 0, y: 0, w: 2.5, h: 1.5, static: true },
            { i: "Tenants", x: 0, y: 1.5, w: 2.5, h: 1.5, static: true },
            { i: "MoveIns", x: 0, y: 3, w: 2.5, h: 1.5, static: true },
            { i: "AffordabilityDistribution", x: 2.5, y: 0, w: 3.5, h: 4.5, static: true },
            { i: "ApplicantAge", x: 0, y: 4.5, w: 2.5, h: 4.5, static: true },
            { i: "GenderSegmentation", x: 2.5, y: 4.5, w: 3.5, h: 4.5, static: true },
            { i: "RaceEthnicity", x: 0, y: 9, w: 6, h: 7, static: true },
            { i: "ApplicationsStatus", x: 0, y: 16, w: 6, h: 4.5, static: true },
            { i: "RentSpendPercentage", x: 0, y: 20.5, w: 6, h: 5, static: true },
            { i: "ApplicationDistribution", x: 0, y: 25.5, w: 6, h: 5, static: true },


            ],

    xs: (user_group_id === 2 || user_group_id === 4)
          ? [
            { i: "Applicants", x: 0, y: 0, w: 4, h: 1.5, static: true },
            { i: "Tenants", x: 0, y: 1.5, w: 4, h: 1.5, static: true },
            { i: "MoveIns", x: 0, y: 3, w: 4, h: 1.5, static: true },
            { i: "AffordabilityDistribution", x: 0, y: 4.5, w: 4, h: 4.5, static: true },
            { i: "RentSpendPercentage", x: 0, y: 9, w: 4, h: 6, static: true },
            { i: "ApplicationsStatus", x: 0, y: 15, w: 4, h: 6, static: true },
            { i: "ApplicationDistribution", x: 0, y: 21, w: 4, h: 6, static: true },
            ]
          : [
            { i: "Applicants", x: 0, y: 0, w: 4, h: 1.5, static: true },
            { i: "Tenants", x: 0, y: 1.5, w: 4, h: 1.5, static: true },
            { i: "MoveIns", x: 0, y: 3, w: 4, h: 1.5, static: true },
            { i: "AffordabilityDistribution", x: 0, y: 4.5, w: 4, h: 4.5, static: true },
            { i: "GenderSegmentation", x: 0, y: 9, w: 4, h: 4, static: true },
            { i: "ApplicantAge", x: 0, y: 13, w: 4, h: 4.5, static: true },
            { i: "RaceEthnicity", x: 0, y: 17.5, w: 12, h: 13, static: true },
            { i: "ApplicationsStatus", x: 3, y: 30.5, w: 9, h: 4.5, static: true },
            { i: "RentSpendPercentage", x: 0, y: 35, w: 6, h: 5, static: true },
            { i: "ApplicationDistribution", x: 6, y:40, w: 6, h: 5, static: true },


            ],
  };

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={applicantsLayouts}
      breakpoints={{ lg: 1339, md: 1000, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      rowHeight={60}
      margin={[15, 15]}
    >
      <Box key="Applicants">
        <CountMetrics
          count={applicants?.total_applicants ?? 0}
          content={"No. of Applications"}
          PropertiesIcon={TotalApplicantsIcon}
        />
      </Box>
      <Box key="MoveIns">
        <CountMetrics
          count={applicants?.total_tenants ?? 0}
          content={"No. of Move-Ins"}
          PropertiesIcon={TotalMoveInsIcon}
          />
      </Box>
      <Box key="Tenants">
        <CountMetrics
          count={applicants?.total_moveins ?? 0}
          content={"No. of Tenants"}
          PropertiesIcon={TotalTenantsIcon}
        />
      </Box>
      <Box key="AffordabilityDistribution">
        <AffordabilityDistribution />
      </Box>
      {user_group_id == 3 && (
        <Box key="RaceEthnicity">
          <RaceEthnicity />
        </Box>
      )}
      {user_group_id == 3 && (
        <Box key="GenderSegmentation">
          <GenderSegmentation />
        </Box>
      )}
   
     <Box key="ApplicationsStatus" >
        <ApplicationsStatus />
      </Box>
      <Box key="RentSpendPercentage">
        <RentSpendPercentage />
      </Box>
      <Box key="ApplicationDistribution" >
        <ApplicationDistribution />
      </Box>
      {user_group_id == 3 && (
        <Box key="ApplicantAge">
          <ApplicationAgeSegmentation />
        </Box>
      )} 

      <Box key="spacer"  />
    </ResponsiveGridLayout>
  );
};

export default styled(ApplicationsDashboard)(Style);
