import { postRequest } from "../../helpers";

async function getProperties(filters = []) {
  const response = await postRequest(`dashboard/properties/total-properties/`, filters);
  return response;
}

async function getUnitsOccupancy(filters = []) {
  const response = await postRequest(`dashboard/properties/unit-occupancy/`, filters);
  return response;
}

async function getPropertyApprovalStatus(filters = []) {
  const response = await postRequest(`dashboard/properties/approval-status/`, filters);
  return response;
}

async function getTenantTrends(filters = []) {
  const response = await postRequest(`dashboard/properties/tenant-trends/`, filters);
  return response;
}

async function getUnitTypeDistribution(filters = []) {
  const response = await postRequest(`dashboard/properties/unit-type-distribution/`, filters);
  return response;
}

async function getAverageHouseholdIncome(filters = []) {
  const response = await postRequest(`dashboard/properties/average-income/`, filters);
  return response;
}

async function getPropertyDistribution(filters = []) {
  const response = await postRequest(`dashboard/properties/properties-map/`, filters);
  return response;
}

async function getAverageHabitationLength(filters = []) {
  const response = await postRequest(`dashboard/properties/habitation-length/`, filters);
  return response;
}

// Export all API functions as part of dashboardService
export const dashboardService = {
  getProperties,
  getUnitsOccupancy,
  getPropertyApprovalStatus,
  getTenantTrends,
  getUnitTypeDistribution,
  getAverageHouseholdIncome,
  getPropertyDistribution,
  getAverageHabitationLength,
};