import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

//style
import Styles from '../style'

//Components
import { Button, Dialog, TabHeader, TabPanel } from "../../../components";
import Property from "./property";
import UnitsRents from "./unitsRents";

// reducer
import { updateGeneralRequest, updateUnitsRequest, resetPropertyData } from "../../../redux/reducer/propertiesReducer";
import { getMasterAmenitiesRequest, getMasterUnitsRequest } from "../../../redux/reducer/globalReducer";
import { errorMessage } from "../../../redux/reducer/uiReducer";

// helpers
import { propertiesValidation, unitsValidation } from "../../../helpers";

const CreateProperties = (props) => {
    //define dispatch
    const dispatch = useDispatch();
    // navigator
    const navigate = useNavigate();

    //props
    const { className } = props;

    // state
    const [tabIndex, setTabIndex] = useState(0);
    const [validate, setValidate] = useState(false);
    const [unitsValidate, setUnitsValidate] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [saveType, setSaveType] = useState('')

    //reducer
    const { currentProperty, propertySubmit, unitsSubmit, isDetailEdited, unitPhotos } = useSelector(s => (s.properties))

    // get all master amenities and untis
    useEffect(() => {
        setTabIndex(0)
        dispatch(getMasterUnitsRequest())
        dispatch(getMasterAmenitiesRequest())
        dispatch(resetPropertyData())
    }, [dispatch])

    useEffect(() => {
        if (propertySubmit) {
            if(saveType===0){
                navigate(`/properties`)
            }else{
                setTabIndex(1)
            }
        }
    }, [propertySubmit])

    useEffect(() => {
        if (unitsSubmit) {
            navigate(`/properties`)
        }
    }, [unitsSubmit])


    // handle save
    const handleSubmit = (approvalStatus) => {
        if (tabIndex === 0) {
            if(approvalStatus !== 0){
                setValidate(true);
            }
            let tmpProperty = {
                ...currentProperty,
                general: {
                    ...currentProperty.general,
                    //owner_id: currentProperty.general.property_owner ? currentProperty.general.property_owner.id : null,
                    owner_id: currentProperty.general.property_owner?.id || null,
                    preference : currentProperty.general.preference ? currentProperty.general.preference : "0", 
                    approval_status: 0,
                    city_id: currentProperty.general.city && currentProperty.general.city.id ? currentProperty.general.city.id : null,
                    state_id: currentProperty.general.state && currentProperty.general.state.id ? currentProperty.general.state.id : null,
                    //owner_id : !currentProperty.general.owner_name ? currentProperty.general.property_owner.id : null,
                    // manager_id : !currentProperty.general.manager_name ? currentProperty.general.property_manager.id : null,

                    // check unit details are filled or not for sending pm approval
                    detailsFilled : (!unitsValidation(currentProperty.units, unitPhotos, approvalStatus))
                }
            }

            delete tmpProperty.general.city;
            delete tmpProperty.general.state;

            const validation = propertiesValidation(tmpProperty.general, tmpProperty.photos, approvalStatus)
            if (!validation) {
                dispatch(updateGeneralRequest(tmpProperty))
            } else {
                dispatch(errorMessage(validation));
            }
        } else if (tabIndex === 1) {
            if(approvalStatus !== 0){
                setUnitsValidate(true);
            }
            const validation = unitsValidation(currentProperty.units, unitPhotos, approvalStatus)
            if (!validation) {
                // check property details are filled or not for sending pm approval
                const propertyValid = propertiesValidation({
                    ...currentProperty.general,
                    approval_status: currentProperty.units && currentProperty.units.length ? approvalStatus : 0,
                    city_id: currentProperty.general.city && currentProperty.general.city.id ? currentProperty.general.city.id : null,
                    state_id: currentProperty.general.state && currentProperty.general.state.id ? currentProperty.general.state.id : null
                }, currentProperty.photos, approvalStatus)
                dispatch(updateUnitsRequest({ ...currentProperty, approvalStatus: approvalStatus, detailsFilled : !propertyValid }))
            } else {
                dispatch(errorMessage(validation));
            }
        }
    }

    return (
        <Grid className={className}>
            <Grid item className="titleContainer">
                <Typography variant="h3" className="fw-500">Create New Property</Typography>
                <Grid className="dflex alignCenter">
                    <Button variant={"text"} color="primary" size="medium" className={"mr15"}
                        onClick={() => {
                            if (isDetailEdited) {
                                setOpenPopup(true)
                            } else {
                                navigate(`/properties`)
                            }
                        }}
                        aria-label="Cancel"
                    >Cancel</Button>
                    <Button
                        className={"mr15"}
                        variant={"text"}
                        color="primary"
                        onClick={() => { setSaveType(0);handleSubmit(0) }}
                        size="medium"
                        sx={{ background: '#E5E5E5', padding: '6px 14px' }}
                        aria-label="Save as Draft"
                    >
                        Save as Draft
                    </Button>
                    <Button variant={"contained"} color="primary" onClick={() => { handleSubmit(1) }} size="medium" aria-label={tabIndex === 0 ? "Next" : "Submit"}>
                        {tabIndex === 0 ? "Next" : "Submit"}
                    </Button>
                </Grid>
            </Grid>
            <Grid className="bodyContainer createBodyContainer">
                <Grid className="bodyInner">
                    <Grid
                        container
                        wrap="nowrap"
                        className={'tabHeaderContainer'}
                    >
                        <TabHeader
                            tabStyle={'inline'}
                            tabIndex={tabIndex}
                            tabList={['Property', 'Units & Rents']}
                            onTabChange={(newValue) => {
                                if (currentProperty && currentProperty.general && (currentProperty.general.id || newValue === 0)) {
                                    setTabIndex(newValue)
                                } else {
                                    dispatch(errorMessage("Please save  general details"));
                                }
                            }}
                        />
                    </Grid>
                    <Grid className={'tabsBodySection'}>
                        <Grid className={"tabsBody createPageTab"}>
                            {
                                tabIndex === 0 &&
                                <TabPanel value={0} index={tabIndex} height="calc(100vh - 215px)" sx={{ overflowY: 'auto' }}>
                                    <Property validate={validate} />
                                </TabPanel>
                            }
                            {
                                tabIndex === 1 &&
                                <TabPanel value={1} index={tabIndex} height="calc(100vh - 215px)" sx={{ overflowY: 'auto', }}>
                                    <UnitsRents unitsValidate={unitsValidate} />
                                </TabPanel>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(isDetailEdited && openPopup) &&
                <Dialog
                    open={openPopup}
                    handleDialogClose={() => { setOpenPopup(false); navigate(-1); }}
                    confirmHandle={() => { handleSubmit(1); setOpenPopup(false) }}
                    successButton={"Yes"}
                    failButton={"No"}
                >
                    <Typography variant='h6'>You have unsaved changes, Do you want to save your changes?</Typography>
                </Dialog>
            }
        </Grid>
    );
}

// default props
CreateProperties.defaultProps = {
    classes: {},
};

// prop types
CreateProperties.propTypes = {
    classes: PropTypes.object,
};

export default styled(CreateProperties)(Styles);