import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import FlowerDonutChart from "../charts-metrics/flowerDonutChart";
//style
import Style from "../style";
import { getUnitTypeDistributionRequest } from "../../../redux/reducer/propertiesDashboardReducer";

const UnitTypeDistribution = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [totalUnitCount, setTotalUnitCount] = useState(0)
  const { properties } = useSelector((s) => s.dashboard);
  useEffect(() => {
    dispatch(getUnitTypeDistributionRequest());
  }, [dispatch]);

  useEffect(() => {
    const colors = {
      studio: "#E3E3E3",
      "1bedroom": "#9A9BFF",
      "2bedroom": "#FC8C99",
      "3bedroom": "#FCC390",
      "4bedroom": "#A2F0C6",
    };



    // const gradientData = [
    //   { id: "studio", colors: ["#FFB6C1", "#D5006D"] },
    //   { id: "1bedroom", colors: ["#ADD8E6", "#1E3A8A"] },
    //   { id: "2bedroom", colors: ["#FFFFE0", "#9C7E00"] },
    //   { id: "3bedroom", colors: ["#98FB98", "#006400"] },
    //   { id: "4bedroom", colors: ["#FFD700", "#B8860B"] },
    // ];

    const gradientData = [
      { id: "studio", colors: ["#e1e1e1", "#E3E3E3"] },
      { id: "1bedroom", colors: ["#9A9BFFC6", "#9A9BFF"] },
      { id: "2bedroom", colors: ["#FC8C99AC", "#FC8C99"] },
      { id: "3bedroom", colors: ["#FCC39091", "#FCC390"] },
      { id: "4bedroom", colors: ["#A2F0C63E", "#A2F0C6"] },
    ];

    var totalCountofUnits =0

    const data = Object.keys(properties?.unit_type_distribution).map((key) => {
      const label =
        key === "studio"
          ? "Studio"
          : `${key.charAt(0).toUpperCase()}${key
              .slice(1)
              .replace("bedroom", " BR")}`;

      const { total, occupied, vacant } =
        properties?.unit_type_distribution[key];
        totalCountofUnits += total
        setTotalUnitCount(totalCountofUnits)
      const color = colors.hasOwnProperty(key) ? colors[key] : undefined;
      const gradient = gradientData.find((g) => g.id === key)?.colors;
      return { label, value: total, occupied, vacant, color,gradient };
    });

    setData(data);
  }, [properties?.unit_type_distribution]);

  return (
    <Box className="container">
    <Typography variant="h4" sx={{ fontWeight: 700 ,fontSize:"16px"}}>
        Unit Type Distribution
      </Typography>
      { 
      totalUnitCount > 0
      ? <FlowerDonutChart data={data} /> 
      : <Typography sx={{display:"grid", placeItems:"center",height:"70%",fontSize:"16px"}} variant="h2"> No Data Found</Typography>
}
      <Grid container sx={{width:"100%", marginInline:"auto", marginTop:"15px"}}>
        {data?.map((item, index) => (
          <Grid item key={index} xs={4}>
            <Box display="flex" alignItems="center" gap={1} marginLeft={3}>
              <Box
                sx={{
                  borderRadius: "50%",
                  height: "10px",
                  width: "10px",
                  backgroundColor: item.color,
                }}
              />
              <Typography>{item.label}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default styled(UnitTypeDistribution)(Style);
