import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,
  applicants: {
    total_applicants: 0,
    total_tenants: 0,
    total_moveins: 0,
    application_status:{},
    affordability_level:{},
    income_rent: {},
    race_ethnicity: {},
    gender_age: {},
    application_distribution: {},
  },
  properties: {},
  filter: {
    start_date: "",
    end_date: "",
    properties: [],
  },
};

const applicantsDashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    // Total Applicants
    getTotalApplicantsRequest(state) {
      state.isLoading = true;
    },
    getTotalApplicantsSuccess(state, action) {
      state.isLoading = false;
      state.applicants = {
        ...state.applicants,
        total_applicants: action.payload.total_applicants,
        };   
    },
    getTotalApplicantsFailed(state, action) {
      state.isLoading = false;
    },

    // Total Tenants and Move-ins
    getTotalTenantsRequest(state) {
      state.isLoading = true;
    },
    getTotalTenantsSuccess(state, action) {
        state.isLoading = false;
        state.applicants = {
        ...state.applicants,
        total_tenants: action.payload.total_tenants,
        total_moveins: action.payload.total_moveins
        };   
    },
    getTotalTenantsFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Applicant Race and Ethnicity
    getApplicantRaceEthnicityRequest(state) {
      state.isLoading = true;
    },
    getApplicantRaceEthnicitySuccess(state, action) {
      state.isLoading = false;
      state.applicants = {
        ...state.applicants,
        race_ethnicity: action.payload.data,
        }; 
    },
    getApplicantRaceEthnicityFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Affordability Level Distribution
    getAffordabilityLevelDistributionRequest(state) {
      state.isLoading = true;
    },
    getAffordabilityLevelDistributionSuccess(state, action) {
        state.isLoading = false;
        state.applicants = {
          ...state.applicants,
          affordability_level: action.payload.data,
          }; 
    },
    getAffordabilityLevelDistributionFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Applicant Age Segmentation
    getApplicantAgeSegmentationRequest(state) {
      state.isLoading = true;
    },
    getApplicantAgeSegmentationSuccess(state, action) {
        state.isLoading = false;
        state.applicants = {
            ...state.applicants,
            gender_age: action.payload.data,
            }; 
    },
    getApplicantAgeSegmentationFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Applications and Status
    getApplicationsStatusRequest(state) {
      state.isLoading = true;
    },
    getApplicationsStatusSuccess(state, action) {
        state.isLoading = false;
        state.applicants = {
          ...state.applicants,
          application_status: action.payload,
          }; 
    },
    getApplicationsStatusFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // % of Income Spend on Rent
    getIncomeRentRequest(state) {
      state.isLoading = true;
    },
    getIncomeRentSuccess(state, action) {
        state.isLoading = false;
        state.applicants = {
          ...state.applicants,
          income_rent: action.payload.data,
          }; 
    },
    getIncomeRentFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Property Distribution By Geography
    getApplicationDistributionRequest(state) {
      state.isLoading = true;
    },
    getApplicationDistributionSuccess(state, action) {
      state.isLoading = false;
        state.applicants = {
          ...state.applicants,
          application_distribution: action.payload.data,
          }; 
    },
    getApplicationDistributionFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateApplicationDashboardFilter(state, action) {
      state.filter = { ...state.filter, ...action.payload };
    },
  }
});

export const {
  getTotalApplicantsRequest,
  getTotalApplicantsSuccess,
  getTotalApplicantsFailed,
  getTotalTenantsRequest,
  getTotalTenantsSuccess,
  getTotalTenantsFailed,
  getApplicantRaceEthnicityRequest,
  getApplicantRaceEthnicitySuccess,
  getApplicantRaceEthnicityFailed,
  getAffordabilityLevelDistributionRequest,
  getAffordabilityLevelDistributionSuccess,
  getAffordabilityLevelDistributionFailed,
  getApplicantAgeSegmentationRequest,
  getApplicantAgeSegmentationSuccess,
  getApplicantAgeSegmentationFailed,
  getApplicationsStatusRequest,
  getApplicationsStatusSuccess,
  getApplicationsStatusFailed,
  getIncomeRentRequest,
  getIncomeRentSuccess,
  getIncomeRentFailed,
  getApplicationDistributionRequest,
  getApplicationDistributionSuccess,
  getApplicationDistributionFailed,
  updateApplicationDashboardFilter,
} = applicantsDashboardSlice.actions;

export default applicantsDashboardSlice.reducer;