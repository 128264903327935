import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const MultiLineChart = ({ moveInData, moveOutData }) => {
  const svgRef = useRef();
  const chartRef = useRef();
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [dimensions, setDimensions] = useState({ width: 1000, height: 325 }); // Initial dimensions

  useEffect(() => {
    // Resize observer to track container size
    const resizeObserver = new ResizeObserver(() => {
      if (chartRef.current) {
        const { width, height } = chartRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    });

    resizeObserver.observe(chartRef.current); // Observe the container

    // Clean up the observer on component unmount
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const { width, height } = dimensions;
    const svg = d3.select(svgRef.current);
    const margin = { top: 20, right: 30, bottom: 30, left: 40 };

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(moveInData, (d) => new Date(d.date)))
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max([...moveInData, ...moveOutData], (d) => d.value) * 1.01])
      .nice(6)
      .range([height - margin.bottom, margin.top]);

    // Add horizontal grid lines
    const gridLines = svg.select(".grid-lines");
    gridLines.selectAll("*").remove(); // Clear existing grid lines
    
    const yGridLines = d3.axisLeft(yScale)
      .tickSize(-width + margin.left + margin.right)
      .tickFormat("")
      .ticks(4);

    gridLines
      .attr("transform", `translate(${margin.left},0)`)
      .call(yGridLines)
      .style("color", "#f2f2f2")
      .selectAll("line")
      .style("stroke", "#e0e0e0")
      .attr("stroke-width", "1px");

    gridLines.selectAll(".domain").remove();

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(d3.timeMonth.every(1))
      .tickFormat(d3.timeFormat("%b"))
      .tickValues(moveInData.map((d) => new Date(d.date)))
      .tickSizeOuter(0);

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(4)
      .tickSize(0)

    svg
      .select(".x-axis")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(xAxis)
      .selectAll(".domain")
      .style("display", "none");

    svg.select(".x-axis").selectAll(".tick line").style("display", "none");

    svg
      .select(".y-axis")
      .attr("transform", `translate(${margin.left -20 },0)`)
      .call(yAxis)
      .selectAll(".domain")
      .style("display", "none");

    svg.select(".y-axis").selectAll(".tick line").style("display", "none");

    svg.select(".x-axis").selectAll("text").style("font-size", "13px");
    svg.select(".y-axis").selectAll("text").style("font-size", "13px");

    const lineGenerator = d3
      .line()
      .curve(d3.curveMonotoneX)
      .x((d) => xScale(new Date(d.date)))
      .y((d) => yScale(d.value));

    const areaGenerator = d3
      .area()
      .curve(d3.curveMonotoneX)
      .x((d) => xScale(new Date(d.date)))
      .y0(yScale(0))
      .y1((d) => yScale(d.value));

    svg
      .select(".area1")
      .datum(moveInData)
      .attr("fill", "url(#gradient1)")
      .attr("d", areaGenerator);

    svg
      .select(".area2")
      .datum(moveOutData)
      .attr("fill", "url(#gradient2)")
      .attr("d", areaGenerator);

    svg
      .select(".line1")
      .datum(moveInData)
      .attr("fill", "none")
      .attr("stroke","#fc8c99" )
      .attr("stroke-width", 2)
      .attr("d", lineGenerator);

    svg
      .select(".line2")
      .datum(moveOutData)
      .attr("fill", "none")
      .attr("stroke","#FCC390")
      .attr("stroke-width", 2)
      .attr("d", lineGenerator);

    const circles1 = svg.selectAll(".circle1").data(moveInData);

    circles1
      .enter()
      .append("circle")
      .merge(circles1)
      .attr("class", "circle1")
      .attr("cx", (d) => xScale(new Date(d.date)))
      .attr("cy", (d) => yScale(d.value))
      .attr("r", 4)
      .attr("fill", "#fc8c99")
      .attr("stroke", "white") // White border
      .attr("stroke-width", 2) // Border width
      .on("mouseover", function (event, d) {
        const content = {
          "Month": d3.timeFormat("%B")(new Date(d.date)),
          "Move In": d.value,
        };
      
        const svgRect = svgRef.current.getBoundingClientRect();
        const x = event.clientX - svgRect.left;
        const y = event.clientY - svgRect.top;
      
        setTooltipContent(content);
        setTooltipPosition({ top: y - 80, left: x });
      
        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", 6);
      })
      .on("mouseout", function () {
        setTooltipContent(null);
        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", 4);
      });

    circles1.exit().remove();

    const circles2 = svg.selectAll(".circle2").data(moveOutData);

    circles2
      .enter()
      .append("circle")
      .merge(circles2)
      .attr("class", "circle2")
      .attr("cx", (d) => xScale(new Date(d.date)))
      .attr("cy", (d) => yScale(d.value))
      .attr("r", 4)
      .attr("fill", "#FCC390")
      .attr("stroke", "white") // White border
      .attr("stroke-width", 2) // Border width
      .on("mouseover", function (event, d) {
        const content = {
          "Month": d3.timeFormat("%B")(new Date(d.date)),
          "Move Out": d.value,
        };
      
        const svgRect = svgRef.current.getBoundingClientRect();
        const x = event.clientX - svgRect.left;
        const y = event.clientY - svgRect.top;
      
        setTooltipContent(content);
        setTooltipPosition({ top: y - 80, left: x }); 
      
        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", 6);
      })
      .on("mouseout", function () {
        setTooltipContent(null); 
        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", 4);
      });

    circles2.exit().remove();
  }, [moveInData, moveOutData, dimensions]);

  const { width, height } = dimensions;
  const viewBoxWidth = width || 1000;  // Default to 1000 if width is 0
  // const viewBoxHeight = height || 325; // Default to 325 if height is 0


  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }} ref={chartRef}>
      <svg
        ref={svgRef}
        className="storage-chart"
        viewBox={`0 0 ${viewBoxWidth} 300`}
        preserveAspectRatio="xMidYMid meet"
        style={{ width: "100%", height: "100%" }}
      >
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#fc8c99" stopOpacity={1} />
            <stop offset="100%" stopColor="#fc8c99" stopOpacity={0.2} />
          </linearGradient>
          <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#FCC390E5" stopOpacity={1} />
            <stop offset="100%" stopColor="#FCC39086" stopOpacity={0.2} />
          </linearGradient>
        </defs>

        <g className="grid-lines" />
        <g className="x-axis" />
        <g className="y-axis" />
        <path className="area1" />
        <path className="area2" />
        <path className="line1" />
        <path className="line2" />
      </svg>

      {tooltipContent && (
  <div
    className="tooltip"
    style={{
      position: "absolute",
      top: tooltipPosition.top,
      left: tooltipPosition.left,
      transform: "translateX(-50%)",
      backgroundColor: "white",
      padding: "12px 16px",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      fontSize: "14px",
      zIndex: 10,
      minWidth: "150px",
      pointerEvents: "none",
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    }}
  >
    {Object.entries(tooltipContent).map(([label, value]) => (
      <div
        key={label}
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        <span style={{ color: "#666" }}>{label}</span>
        <span style={{ fontWeight: "600" }}>{value}</span>
      </div>
    ))}
    <div
      style={{
        position: "absolute",
        bottom: "-8px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "0",
        height: "0",
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderTop: "8px solid white",
        filter: "drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1))",
      }}
    />
  </div>
)}
    </div>
  );
};

export default MultiLineChart;
