import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  properties: {
    properties: 0,
    occupied_units: 0,
    vacant_units: 0,
    property_approval_status: {},
    tenant_trends: {
      move_in: {},
      move_out: {},
    },
    unit_type_distribution: {},
    average_household_income: {},
    property_distribution: [],
    avg_habitation_length: [],
  },
  applications: {},
  filter: {
    start_date: "",
    end_date: "",
    properties:[]
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    // Properites
    getPropertiesRequest(state) {
      state.isLoading = true;
    },
    getPropertiesSuccess(state, action) {
      state.isLoading = false;
      state.properties = {
        ...state.properties,
        properties: action.payload.total_properties,
      };
    },
    getPropertiesFailed(state) {
      state.isLoading = false;
    },
    // UnitsOccupancy
    getUnitsOccupancyRequest(state) {
      state.isLoading = true;
    },
    getUnitsOccupancySuccess(state, action) {
      state.isLoading = false;
      state.properties = {
        ...state.properties,
        occupied_units: action.payload.occupied_units,
        vacant_units: action.payload.vacant_units,
      };
    },
    getUnitsOccupancyFailed(state) {
      state.isLoading = false;
    },
    // PropertyApprovalStatus
    getPropertyApprovalStatusRequest(state) {
      state.isLoading = true;
    },
    getPropertyApprovalStatusSuccess(state, action) {
      state.isLoading = false;
      state.properties = {
        ...state.properties,
        property_approval_status: action.payload,
      };
    },
    getPropertyApprovalStatusFailed(state) {
      state.isLoading = false;
    },
    // TenantTrends
    getTenantTrendsRequest(state) {
      state.isLoading = true;
    },
    getTenantTrendsSuccess(state, action) {
      state.isLoading = false;
      state.properties = {
        ...state.properties,
        tenant_trends: action.payload,
      };
    },
    getTenantTrendsFailed(state) {
      state.isLoading = false;
    },
    // UnitTypeDistribution
    getUnitTypeDistributionRequest(state) {
      state.isLoading = true;
    },
    getUnitTypeDistributionSuccess(state, action) {
      state.isLoading = false;
      state.properties = {
        ...state.properties,
        unit_type_distribution: action.payload,
      };
    },
    getUnitTypeDistributionFailed(state) {
      state.isLoading = false;
    },
    // AverageHouseholdIncome
    getAverageHouseholdIncomeRequest(state) {
      state.isLoading = true;
    },
    getAverageHouseholdIncomeSuccess(state, action) {
      state.isLoading = false;
      state.properties = {
        ...state.properties,
        average_household_income: action.payload,
      };
    },
    getAverageHouseholdIncomeFailed(state) {
      state.isLoading = false;
    },
    // PropertyDistribution
    getPropertyDistributionRequest(state) {
      state.isLoading = true;
    },
    getPropertyDistributionSuccess(state, action) {
      state.isLoading = false;
      state.properties = {
        ...state.properties,
        property_distribution: action.payload,
      };
    },
    getPropertyDistributionFailed(state) {
      state.isLoading = false;
    },
    // AverageHabitationLength
    getAverageHabitationLengthRequest(state) {
      state.isLoading = true;
    },
    getAverageHabitationLengthSuccess(state, action) {
      state.isLoading = false;
      state.properties = {
        ...state.properties,
        avg_habitation_length: action.payload,
      };
    },
    getAverageHabitationLengthFailed(state) {
      state.isLoading = false;
    },

    updatePropertyDashboardFilter(state, action) {
      state.filter = { ...state.filter, ...action.payload };
    },
  },
});

export const {
  getPropertiesRequest,
  getPropertiesSuccess,
  getPropertiesFailed,
  getUnitsOccupancyRequest,
  getUnitsOccupancySuccess,
  getUnitsOccupancyFailed,
  getPropertyApprovalStatusRequest,
  getPropertyApprovalStatusSuccess,
  getPropertyApprovalStatusFailed,
  getTenantTrendsRequest,
  getTenantTrendsSuccess,
  getTenantTrendsFailed,
  getUnitTypeDistributionRequest,
  getUnitTypeDistributionSuccess,
  getUnitTypeDistributionFailed,
  getAverageHouseholdIncomeRequest,
  getAverageHouseholdIncomeSuccess,
  getAverageHouseholdIncomeFailed,
  getPropertyDistributionRequest,
  getPropertyDistributionSuccess,
  getPropertyDistributionFailed,
  getAverageHabitationLengthRequest,
  getAverageHabitationLengthSuccess,
  getAverageHabitationLengthFailed,
  updatePropertyDashboardFilter,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
