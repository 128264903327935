import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

//style
import Styles from './style'

//Components
import { Dialog, TabHeader, TabPanel, LeftPanel, CopyToClipboard, Button, TextField, NoResult, Avatar } from "../../components";
import Comments from "./comments";
import TopPanel from "./topPanel";
import Lottery from "./lottery";
import UnitsRents from "./createProperty/unitsRents";
import Property from "./createProperty/property";
import ChooseUnits from './chooseUnits';
import Logs from "./logs";

//reducer
import {
    getPropertiesLeftPanelListRequest, getPropertiesCountRequest, getPropertiesDetailRequest,
    updatePropertiesFlagRequest, propertiesApprovalRequest, getPropertiesReportRequest, deletePropertiesRequest, getPropertiesUnitsRequest, getPropertiesAmenitiesRequest, getPropertiesPhotosRequest, updateGeneralRequest, updateUnitsRequest, updatePropertiesCommentsRequest,
    updateTempPropertiesFilter, updatePropertyUnitsStatusRequest
} from "../../redux/reducer/propertiesReducer";
import { getMasterAmenitiesRequest, getMasterUnitsRequest } from "../../redux/reducer/globalReducer";
import { errorMessage } from "../../redux/reducer/uiReducer";

//helpers
import { propertiesFieldMap, propertiesStatusOptions, propertiesStatusPlOptions, getPropertiesSlugUrl, propertiesValidation, unitsValidation, jsonParser, gets3URL } from "../../helpers";

function PanelView(props) {
    // navigator
    const navigate = useNavigate();
    const { search } = useLocation()
    let [searchParams] = useSearchParams();

    // dispatch
    const dispatch = useDispatch()

    // props
    const { className } = props;

    // redux
    const { loggedUser, loggedUser: { user_group_id } } = useSelector(s => (s.profile));
    const {
        unitPhotos, currentProperty, propertySubmit, panelView: { data, pagination, pagination: { count, sortField, sortBy } },
        viewType, currentProperty: { general: propertiesDetail }, filter, isLazyLoading, isDetailEdited, isUnitsEdited,
        hasGeneralChanges, hasAmenityChanges, hasPhotosChange, hasUnitChanges, hasUnitPhotosChanges, changedFields, unitChangedFields
    } = useSelector(s => (s.properties))

    // state
    const [tabIndex, setTabIndex] = useState(0);
    const [openPopup, setOpenPopup] = useState(false);
    const [validate, setValidate] = useState(false);
    const [unitsValidate, setUnitsValidate] = useState(false);
    const [rejectDialog, setRejectDialog] = useState(false);
    const [comment, setComment] = useState("")
    const [editAlert, setEditAlert] = useState(false);

    const [isChooseUnitsOpen, setIsChooseUnitsOpen] = useState(false);

    const handleOpenChooseUnits = () => {
        let changesArr = [...jsonParser(changedFields)];
        let tmpProperty = {
            ...currentProperty,
            general: {
                ...currentProperty.general,
                approval_status: 1,
                city_id: currentProperty.general.city?.id || null,
                state_id: currentProperty.general.state?.id || null,
    
                detailsFilled: (!unitsValidation(currentProperty.units, unitPhotos, 1)),
                changedFields: changesArr.toString()
            }
        }
        delete tmpProperty.general.city;
        delete tmpProperty.general.state;
        const validation = propertiesValidation(tmpProperty.general, tmpProperty.photos, 1);
        if (!validation) {
            setIsChooseUnitsOpen(true);
        } else {
            dispatch(errorMessage(validation));
        }
    }

    const handleCloseChooseUnits = () => {
        setIsChooseUnitsOpen(false);
    };

    const handleChooseUnitsSubmit = (data) => {
        console.log('Selected Units and Reason:', data);
        setIsChooseUnitsOpen(false);

 
        dispatch(updatePropertiesCommentsRequest({
            message: `Lottery rerun reason: ${data.reason}`,
            user: loggedUser.id,
            property: propertiesDetail.id,
            id: null
        }));

        let unitIds = data.updatedUnits.filter(unit => unit.status).map(unit => unit.unit_detail_id);
        handleSubmit(1,unitIds);
    };

    // variables
    const isPropertyManager = user_group_id === 2
    const isPropertyOwner = user_group_id === 4
    
    // get left panel data
    useEffect(() => {
        let searchQuery = {}
        let redirect = false
        if (search && search.includes("?id=")) {
            searchQuery = {
                property_id: searchParams.get("id")
            }
            redirect = true
        }
        if (search && search.includes("comment")) {
            setTabIndex(3)
            redirect = true
        }

        //clear search query if any
        if (redirect) {
            navigate("/properties")
        }
        // get total count for pagination and then call the data in the epic
        dispatch(getPropertiesCountRequest({ ...pagination, skip: 0, viewType: viewType, isFirst: true, filter: { ...filter, ...searchQuery } }))
        dispatch(getPropertiesReportRequest())

        // get global units and amenities
        dispatch(getMasterUnitsRequest())
        dispatch(getMasterAmenitiesRequest())
    }, [dispatch])

    useEffect(() => {
        if (propertySubmit) {
            setTabIndex(1)
        }
    }, [propertySubmit])

    // approve or reject manager
    const handleApproval = (approval) => {
        dispatch(propertiesApprovalRequest({
            approval_status: approval,
            property_id: propertiesDetail.id
        }))
    }

    //show/hide prev button
    const hasPrev = () => {
        return propertiesDetail && propertiesDetail.id && data && data[0] && data[0].id !== propertiesDetail.id ? true : false
    }

    //show or hide next button
    const hasNext = () => {
        const curIndex = data.findIndex(f => (f.id === propertiesDetail.id))
        return curIndex < data.length - 1
    }

    const handleFilter = (setOpenFilter= ()=>{}) => {
        if (filter.minSqfeetFilter && filter.maxSqfeetFilter && filter.minSqfeetFilter >= filter.maxSqfeetFilter) {
            dispatch(errorMessage("Maximum square feet filter should be greater than minimum square feet filter"))
        } else {
            //update temporary filters on applying filters. This can be used to reset them on opening the filter drawer again
            dispatch(updateTempPropertiesFilter())
            dispatch(getPropertiesCountRequest({ ...pagination, skip: 0, isFirst: true, filter: filter }))
            setOpenFilter(false)
        }
    }

    const handleSorting = ({ sortField, sortBy }) => {
        dispatch(getPropertiesCountRequest({ ...pagination, sortField, sortBy, skip: 0, isFirst: true, filter: filter }))
    }

    const handleRejection = () => {
        if (comment) {
            dispatch(propertiesApprovalRequest({
                approval_status: "3",
                property_id: propertiesDetail.id
            }))
            dispatch(updatePropertiesCommentsRequest({
                message: comment,
                user: loggedUser.id,
                property: propertiesDetail.id,
                id: null
            }))
            setComment("");
            setRejectDialog(false)
        } else {
            dispatch(errorMessage("Please enter comments"))
        }
    }

    // handle save
    const handleSubmit = (approvalStatus, unitIds = []) => {
        if (tabIndex === 0) {
            if (approvalStatus !== 0) {
                setValidate(true);
            }
            let changesArr = [...jsonParser(changedFields)]
            if(hasAmenityChanges){
                changesArr.push("Amenities")
            }
            if(hasPhotosChange){
                changesArr.push("Photos")
            }

            let tmpProperty = {
                ...currentProperty,
                general: {
                    ...currentProperty.general,
                    approval_status: approvalStatus,
                    city_id: currentProperty.general.city && currentProperty.general.city.id ? currentProperty.general.city.id : null,
                    state_id: currentProperty.general.state && currentProperty.general.state.id ? currentProperty.general.state.id : null,

                    // check unit details are filled or not for sending pm approval
                    detailsFilled: (!unitsValidation(currentProperty.units, unitPhotos, approvalStatus)),
                    changedFields: changesArr.toString()
                }
            }

            delete tmpProperty.general.city;
            delete tmpProperty.general.state;
            if (changedFields.includes("Lottery Date")) {
                tmpProperty.general.lottery_units = unitIds
            }

            const validation = propertiesValidation(tmpProperty.general, tmpProperty.photos, approvalStatus)
            if (!validation) {
                dispatch(updateGeneralRequest(tmpProperty))
            } else {
                dispatch(errorMessage(validation));
            }
        } else if (tabIndex === 1) {
            if (approvalStatus !== 0) {
                setUnitsValidate(true);
            }
            const validation = unitsValidation(currentProperty.units, unitPhotos, approvalStatus)
            if (!validation) {
                // check property details are filled or not for sending pm approval
                const propertyValid = propertiesValidation({
                    ...currentProperty.general,
                    approval_status: currentProperty.units && currentProperty.units.length ? approvalStatus : 0,
                    city_id: currentProperty.general.city && currentProperty.general.city.id ? currentProperty.general.city.id : null,
                    state_id: currentProperty.general.state && currentProperty.general.state.id ? currentProperty.general.state.id : null
                }, currentProperty.photos, approvalStatus)
                
                // for tracking changes in the unit level
                let changedFields = unitChangedFields.filter((value, index, array) => {return array.indexOf(value) === index;})
                if(hasUnitPhotosChanges){
                    changedFields.push("Unit Photos")
                }

                dispatch(updateUnitsRequest({
                    ...currentProperty,
                    approvalStatus: approvalStatus,
                    detailsFilled: !propertyValid,
                    changedFields: changedFields.toString()
                }))
            } else {
                dispatch(errorMessage(validation));
            }
        }
    }

    const getNextproperty = (data) => {
        dispatch(getPropertiesDetailRequest(data))
        dispatch(getPropertiesUnitsRequest(data))
        dispatch(getPropertiesPhotosRequest(data))
        dispatch(getPropertiesAmenitiesRequest(data))
        setEditAlert(false);
        setTabIndex(0)
    }

    // remove reject from the status dropdown if the property is rejected
    const getStatusOptions = (data) => {
        if(data.approval_status != 3){
            return propertiesStatusOptions
        }
        else{
            let filteredStatuses = {...jsonParser(propertiesStatusOptions)}
            delete filteredStatuses['3']
            return filteredStatuses
        }
    }

    return (
        <>
            <Grid container className={className}>
                <Grid item xs={12}>
                    <TopPanel
                        IconsList={["VIEW_TYPE", isPropertyManager ? "ADD" : null, "CHILDREN", "FILTER_LIST", "DOWNLOAD", hasPrev() ? "PREV" : null, hasNext() ? "NEXT" : null]}
                        sortValue={`${sortField}-${sortBy}`}
                        handleSorting={handleSorting}
                        handleFilter={handleFilter}
                        setEditAlert={setEditAlert}
                    />
                </Grid>
                <Grid item xs={12} className="bodyContainer">
                    {(count > 0 && propertiesDetail && propertiesDetail.id) ?

                        <Grid container spacing={2}>
                            <Grid item xs={4} lg={3} className={"sideBarList"}>
                                <LeftPanel
                                    viewId={propertiesDetail.id || null}
                                    fieldMap={propertiesFieldMap}
                                    handleStatusChange={(name, value, data) => {
                                        if (Number(value) === 3) {
                                            setRejectDialog(true)
                                        } else {
                                            dispatch(propertiesApprovalRequest({
                                                approval_status: value,
                                                property_id: data.id
                                            }))
                                        }
                                    }}
                                    data={data || []}
                                    totalCount={count}
                                    disableStatusChange={(data) => {
                                        return (isPropertyManager ? true : isPropertyOwner ? true : data.approval_status !== 2 ? false : true)
                                    }}
                                    apicallback={() => {
                                        dispatch(getPropertiesLeftPanelListRequest({ ...pagination, filter: filter }))
                                    }}
                                    statusOptions={getStatusOptions}
                                    statusViewOption={propertiesStatusPlOptions}
                                    handleView={(data) => {
                                        if (((isDetailEdited && (hasGeneralChanges || hasAmenityChanges || hasPhotosChange)) || (isUnitsEdited && (hasUnitChanges || hasUnitPhotosChanges)))) {
                                            if (isPropertyManager){
                                                setEditAlert(data)
                                            }
                                            else{
                                                getNextproperty(data)
                                            }
                                            
                                        } else {
                                            getNextproperty(data)
                                        }
                                    }}
                                    handleFlag={(data) => {
                                        dispatch(updatePropertiesFlagRequest({ ...data, is_flag: data.is_flag ? 0 : 1 }))
                                    }}
                                    isLoading={isLazyLoading}
                                />
                            </Grid>
                            <Grid item xs={8} lg={9} className={"detailView"}>
                                <Grid className="managerDetail">
                                    <Grid className="header">
                                        <Grid container justifyContent={"space-between"}>
                                            <Grid className="managerTitle">
                                                <Grid item className="propertyLogo">
                                                    <Avatar src={gets3URL(propertiesDetail.photo)} alt={propertiesDetail.name} />
                                                </Grid>
                                                <Grid className="pl-1">
                                                    <Grid className="dflex alignCenter">
                                                        <Typography variant="h2" className="fw-500 pr-2">{propertiesDetail.name}</Typography>
                                                        {propertiesDetail.approval_status === 2 && <Tooltip arrow title={"Copy Property Link"}><Grid><CopyToClipboard text={getPropertiesSlugUrl(propertiesDetail.slug)} /></Grid></Tooltip>}
                                                    </Grid>
                                                    {!isPropertyManager && <Typography variant="subtitle1">{`By ${propertiesDetail.manager_name}`}</Typography>}
                                                </Grid>
                                            </Grid>
                                            {!isPropertyManager && propertiesDetail.approval_status !== 2 && !isPropertyOwner &&
                                                <Grid className="detailAction">
                                                    {propertiesDetail.approval_status !== 3 && <Button size={"small"} variant={"contained reject mr-1"}
                                                        onClick={() => { setRejectDialog(true) }}
                                                        aria-label="Reject"
                                                    >Reject</Button>}
                                                    <Button size={"small"} variant={"contained approve"} onClick={() => { handleApproval("2") }} aria-label="Approve">Approve</Button>
                                                </Grid>
                                            }
                                            {isPropertyManager && <Grid item className="dflex alignCenter">
                                                {(propertiesDetail.approval_status === 2) && <Lottery propertiesDetail={propertiesDetail} lotteryCount={propertiesDetail.lottery_count} />}
                                                {propertiesDetail.approval_status === 0 && <Button
                                                    className={"mr15"}
                                                    variant={"text"}
                                                    color="primary"
                                                    onClick={() => { handleSubmit(0) }}
                                                    size="medium"
                                                    sx={{ background: '#E5E5E5', padding: '6px 14px' }}
                                                    aria-label="Save as Draft"
                                                >
                                                    Save as Draft
                                                </Button>}
                                                {(propertiesDetail.approval_status !== 1) &&
                                                    <Button
                                                    variant={"contained"}
                                                    color="primary"
                                                    disabled={((isDetailEdited && (hasGeneralChanges || hasAmenityChanges || hasPhotosChange)) || (isUnitsEdited && (hasUnitChanges || hasUnitPhotosChanges)) ? false : true)}
                                                    onClick={() => {
                                                            if (propertiesDetail.lottery_count > 0 && isDetailEdited && changedFields.includes("Lottery Date")) {
                                                                handleOpenChooseUnits();
                                                            } else {
                                                                handleSubmit(1);
                                                            }
                                                        }
                                                    }
                                                    size="medium"
                                                    aria-label={propertiesDetail.approval_status === 0 ? "Submit" : "Update"}
                                                    >
                                                    {propertiesDetail.approval_status === 0 ? "Submit" : "Update"}
                                                    </Button>
                                                }

                                                {/* Render the ChooseUnits dialog */}
                                                <ChooseUnits 
                                                    open={isChooseUnitsOpen} 
                                                    onClose={handleCloseChooseUnits} 
                                                    onSubmit={handleChooseUnitsSubmit}
                                                    //unitsData={currentProperty.units} 
                                                />
                                                {(propertiesDetail.approval_status !== 2) && (propertiesDetail.is_deletable) && <Tooltip arrow title={"Delete"}><IconButton onClick={() => setOpenPopup(true)} className="ml-1"><DeleteOutlineIcon sx={{ fill: '#2E2E84' }} /></IconButton></Tooltip>}
                                            </Grid>}
                                        </Grid>
                                        <Grid className="dflex alignCenter pt-2">
                                            <Typography variant="body1" className="pr-3 ">{propertiesDetail.application_count} <span style={{ color: '#B1B1B1', paddingLeft: 5 }}>{propertiesDetail.application_count > 1 || propertiesDetail.application_count === 0? "Applications": "Application"}</span></Typography>
                                            <Typography variant="body1" className="pr-3 fw-500">{propertiesDetail.unit_count} <span style={{ color: '#B1B1B1', paddingLeft: 5 }}>{propertiesDetail.unit_count > 1 || propertiesDetail.unit_count === 0? "Units" : "Unit"}</span></Typography>
                                            <Typography variant="body1" className="pr-3 fw-500">{propertiesDetail.vacant_units} <span style={{ color: '#B1B1B1', paddingLeft: 5 }}>{propertiesDetail.vacant_units > 1 || propertiesDetail.vacant_units === 0 ? "Vacant Units" : "Vacant Unit"}</span></Typography>
                                            <Typography variant="body1" className="pr-2 fw-500">{Math.trunc(propertiesDetail.vacancy_percentage)}% <span style={{ color: '#B1B1B1', paddingLeft: 5 }}> Vacancy</span></Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        wrap="nowrap"
                                        className={'tabHeaderContainer'}
                                    >
                                        <TabHeader
                                            tabStyle={'inline'}
                                            tabIndex={tabIndex}
                                            tabList={
                                                isPropertyOwner
                                                    ? ['Details', 'Units & Rents', 'Lottery Details']
                                                    : ['Details', 'Units & Rents', 'Lottery Details', 'Comments']
                                            }
                                            onTabChange={(newValue) => setTabIndex(newValue)}
                                        />
                                    </Grid>
                                    <Grid className={'tabsBodySection'}>
                                        <Grid className={"tabsBody"} sx={{ '& .commentsList': { height: 'calc(100vh - 425px) !important' } }}>
                                            {
                                                tabIndex === 0 &&
                                                <TabPanel value={0} index={tabIndex} height="calc(100vh - 336px)" sx={{ overflowY: 'auto' }}>
                                                    <Property isDisabled={!isPropertyManager || (propertiesDetail.approval_status === 1) || propertiesDetail.lottery_count < 0 } validate={validate} />
                                                </TabPanel>
                                            }
                                            {
                                                tabIndex === 1 &&
                                                <TabPanel value={1} index={tabIndex} height="calc(100vh - 336px)" sx={{ overflowY: 'auto' }}>
                                                    <UnitsRents isDisabled={!isPropertyManager || (propertiesDetail.approval_status === 1) || propertiesDetail.lottery_count < 0} unitsValidate={unitsValidate} />
                                                </TabPanel>
                                            }
                                            {
                                                tabIndex === 2 &&
                                                <TabPanel value={2} index={tabIndex} height="calc(100vh - 336px)" sx={{ overflowY: 'auto' }}>
                                                    <Logs isDisabled={!isPropertyManager || (propertiesDetail.approval_status === 1) || propertiesDetail.lottery_count > 0} unitsValidate={unitsValidate} />
                                                </TabPanel>
                                            }
                                            {
                                                tabIndex === 3 && !isPropertyOwner &&
                                                <TabPanel boxClass="commentBox commentsSection" value={3} index={tabIndex} height="calc(100vh - 336px)">
                                                    <Comments />
                                                </TabPanel>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Dialog
                                        open={rejectDialog}
                                        handleDialogClose={() => { setRejectDialog(false); setComment("") }}
                                        confirmHandle={() => {
                                            handleRejection()
                                        }}
                                    >
                                        <Typography variant='h6' className="pb-1">Please provide the rejection reason</Typography>
                                        <TextField
                                            placeholder={"Rejection reason"}
                                            aria-label="Enter the reject reason"
                                            multiline
                                            value={comment}
                                            handleChange={e => { setComment(e.value) }}
                                        />
                                    </Dialog>

                                </Grid>


                            </Grid>
                        </Grid>
                        :
                        <>{!isLazyLoading && <NoResult text="No Property Found" />}</>
                    }
                </Grid>
            </Grid>
            {openPopup &&
                <Dialog
                    open={openPopup}
                    handleDialogClose={() => { setOpenPopup(false); }}
                    confirmHandle={() => {
                        dispatch(deletePropertiesRequest({ id: propertiesDetail.id, data: data }));
                        setOpenPopup(false)

                        const curIndex = data.findIndex(f => (f.id === propertiesDetail.id))
                        if (data[curIndex + 1]) {
                            getNextproperty(data[curIndex + 1])
                        } else if (data[curIndex - 1]) {
                            getNextproperty(data[curIndex - 1])
                        }
                    }}
                    successButton={"Yes"}
                    failButton={"No"}
                >
                    <Typography variant='h6'>Are you sure you want to delete the property?</Typography>
                </Dialog>
            }
            {editAlert && isPropertyManager &&
                <Dialog
                    open={editAlert}
                    handleDialogClose={() => { setEditAlert(false); }}
                    confirmHandle={() => { getNextproperty(editAlert) }}
                    successButton={"Yes"}
                    failButton={"Cancel"}
                >
                    <Typography variant='h6'>All your changes will be lost, Are you sure you want to proceed?</Typography>
                </Dialog>
            }
        </>
    );
}

// default props
PanelView.defaultProps = {
    classes: {},
};

// prop types
PanelView.propTypes = {
    classes: PropTypes.object,
};

export default styled(PanelView)(Styles);