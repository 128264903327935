import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const FlowerDonutChart = ({ data }) => {
  const svgRef = useRef();
  const chartRef = useRef();
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const maxAllowedRadius = 100;


  useEffect(() => {
    const width = 300;
    const height = 300;
    const innerRadius = 35;
    const maxOuterRadius = Math.min(width, height) / 1;
  
    const sortedData = [...data].sort((a, b) => b.value - a.value);
  
    const totalValue = d3.sum(sortedData, (d) => d.value);
    const angle = (2 * Math.PI) / sortedData.length;
    const arc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius((d) =>
        Math.min(
          innerRadius + (d.value / totalValue) * (maxOuterRadius - innerRadius),
          maxAllowedRadius
        )
      );
  
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .select("g");
  
    svg.selectAll("*").remove();
    if (svg.empty()) {
      d3.select(svgRef.current)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);
    }
  
    // Define gradients in <defs>
    const defs = svg.append("defs");
    sortedData.forEach((d, i) => {
      const gradientId = `gradient-${i}`;
      const gradient = defs
        .append("linearGradient")
        .attr("id", gradientId)
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0%");
  
      gradient
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", d.gradient[0]); // Start color
  
      gradient
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", d.gradient[1]); // End color
  
      d.gradientId = gradientId; // Attach gradientId to the data for later use
    });
  
    const arcs = svg
      .selectAll(".arc")
      .data(sortedData)
      .join("g")
      .attr("class", "arc");
  
    // Remove existing paths and texts to prevent duplicates
    arcs.select("path").remove();
    arcs.select("text").remove();
  
    // Append the paths
    arcs
      .append("path")
      .attr("d", (d, i) => {
        const startAngle = i * angle;
        const endAngle = startAngle + angle;
        return arc({ startAngle, endAngle, value: d.value });
      })
      .attr("fill", (d) => `url(#${d.gradientId})`) // Use gradient for fill
      .on("mouseover", function (event, d) {
        const content = {
          "Total Units": d.value,
          Vacant: d.vacant,
          Occupied: d.occupied,
        };
  
        const index = data.indexOf(d);
        const startAngle = index * angle;
        const endAngle = startAngle + angle;
        const centroid = arc.centroid({
          startAngle,
          endAngle,
          value: d.value,
        });
  
        const chartRect = chartRef.current.getBoundingClientRect();
  
        const svgRect = svgRef.current.getBoundingClientRect();
        const x = svgRect.left + centroid[0] + width / 2;
        const y = svgRect.top + centroid[1] + height / 2;
  
        setTooltipContent(content);
        setTooltipPosition({
          left: x - chartRect.left,
          top: y - chartRect.top,
        });
  
        d3.select(this).transition().duration(200).attr("transform", "scale(1.1)");
  
        d3.select(this.parentNode)
          .select("text")
          .transition()
          .duration(200)
          .style("font-size", "15px");
      })
      .on("mousemove", function (event) {
        const svgRect = svgRef.current.getBoundingClientRect();
        const x = event.clientX - svgRect.left + 10; // Update x on mouse move
        const y = event.clientY - svgRect.top + 10; // Update y on mouse move
  
        // Update tooltip position dynamically
        setTooltipPosition({ top: y, left: x });
      })
      .on("mouseout", function () {
        setTooltipContent(null);
  
        d3.select(this)
          .transition()
          .duration(200)
          .attr("transform", "scale(1)");
  
        d3.select(this.parentNode)
          .select("text")
          .transition()
          .duration(200)
          .style("font-size", "12px");
      });
  
    // Append text to each arc
    arcs
      .append("text")
      .attr("transform", (d, i) => {
        const startAngle = i * angle;
        const endAngle = startAngle + angle;
        const centroid = arc.centroid({
          startAngle,
          endAngle,
          value: d.value,
        });
        return `translate(${centroid[0]}, ${centroid[1]})`;
      })
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("font-size", "10px")
      .style("fill", "#000") // Ensure text is visible against the arc color
      .text((d) => d.value > 0 ? d.value : null);


      svg
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 35)
      .attr("fill", "#FFFFFF")
      .style("filter", "drop-shadow(0px 0px 10px #00000029)");
  
    // Append total value text in the center
    svg
      .append("text")
      .attr("class", "total")
      .attr("text-anchor", "middle")
      .attr("font-size", "11px")
      .attr("dy", "-0.35em") // Shift label slightly upwards
      .text("Total Units");
  
    svg
      .append("text")
      .attr("class", "total-value")
      .attr("text-anchor", "middle")
      .attr("font-size", "13px")
      .attr("dy", "0.85em") // Shift value slightly downwards
      .text(totalValue);
  }, [data]);
  
  
  

  return (
    <div ref={chartRef} style={{ position: "relative", textAlign: "center" }}>
      <svg ref={svgRef} viewBox="-80 -135 200 250">
        <g />
      </svg>
      {tooltipContent && (
        <div
          className="tooltip"
          style={{
            position: "absolute",
            top: tooltipPosition.top - 120,
            left: tooltipPosition.left,
            transform: "translateX(-50%)",
            backgroundColor: "white",
            padding: "12px 16px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize: "14px",
            zIndex: 10,
            minWidth: "150px",
            pointerEvents: "none",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          {Object.entries(tooltipContent).map(([label, value]) => (
            <div
              key={label}
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <span style={{ color: "#666" }}>{label}</span>
              <span style={{ fontWeight: "600" }}>{value}</span>
            </div>
          ))}
          <div
            style={{
              position: "absolute",
              bottom: "-8px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "0",
              height: "0",
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderTop: "8px solid white",
              filter: "drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1))",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FlowerDonutChart;
