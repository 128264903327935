import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const FunnelChart = ({ data, width = 600, height = 400 }) => {
  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const margin = { top: 5, right: 20, bottom: 120, left: 20 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    if (!data || data.length === 0) {
      svg.append("text")
        .attr("x", chartWidth / 2)
        .attr("y", chartHeight / 2)
        .attr("text-anchor", "middle")
        .text("No data available")
        .style("font-size", "16px")
        .style("fill", "red");
      return;
    }

    const sortedData = data.sort((a, b) => b.value - a.value);

    const minBarWidth = 65; 
    const maxValue = d3.max(sortedData, d => d.value);
    const minValue = d3.min(sortedData, d => d.value);

    const x = d3.scaleLinear()
      .domain([minValue, maxValue])
      .range([minBarWidth, chartWidth]);

    const y = d3.scaleBand()
      .domain(sortedData.map((_, i) => i))
      .range([0, chartHeight])
      .padding(0.12);

    const g = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    //  background rectangle to the <g> group
    g.append("rect")
    .attr("x", -20)
    .attr("y", -30)
    .attr("width", chartWidth +40)
    .attr("height", chartHeight+40)
    .attr("fill", "#F8F8F8"); 

    g.selectAll(".bar")
      .data(sortedData)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("y", (_, i) => y(i))
      .attr("height", y.bandwidth())
      .attr("x", d => (chartWidth - x(d.value)) / 2)
      .attr("width", d => x(d.value))
      .attr("fill", d => d.color);

    g.selectAll(".bar-label")
      .data(sortedData)
      .enter().append("text")
      .attr("class", "bar-label")
      .attr("y", (_, i) => y(i) + y.bandwidth() / 2)
      .attr("x", chartWidth / 2)
      .attr("dy", ".35em")
      .attr("text-anchor", "middle")
      .attr("fill", "white")
      .text(d => d.value)
      .style("font-size", "13px")
      .style("font-weight", "bold");

  }, [data, width, height]);

  return <svg ref={svgRef} width={width} height={height}></svg>;
};

export default FunnelChart;