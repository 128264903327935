import React, { useMemo, useState } from "react";
import * as d3 from "d3";

const DonutChart = ({ width, height, data, colors, label }) => {
  const radius = Math.min(width, height) / 2;

  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const pie = useMemo(() => {
    const pieGenerator = d3.pie().value((d) => d.value);
    return pieGenerator(data);
  }, [data]);

  const arcPathGenerator = d3.arc().innerRadius(80).outerRadius(radius);

  const handleMouseOver = (event, d) => {
    setTooltipContent({
      Name: d.data.name,
      Value: d.data.value,
    });

    const [centroidX, centroidY] = arcPathGenerator.centroid(d);
    const svgRect = event.target.closest("svg").getBoundingClientRect();
    const x = svgRect.left + centroidX + width / 2;
    const y = svgRect.top + centroidY + height / 2;

    setTooltipPosition({ top: y, left: x });
  };

  const handleMouseMove = (event) => {
    const svgRect = event.target.closest("svg").getBoundingClientRect();
    const x = event.clientX - svgRect.left + 0;
    const y = event.clientY - svgRect.top + -10;

    setTooltipPosition({ top: y, left: x });
  };

  const handleMouseOut = () => {
    setTooltipContent(null);
  };

  return (
    <div style={{ position: "relative" }}>
      <svg width={width} height={height} style={{ display: "inline-block" }}>
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          {pie.map((d, i) => (
            <path
              key={i}
              d={arcPathGenerator(d)}
              fill={colors[i]}
              onMouseOver={(event) => handleMouseOver(event, d)}
              onMouseMove={handleMouseMove}
              onMouseOut={handleMouseOut}
            />
          ))}
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: "14px", fill: "black", fontWeight: 700 }}
          >
            {label}
          </text>
        </g>
      </svg>

      {tooltipContent && (
        <div
          className="tooltip"
          style={{
            position: "absolute",
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            transform: "translate(-50%, -100%)", // Adjust to align tooltip above the arc
            backgroundColor: "white",
            padding: "12px 16px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize: "14px",
            zIndex: 10,
            minWidth: "150px",
            pointerEvents: "none",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
              alignItems: "center", // Align items in the same line
            }}
          >
            <span style={{ color: "#666" }}>{tooltipContent.Name}</span>
            <span style={{ color: "#000", fontWeight: "600" }}>{tooltipContent.Value}</span>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "-8px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "0",
              height: "0",
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderTop: "8px solid white",
              filter: "drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1))",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DonutChart;