import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

// Import necessary assets
import Logo1 from "../../assets/images/Group175672.png";
import JCCityLogo from "../../assets/images/JCCityLogo.png";
import LeftArrow from "../../assets/images/LeftArrow.png";
import Img2 from "../../assets/images/Group175684.png";
import Img1 from "../../assets/images/Group175676.png";
import DashboardPage2 from "../../assets/images/DashboardPage2.png";
import NJ_logo_png from "../../assets/images/NJ_logo_png.png";

export const useDashboardDownload = (contentRef, tabIndex, dataFilters) => {

  const generateFirstPage = async (pdf, pdfWidth, pdfHeight, footerHeight) => {
    const scale = 4;
    const canvas1 = document.createElement("canvas");
    canvas1.width = pdfWidth * scale;
    canvas1.height = (pdfHeight - footerHeight) * scale;
    const ctx1 = canvas1.getContext("2d");

    ctx1.scale(scale, scale);

    ctx1.fillStyle = "#FFFFFF";
    ctx1.fillRect(0, 0, canvas1.width, canvas1.height);

    ctx1.imageSmoothingEnabled = true;
    ctx1.imageSmoothingQuality = "high";

    try {
      // Main logo
      await new Promise((resolve) => {
        const logoImg = new Image();
        logoImg.src = Logo1;
        logoImg.onload = () => {
          ctx1.drawImage(logoImg, 0, 31, 350, 540);
          resolve();
        };
      });

      await new Promise((resolve) => {
        const cityLogoImg = new Image();
        cityLogoImg.src = JCCityLogo;
        cityLogoImg.onload = () => {
          ctx1.drawImage(cityLogoImg, 400, 43, 146, 146);
          resolve();
        };
      });

      await new Promise((resolve) => {
        const arrowImg = new Image();
        arrowImg.src = LeftArrow;
        arrowImg.onload = () => {
          ctx1.drawImage(arrowImg, 570, 0, 31, 63);
          resolve();
        };
      });

      await new Promise((resolve) => {
        const img2 = new Image();
        img2.src = Img2;
        img2.onload = () => {
          ctx1.drawImage(img2, 440, 430, 200, 170);
          resolve();
        };
      });

      await new Promise((resolve) => {
        const img1 = new Image();
        img1.src = Img1;
        img1.onload = () => {
          ctx1.drawImage(img1, 450, 630, 100, 80);
          resolve();
        };
      });

      const textCanvas = document.createElement("canvas");
      textCanvas.width = pdfWidth * scale;
      textCanvas.height = (pdfHeight - footerHeight) * scale;
      const textCtx = textCanvas.getContext("2d");
      textCtx.scale(scale, scale);

      textCtx.textRendering = "optimizeLegibility";
      textCtx.imageSmoothingEnabled = true;
      textCtx.imageSmoothingQuality = "high";

      const drawTextWithLetterSpacingRightAligned = (
        text,
        y,
        fontSize,
        letterSpacing = 0.72
      ) => {
        textCtx.font = `bold ${fontSize}px Public Sans, sans-serif`;

        let totalWidth = 0;
        const letters = text.split("");
        letters.forEach((letter) => {
          totalWidth += textCtx.measureText(letter).width + letterSpacing;
        });
        totalWidth -= letterSpacing;

        const startX = pdfWidth - totalWidth - 50;

        let currentX = startX;
        letters.forEach((letter) => {
          textCtx.fillText(letter, currentX, y);
          currentX += textCtx.measureText(letter).width + letterSpacing;
        });
      };

      textCtx.fillStyle = "#232323";
      const lines = ["NEW JERSEY", "AFFORDABLE", "HOUSING", "DEVELOPMENT"];
      const Nj_textY = 300;
      const lineSpacing = 30;

      lines.forEach((line, index) => {
        drawTextWithLetterSpacingRightAligned(
          line,
          Nj_textY + index * lineSpacing,
          20
        );
      });

      textCtx.fillStyle = "#343487";
      textCtx.font = "bold 24px Public Sans, sans-serif";

      const text1Line1 = "Data Dashboard";
      const text1Line2 =
        localStorage.getItem("user_group_id") == 2
          ? "New Jersey Property Manager Portal"
          : localStorage.getItem("user_group_id") == 4
          ? "New Jersey Property Owner Portal"
          : "New Jersey City Staff Portal";

      textCtx.fillText(text1Line1, 30, 650);
      textCtx.fillText(text1Line2, 30, 650 + 30);

      textCtx.fillStyle = "#9B9BC4";
      textCtx.font = "500 15px Public Sans, sans-serif";
      const today_date = moment().format("YYYY-MM-DD");
      textCtx.fillText(`Report generated on – ${today_date}`, 30, 720);

      ctx1.drawImage(textCanvas, 0, 0, pdfWidth, pdfHeight - footerHeight);

      const pageImgData = canvas1.toDataURL("image/png", 1.0);
      pdf.addImage(
        pageImgData,
        "PNG",
        0,
        0,
        pdfWidth,
        pdfHeight - footerHeight,
        undefined,
        "FAST"
      );
    } catch (error) {
      console.error("Error generating first page:", error);
    }
  };

  const addFooter = async (pdf, pageNumber, totalpages) => {
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const footerHeight = 60;
    const logoWidth = 90;
    const logoHeight = 40;
    const footerY = pdfHeight - footerHeight + 10;
    const textX = logoWidth + 15;
    const pageNumberX = pdfWidth - 80;

    pdf.setFillColor(240, 240, 240);
    pdf.rect(0, pdfHeight - footerHeight, pdfWidth, footerHeight, "F");

    try {
      await new Promise((resolve, reject) => {
        const logoImg = new Image();
        logoImg.crossOrigin = "Anonymous";
        logoImg.onload = () => {
          pdf.addImage(
            logoImg,
            "PNG",
            10,
            footerY,
            logoWidth,
            logoHeight,
            undefined,
            "FAST"
          );
          resolve();
        };
        logoImg.onerror = reject;
        logoImg.src = NJ_logo_png;
      });

      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      pdf.text(`Affordable Housing Portal Data Dashboard`, textX, footerY + 25);
      pdf.text(
        `Page ${pageNumber} of ${totalpages}`,
        pageNumberX,
        footerY + 25
      );
    } catch (error) {
      console.error("Error loading logo:", error);
    }
  };

  const handleDownload = async (setHeight) => {
    setHeight("auto");
    const input = contentRef.current;
    const pdf = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
      compress: true,
      hotfixes: ["px_scaling"],
      precision: 16,
    });
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const footerHeight = 60;
    const scale = 4;

    try {
      // First Page
      await generateFirstPage(pdf, pdfWidth, pdfHeight, footerHeight);
      await addFooter(pdf, 1, 3);
      pdf.addPage();

      // Second Page (Filters Page)
      const canvas2 = document.createElement("canvas");
      const scale = 4;
      canvas2.width = pdfWidth * scale;
      canvas2.height = (pdfHeight - footerHeight) * scale;
      const ctx2 = canvas2.getContext("2d");

      ctx2.scale(scale, scale);
      ctx2.imageSmoothingEnabled = true;
      ctx2.imageSmoothingQuality = "high";
      ctx2.fillStyle = "white";
      ctx2.fillRect(0, 0, pdfWidth, pdfHeight - footerHeight);

      try {
        await new Promise((resolve) => {
          const dashboardImg = new Image();
          dashboardImg.onload = () => {
            const imgWidth = 240;
            const imgHeight = 180;
            const imgX = pdfWidth - imgWidth + 40;
            const imgY = 20;

            ctx2.drawImage(dashboardImg, imgX, imgY, imgWidth, imgHeight);
            resolve();
          };
          dashboardImg.src = DashboardPage2;
        });
      } catch (error) {
        console.error("Error loading dashboard image:", error);
      }

      ctx2.fillStyle = "black";
      ctx2.font = "bold 16px Public Sans, sans-serif";
      ctx2.fillText("Reports filters applied", 50, 75);

      const maxWidth = pdfWidth - 300;
      let startY = 105;

      if (dataFilters.propertiesData && dataFilters.propertiesData.length > 0) {
        ctx2.font = "14px Public Sans, sans-serif";
        let line = "Properties : ";
        dataFilters.propertiesData.forEach((property, index) => {
          const propertyName = property.name;
          const text =
            index === dataFilters.propertiesData.length - 1
              ? propertyName
              : `${propertyName}, `;
          const testLine = line + text;

          if (ctx2.measureText(testLine).width > maxWidth) {
            ctx2.fillText(line, 50, startY);
            line = text;
            startY += 20;
          } else {
            line = testLine;
          }
        });

        // Draw the last line
        ctx2.fillText(line, 50, startY);
      } else {
        ctx2.font = "14px Public Sans, sans-serif";
        ctx2.fillText("Properties : All properties", 50, 105);
      }

      if (dataFilters.startDate && dataFilters.endDate) {
        ctx2.font = "14px Public Sans, sans-serif";
        ctx2.fillText(
          `Period : ${dataFilters.startDate || "N/A"} to ${
            dataFilters.endDate || "N/A"
          }`,
          50,
          startY + 25
        );
      }

      const page2ImgData = canvas2.toDataURL("image/png", 1.0);
      pdf.addImage(
        page2ImgData,
        "PNG",
        0,
        0,
        pdfWidth,
        pdfHeight - footerHeight
      );
      await addFooter(pdf, 2, 3);

      pdf.addPage();

      window.scrollTo(0, 0);

      // Set background color for the third page
      pdf.setFillColor(247, 247, 247); // #F7F7F7
      pdf.rect(0, 0, pdfWidth, pdfHeight, "F");

      const contentCanvas = await html2canvas(input, {
        logging: false,
        useCORS: true,
        allowTaint: true,
        scale: 4,
        height: input.scrollHeight,
        windowHeight: input.scrollHeight,
        backgroundColor: "#F7F7F7",
        imageTimeout: 0,
        onclone: (clonedDoc) => {
          // Ensure fonts are loaded in cloned document
          const style = clonedDoc.createElement("style");
          style.textContent = `
          @font-face {
            font-family: 'Public Sans';
            font-display: swap;
            size-adjust: 100%;
            src: local('Public Sans');
          }
        `;
          clonedDoc.head.appendChild(style);
        },
      });

      const contentWidth = contentCanvas.width / scale;
      const contentHeight = (contentCanvas.height + 100) / scale;
      const pageHeight = pdfHeight;
      const pageWidth = pdfWidth + 23;
      const ratio = pageWidth / contentWidth;
      const scaledHeight = contentHeight * ratio;

      const maxHeight = 775 - 60; 
      let finalHeight = scaledHeight;
      let finalWidth = pageWidth;

      if (finalHeight > maxHeight) {
        const heightScalingFactor = maxHeight / finalHeight;
        finalWidth *= heightScalingFactor;
        finalHeight *= heightScalingFactor;
      }

      const xOffset = (pdfWidth - finalWidth) / 2;
      const yOffset = 50;

      pdf.setFontSize(14);
      pdf.setFont("helvetica", "bold");
      const title =
        tabIndex === 0
          ? "Properties Dashboard"
          : "Applications/Applicants Dashboard";
      pdf.text(title, 25, yOffset - 5);

      pdf.addImage(
        contentCanvas,
        "PNG",
        xOffset,
        yOffset,
        finalWidth,
        finalHeight,
        undefined,
        "FAST"
      );

      await addFooter(pdf, 3, 3);

      pdf.save("dashboard.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setHeight("calc(100vh - 215px)");
    }
  };

  return { handleDownload };
};
