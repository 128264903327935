import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import ReactLeaflet from "../charts-metrics/reactLeaflet";
import { getApplicationDistributionRequest } from '../../../redux/reducer/applicantsDashboardReducer';

const ApplicationDistribution = () => {
  const dispatch = useDispatch();
  const { applicants } = useSelector((s) => s.applicantsDashboard);

  useEffect(() => {
    dispatch(getApplicationDistributionRequest());
  }, [dispatch]);

  const zipData = applicants?.application_distribution || [];

  return (
    <Box className="container">
      <Typography variant="h4" sx={{ fontWeight: 700, mb: 3 ,fontSize:"16px"}}>
        Application Distribution By Geography
      </Typography>
      {zipData.length > 0 ? (
        <ReactLeaflet height="80%" zips={zipData} />
      ) : (
        <ReactLeaflet height="80%" />
      )}
    </Box>
  );
};

export default ApplicationDistribution;
