import { ofType } from "redux-observable";
import { mergeMap, catchError, concatMap, withLatestFrom } from "rxjs/operators";
import { of, from } from "rxjs";
import { errorMessageApi } from "../reducer/uiReducer";

import {
    getTotalApplicantsRequest,
    getTotalApplicantsSuccess,
    getTotalApplicantsFailed,
    getTotalTenantsRequest,
    getTotalTenantsSuccess,
    getTotalTenantsFailed,
    getApplicantRaceEthnicityRequest,
    getApplicantRaceEthnicitySuccess,
    getApplicantRaceEthnicityFailed,
    getAffordabilityLevelDistributionRequest,
    getAffordabilityLevelDistributionSuccess,
    getAffordabilityLevelDistributionFailed,
    getApplicantAgeSegmentationRequest,
    getApplicantAgeSegmentationSuccess,
    getApplicantAgeSegmentationFailed,
    getApplicationsStatusRequest,
    getApplicationsStatusSuccess,
    getApplicationsStatusFailed,
    getIncomeRentRequest,
    getIncomeRentSuccess,
    getIncomeRentFailed,
    getApplicationDistributionRequest,
    getApplicationDistributionSuccess,
    getApplicationDistributionFailed,
    updateApplicationDashboardFilter,

} from "../reducer/applicantsDashboardReducer"


import { applicantsDashboardService } from "../service";

const getTotalApplicants = (action$) =>
    action$.pipe(
      ofType(getTotalApplicantsRequest.type),
      mergeMap(({payload}) => {
        return from(applicantsDashboardService.getTotalApplicants(payload)).pipe(
          concatMap((res) => {
            return of(getTotalApplicantsSuccess(res.data));
          }),
          catchError((error) => {
            return of(getTotalApplicantsFailed(), errorMessageApi(error));
          })
        );
      })
    );

const getTotalTenants = (action$) =>
    action$.pipe(
        ofType(getTotalTenantsRequest.type),
        mergeMap(({payload}) => {
            return from(applicantsDashboardService.getTotalTenants(payload)).pipe(
                concatMap((res) => {
                    return of(getTotalTenantsSuccess(res.data)); 
                }),
                catchError((error) => {
                    return of(getTotalTenantsFailed(), errorMessageApi(error));
                })
            );
        })
    );

const getApplicationsStatusEpic = (action$) =>
    action$.pipe(
        ofType(getApplicationsStatusRequest.type),
        mergeMap(({payload}) => {
            return from(applicantsDashboardService.getApplicationsStatus(payload)).pipe(
                mergeMap((res) => {
                    const applicationCount = res.data ? res.data["application count"] : res["application count"];
                    return of(getApplicationsStatusSuccess(applicationCount));
                }),
                catchError((error) => {
                    return of(getApplicationsStatusFailed(error));
                })
            );
        })
    );

const getAffordabilityLevelEpic = (action$) =>
    action$.pipe(
        ofType(getAffordabilityLevelDistributionRequest.type),
        mergeMap(({payload}) => {
        return from(applicantsDashboardService.getAffordabilityLevel(payload)).pipe(
            mergeMap((res) => {
            return of(getAffordabilityLevelDistributionSuccess(res));
            }),
            catchError((error) => {
            return of(getAffordabilityLevelDistributionFailed(error));
            })
        );
        })
    );

const getIncomeRentEpic = (action$) =>
    action$.pipe(
        ofType(getIncomeRentRequest.type),
        mergeMap(({payload}) => {
        return from(applicantsDashboardService.getIncomeRent(payload)).pipe(
            mergeMap((res) => {
            return of(getIncomeRentSuccess(res)); 
            }),
            catchError((error) => {
            return of(getIncomeRentFailed(error)); 
            })
        );
        })
    );

const getRaceEthnicityEpic = (action$) =>
    action$.pipe(
        ofType(getApplicantRaceEthnicityRequest.type),
        mergeMap(({payload}) => {
        return from(applicantsDashboardService.getRaceEthnicity(payload)).pipe(
            mergeMap((res) => {
            return of(getApplicantRaceEthnicitySuccess(res)); 
            }),
            catchError((error) => {
            return of(getApplicantRaceEthnicityFailed(error)); 
            })
        );
        })
    );

const getAgeGenderEpic = (action$) =>
    action$.pipe(
        ofType(getApplicantAgeSegmentationRequest.type),
        mergeMap(({payload}) => {
        return from(applicantsDashboardService.getAgeGender(payload)).pipe(
            mergeMap((res) => {
            return of(getApplicantAgeSegmentationSuccess(res)); 
            }),
            catchError((error) => {
            return of(getApplicantAgeSegmentationFailed(error)); 
            })
        );
        })
    );

const getApplicationsMapEpic = (action$) =>
    action$.pipe(
        ofType(getApplicationDistributionRequest.type),
        mergeMap(({payload}) => {
        return from(applicantsDashboardService.getApplicationsZipcode(payload)).pipe(
            mergeMap((res) => {
            return of(getApplicationDistributionSuccess(res)); 
            }),
            catchError((error) => {
            return of(getApplicationDistributionFailed(error)); 
            })
        );
        })
    );
    
const handleFilterUpdateEpic = (action$, state$) =>
    action$.pipe(
        ofType(updateApplicationDashboardFilter),
        withLatestFrom(state$),
        mergeMap(([, state]) => {
            const filters = state.applicantsDashboard.filter;

            return from(Promise.all([
                applicantsDashboardService.getTotalApplicants(filters)
                    .then(res => getTotalApplicantsSuccess(res.data))
                    .catch(() => getTotalApplicantsFailed()),
                
                applicantsDashboardService.getTotalTenants(filters)
                    .then(res => getTotalTenantsSuccess(res.data))
                    .catch(() => getTotalTenantsFailed()),

                applicantsDashboardService.getApplicationsStatus(filters)
                    .then(res => getApplicationsStatusSuccess(res.data ? res.data["application count"] : res["application count"]))
                    .catch(() => getApplicationsStatusFailed()),

                applicantsDashboardService.getAffordabilityLevel(filters)
                    .then(res => getAffordabilityLevelDistributionSuccess(res))
                    .catch(() => getAffordabilityLevelDistributionFailed()),

                applicantsDashboardService.getIncomeRent(filters)
                    .then(res => getIncomeRentSuccess(res))
                    .catch(() => getIncomeRentFailed()),

                applicantsDashboardService.getRaceEthnicity(filters)
                    .then(res => getApplicantRaceEthnicitySuccess(res))
                    .catch(() => getApplicantRaceEthnicityFailed()),

                applicantsDashboardService.getAgeGender(filters)
                    .then(res => getApplicantAgeSegmentationSuccess(res))
                    .catch(() => getApplicantAgeSegmentationFailed()),

                applicantsDashboardService.getApplicationsZipcode(filters)
                    .then(res => getApplicationDistributionSuccess(res))
                    .catch(() => getApplicationDistributionFailed())
            ])).pipe(
                mergeMap(actions => of(...actions)),
                catchError(error => of(errorMessageApi(error)))
            );
        })
    );



export const applicantsDashboardEpic = [
      getTotalApplicants,
      getTotalTenants,
      getApplicationsStatusEpic,
      getAffordabilityLevelEpic,
      getIncomeRentEpic,
      getRaceEthnicityEpic,
      getAgeGenderEpic,
      getApplicationsMapEpic,
      handleFilterUpdateEpic,
    ];