import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const BarChart = ({ data }) => {
  const svgRef = useRef();
  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Resize observer to handle dynamic dimensions
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (dimensions.width === 0 || dimensions.height === 0) return;

    const svg = d3.select(svgRef.current);
    const { width, height } = dimensions;
    const margin = { top: 20, right: 20, bottom: 60, left: 50 };

    // Clear previous content
    svg.selectAll("*").remove();

    // Scales
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.name))
      .range([margin.left, width - margin.right])
      .padding(0.6);

    const yMax = d3.max(data, (d) => d.count);
    const tickStep = yMax / 5; 

    const yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([height - margin.bottom, margin.top]);

    // Add grid lines
    svg
      .append("g")
      .attr("class", "grid-lines")
      .selectAll("line")
      .data(d3.range(0, yMax + tickStep, tickStep)) 
      .enter()
      .append("line")
      .attr("x1", margin.left)
      .attr("x2", width - margin.right)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#f2f2f2")
      .attr("stroke-width", 1);

    // X Axis
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height - margin.bottom+20})`)
      .call(d3.axisBottom(xScale).tickSize(0))
      .selectAll(".tick line, .domain") 
      .remove();

    svg
      .selectAll(".x-axis text")
      .style("font-size", "12px")
      .style("text-anchor", "middle")
      .attr("dy", "32px");

   
    svg
      .selectAll(".x-axis text")
      .each(function () {
        const text = d3.select(this);
        const label = text.text();

        if (label === "Prefer not to say") {
         
          text.text(""); 
          text.append("tspan").text("Prefer not").attr("x", 0).attr("dy", 0); 
          text.append("tspan").text("to say").attr("x", 0).attr("dy", "1.2em"); 
        } else {
          
          const words = label.split(" "); 
          text.text(""); 

          words.forEach((word, i) => {
            text
              .append("tspan")
              .text(word)
              .attr("x", 0) 
              .attr("dy", i > 0 ? "1.2em" : 0); 
          });
        }
      });

    // Y Axis
    svg
      .append("g")
      .attr("class", "y-axis")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(
        d3.axisLeft(yScale)
          .tickValues(d3.range(0, yMax + tickStep, tickStep)) 
          .tickSize(0)
      )
      .selectAll(".tick line, .domain") 
      .remove();

    svg.selectAll(".y-axis text").style("font-size", "12px");

    // Bars
    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.name))
      .attr("y", (d) => yScale(d.count))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => height - margin.bottom - yScale(d.count))
      .attr("fill", "#9A9BFF")
      .on("mouseover", function () {
        d3.select(this).attr("fill", d3.rgb("#9A9BFF").darker(-0.5));
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", "#9A9BFF");
      });

    // Add labels on bars
    svg
      .selectAll(".label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) => xScale(d.name) + xScale.bandwidth() / 2)
      .attr("y", (d) => yScale(d.count) - 5)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .text((d) => d.count);
  }, [data, dimensions]);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "80%",
      }}
    >
      <svg ref={svgRef} width={dimensions.width+40} height={dimensions.height+50} />
    </div>
  );
};

export default BarChart;
