import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import DonutChart from '../charts-metrics/donutChart';
import { getApplicantRaceEthnicityRequest } from '../../../redux/reducer/applicantsDashboardReducer';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  minHeight: "622px"
}));

const ChartContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // height: '404px', // Fixed height for both charts
  // marginBottom: '20px',
});

const LegendContainer = styled(Grid)(({ state , isMobile}) => ({
  backgroundColor: 'rgb(0 0 0 / 4%)',
  padding: '15px',
  borderRadius: '10px',
  // width: state ? "70%" : isMobile ? "70%" :  "50%", // Conditional styling based on state prop
  width: isMobile ? "120%" : state ? "90%" : "65%",
  height: state ? "190px" : "",
  marginTop: state ? "10px" : "",
  marginInline : state? "auto" :"",
  marginLeft:"10px",
  alignItems:"center",
}));

const LegendItem = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '3px',
  padding: '8px',
  height: '25px',
});

const ColorDot = styled(Box)({
  width: 10,
  height: 10,
  borderRadius: '50%',
  marginRight: 8,
  flexShrink: 0
});

// Color mappings remain the same
const raceColorMapping = {
  "American Indian or Alaskan Native": "#7E464D",
  "Black or African-American": "#97545C",
  "Native Hawaiian or Other Pacific Islander": "#B1626B",
  "Asian": "#CA6F7A",
  "White": "#E27E8A",
  "Unknown": "#FC8C99",
  "Other": "#FDA3AD",
  "Prefer not to say": "#FDBAC2"
};

const ethnicityColorMapping = {
  "Hispanic or Latino": "#6C6DB3",
  "No - Not Hispanic or Latino": "#7B7CCB",
  "Spanish Origins": "#8B8CE6",
  "No - Not Spanish Origins": "#9A9BFF",
  "Prefer not to say": "#AEAFFF"
};

const RaceEthnicity = () => {
  const [isRaceDataAvailable, setIsRaceDataAvailable] = useState(false);
  const [isEthnicDataAvailable, setIsEthnicDataAvailable] = useState(false);
  const [isSmallDevice, setIsSmallDevice] = useState(false);
  const [isMobile, setIsMobile] = useState(false)

  const dispatch = useDispatch();
  const { applicants } = useSelector((state) => state.applicantsDashboard);

  useEffect(() => {
    dispatch(getApplicantRaceEthnicityRequest());
  }, [dispatch]);

  // Data preparation remains the same
  const raceData = applicants?.race_ethnicity?.race
    ? Object.keys(applicants.race_ethnicity.race).map((key) => ({
        name: key,
        value: applicants.race_ethnicity.race[key],
        color: raceColorMapping[key] || "#000"
      }))
    : [];

  const ethnicData = applicants?.race_ethnicity?.ethnicity
    ? Object.keys(applicants.race_ethnicity.ethnicity).map((key) => ({
        name: key,
        value: applicants.race_ethnicity.ethnicity[key],
        color: ethnicityColorMapping[key] || "#000"
      }))
    : [];

  useEffect(() => {
    let total_race_value = 0;
    let total_ethnic_value = 0;
    
    for(let i of raceData) {
      total_race_value += i?.value;
    }
    for(let i of ethnicData) {
      total_ethnic_value += i?.value;
    }
    
    setIsRaceDataAvailable(total_race_value > 0);
    setIsEthnicDataAvailable(total_ethnic_value > 0);
  }, [raceData, ethnicData]);

  useEffect(() => {
    const checkScreenWidth = () => {
      if (window.innerWidth <= 1200) {
        setIsSmallDevice(true);
      } else {
        setIsSmallDevice(false);
      }
      if(window.innerWidth <=830){
        setIsMobile(true)
      }else {
        setIsMobile(false);
      }
    };

    checkScreenWidth();

    //  event listener to handle window resize
    window.addEventListener("resize", checkScreenWidth);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []); 



  return (
    <Box className="container">
      <Typography variant="h4" sx={{ fontWeight: 700, marginBottom: 2 ,fontSize:"16px"}}>
        Applicant Race and Ethnicity
      </Typography>
      
      {(isEthnicDataAvailable && isRaceDataAvailable) ? (
        <Grid  spacing={2} sx={{ display: "flex", alignItems: "flex-start" , marginTop:"10px", flexDirection: isMobile ? "column" : "row", justifyContent:"center", alignItems:"center"}}>
          {isRaceDataAvailable ? (
            <Grid item xs={6} sx={{ display: 'flex', justifyContent:"space-around",flexDirection: isSmallDevice ? "column" : "row"}}>
              <ChartContainer>
                <DonutChart
                  data={raceData}
                  width={200}
                  height={200}
                  colors={raceData.map((item) => item.color)}
                  label="Race"
                />
              </ChartContainer>
              <LegendContainer isMobile={isMobile}  state ={isSmallDevice} container>
                {raceData.map((item, index) => (
                  <LegendItem item xs={6} key={index}>
                    <ColorDot sx={{ backgroundColor: item.color }} />
                    <Typography sx = {{fontSize: '12px', lineHeight:1.5}}>{item.name}</Typography>
                  </LegendItem>
                ))}
              </LegendContainer>
            </Grid>
          ) : (
            <Typography variant="h2" sx={{ height: "100%", display: "grid", placeItems: "center",fontSize:"16px" }}>
              No Data Found
            </Typography>
          )}
          
          {isEthnicDataAvailable ? (
            <Grid item xs={6} sx={{ display: 'flex' , justifyContent:"space-around", flexDirection: isSmallDevice ? "column" : "row"}}>
              <ChartContainer>
                <DonutChart
                  data={ethnicData}
                  width={200}
                  height={200}
                  colors={ethnicData.map((item) => item.color)}
                  label="Ethnicity"
                />
              </ChartContainer>
              <LegendContainer isMobile={isMobile}   state ={isSmallDevice}  container>
                {ethnicData.map((item, index) => (
                  <LegendItem item xs={6} key={index}>
                    <ColorDot sx={{ backgroundColor: item.color }} />
                    <Typography sx = {{fontSize: '12px', lineHeight: isSmallDevice ? 2 :1.5}}>{item.name}</Typography>
                  </LegendItem>
                ))}
              </LegendContainer>
            </Grid>
          ) : (
            <Typography variant="h2" sx={{ height: "100%", display: "grid", placeItems: "center" ,fontSize:"16px"}}>
              No Data Found
            </Typography>
          )}
        </Grid>
      ) : (
        <Typography variant="h2" sx={{ height: "80%", display: "grid", placeItems: "center",fontSize:"16px" }}>
          No Data Found
        </Typography>
      )}
    </Box>
  );
};

export default RaceEthnicity;