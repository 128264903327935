import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { useSelector } from "react-redux";

const GroupedBarChart = ({ data, colors, subgroups }) => {
  const {
    loggedUser: { user_group_id },
  } = useSelector((s) => s.profile);
  const svgRef = useRef();
  const chartContainerRef = useRef(); // Ref to the container element
  const [tooltipText, setTooltipText] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [chartDimensions, setChartDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateChartDimensions = () => {
      const { width, height } = chartContainerRef.current.getBoundingClientRect();
      setChartDimensions({ width, height });
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver(updateChartDimensions);
    resizeObserver.observe(chartContainerRef.current);

    // Cleanup on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    // Clear previous chart
    d3.select(svgRef.current).selectAll("*").remove();

    // Set margins
    const margin = { top: 20, right: 30, bottom: 60, left: 50 };
    const width = chartDimensions.width - margin.left - margin.right;
    const height = chartDimensions.height - margin.top - margin.bottom;

    // Create SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Set up scales
    const groups = data.map((d) => d.bedroomType);
    const x = d3.scaleBand()
      .domain(groups)
      .range([0, width])
      .padding(0.2);

    const y = d3.scaleLinear()
      .domain([0, 100])
      .range([height, 0]);

    // Add horizontal grid lines (behind bars)
    const gridLines = svg.append("g")
      .attr("class", "grid-lines");

    gridLines.selectAll("line")
      .data(y.ticks(5))
      .enter()
      .append("line")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", (d) => y(d))
      .attr("y2", (d) => y(d))
      .attr("stroke", "#f2f2f2");

    // Add axes (without ticks and lines)
    svg.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height+20})`)
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .style("text-anchor", "middle")
      .style("font-size", "12px");

    svg.append("g")
      .attr("class", "y-axis")
      // .ticks(4)
      .call(d3.axisLeft(y).tickFormat((d) => `${d}%`).ticks(4))
      .selectAll("text")
      .style("font-size", "12px");

    // Remove axis lines
    svg.selectAll(".domain").style("display", "none");
    svg.selectAll(".tick line").style("display", "none");

    // Set up scales for subgroups
    const xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.25]);

    const color = d3.scaleOrdinal()
      .domain(subgroups)
      .range(colors)
      .unknown("#ccc");

    // Create and render the bars
    const barGroups = svg.append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", (d) => `translate(${x(d.bedroomType)},0)`);

    barGroups.selectAll("rect")
      .data((d) => d.data)
      .enter()
      .append("rect")
      .attr("x", (d) => xSubgroup(d.name))
      .attr("y", (d) => y(d.value.average_percentage || 0))
      .attr("width", xSubgroup.bandwidth())
      .attr("height", (d) => height - y(d.value.average_percentage || 0))
      .attr("fill", (d) => color(d.name))
      .on("mouseover", function(event, d) {
        const tooltipContent = {
          "Average Monthly Rent": `$${d.value.average_rent?.toLocaleString() || 0}`,
          "Average Monthly Income": `$${d.value.average_income?.toLocaleString() || 0}`,
        };
        setTooltipText(tooltipContent);

        const rect = this.getBoundingClientRect();
        const svgRect = svgRef.current.getBoundingClientRect();
        
        // Position tooltip above the bar
        const xPos = rect.left - svgRect.left + (rect.width / 2);
        const yPos = rect.top - svgRect.top - 10;

        setTooltipPosition({ top: yPos, left: xPos });
        
        // Highlight bar
        d3.select(this)
          .attr("opacity", 0.7);
      })
      .on("mouseout", function() {
        setTooltipText("");
        d3.select(this)
          .transition()
          .duration(200)
          .attr("opacity", 1);
      });

    // Add value labels on top of bars
    barGroups.selectAll(".bar-label")
      .data((d) => d.data)
      .enter()
      .append("text")
      .attr("class", "bar-label")
      .attr("x", (d) => xSubgroup(d.name) + xSubgroup.bandwidth() / 2)
      .attr("y", (d) => y(d.value.average_percentage || 0) - 5)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .text((d) => `${d.value.average_percentage || 0}%`);

  }, [data, colors, subgroups, user_group_id, chartDimensions]);

  return (
    <div ref={chartContainerRef} style={{ position: "relative", width: "100%", height: "80%" }}>
      <svg ref={svgRef}></svg>
      {tooltipText && (
        <div
          className="tooltip"
          style={{
            position: "absolute",
            top: tooltipPosition.top - 80,
            left: tooltipPosition.left,
            transform: "translateX(-50%)",
            backgroundColor: "white",
            padding: "12px 16px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize: "14px",
            zIndex: 10,
            width: "auto",
            minWidth: "120px",
            pointerEvents: "none",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          {Object.entries(tooltipText).map(([label, value]) => (
            <div key={label} style={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
              <span style={{ color: "#666" }}>{label}</span>
              <span style={{ fontWeight: "600" }}>{value}</span>
            </div>
          ))}
          <div
            style={{
              position: "absolute",
              bottom: "-8px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "0",
              height: "0",
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderTop: "8px solid white",
              filter: "drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1))"
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GroupedBarChart;
