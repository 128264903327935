import { ofType } from "redux-observable";
import { mergeMap, catchError, concatMap, withLatestFrom } from "rxjs/operators";
import { of, from } from "rxjs";
import { errorMessageApi } from "../reducer/uiReducer";

import {
  getPropertiesRequest,
  getPropertiesSuccess,
  getPropertiesFailed,
  getUnitsOccupancyRequest,
  getUnitsOccupancySuccess,
  getUnitsOccupancyFailed,
  getPropertyApprovalStatusRequest,
  getPropertyApprovalStatusSuccess,
  getPropertyApprovalStatusFailed,
  getTenantTrendsRequest,
  getTenantTrendsSuccess,
  getTenantTrendsFailed,
  getUnitTypeDistributionRequest,
  getUnitTypeDistributionSuccess,
  getUnitTypeDistributionFailed,
  getAverageHouseholdIncomeRequest,
  getAverageHouseholdIncomeSuccess,
  getAverageHouseholdIncomeFailed,
  getPropertyDistributionRequest,
  getPropertyDistributionSuccess,
  getPropertyDistributionFailed,
  getAverageHabitationLengthRequest,
  getAverageHabitationLengthSuccess,
  getAverageHabitationLengthFailed,
  updatePropertyDashboardFilter,
} from "../reducer/propertiesDashboardReducer";

//service
import { dashboardService } from "../service";

/** Get properties */
const getProperties = (action$) =>
  action$.pipe(
    ofType(getPropertiesRequest),
    mergeMap(({payload}) => {
      return from(dashboardService.getProperties(payload)).pipe(
        concatMap((res) => {
          return of(getPropertiesSuccess(res.data));
        }),
        catchError((res) => of(getPropertiesFailed(), errorMessageApi(res)))
      );
    })
  );

/** Get UnitsOccupancy  */
const getUnitsOccupancy = (action$) =>
  action$.pipe(
    ofType(getUnitsOccupancyRequest),
    mergeMap(({payload}) => {
      return from(dashboardService.getUnitsOccupancy(payload)).pipe(
        concatMap((res) => {
          return of(getUnitsOccupancySuccess(res.data));
        }),
        catchError((res) => of(getUnitsOccupancyFailed(), errorMessageApi(res)))
      );
    })
  );

/** Get Property Approval Status */
const getPropertyApprovalStatus = (action$) =>
  action$.pipe(
    ofType(getPropertyApprovalStatusRequest),
    mergeMap(({payload}) => {
      return from(dashboardService.getPropertyApprovalStatus(payload)).pipe(
        concatMap((res) => {
          return of(getPropertyApprovalStatusSuccess(res.data));
        }),
        catchError((res) =>
          of(getPropertyApprovalStatusFailed(), errorMessageApi(res))
        )
      );
    })
  );

/** Get Tenant Trends */
const getTenantTrends = (action$) =>
  action$.pipe(
    ofType(getTenantTrendsRequest),
    mergeMap(({payload}) => {
      return from(dashboardService.getTenantTrends(payload)).pipe(
        concatMap((res) => {
          return of(getTenantTrendsSuccess(res.data));
        }),
        catchError((res) => of(getTenantTrendsFailed(), errorMessageApi(res)))
      );
    })
  );

/** Get Unit Type Distribution */
const getUnitTypeDistribution = (action$) =>
  action$.pipe(
    ofType(getUnitTypeDistributionRequest),
    mergeMap(({payload}) => {
      return from(dashboardService.getUnitTypeDistribution(payload)).pipe(
        concatMap((res) => {
          return of(getUnitTypeDistributionSuccess(res.data));
        }),
        catchError((res) =>
          of(getUnitTypeDistributionFailed(), errorMessageApi(res))
        )
      );
    })
  );

/** Get Average HouseholdIncome */
const getAverageHouseholdIncome = (action$) =>
  action$.pipe(
    ofType(getAverageHouseholdIncomeRequest),
    mergeMap(({payload}) => {
      return from(dashboardService.getAverageHouseholdIncome(payload)).pipe(
        concatMap((res) => {
          return of(getAverageHouseholdIncomeSuccess(res.data));
        }),
        catchError((res) =>
          of(getAverageHouseholdIncomeFailed(), errorMessageApi(res))
        )
      );
    })
  );

/** Get Property Distribution */
const getPropertyDistribution = (action$) =>
  action$.pipe(
    ofType(getPropertyDistributionRequest),
    mergeMap(({payload}) => {
      return from(dashboardService.getPropertyDistribution(payload)).pipe(
        concatMap((res) => {
          return of(getPropertyDistributionSuccess(res.data));
        }),
        catchError((res) =>
          of(getPropertyDistributionFailed(), errorMessageApi(res))
        )
      );
    })
  );

/** Get Average Habitation Length */
const getAverageHabitationLength = (action$) =>
  action$.pipe(
    ofType(getAverageHabitationLengthRequest),
    mergeMap(({payload}) => {
      return from(dashboardService.getAverageHabitationLength(payload)).pipe(
        concatMap((res) => {
          return of(getAverageHabitationLengthSuccess(res.data));
        }),
        catchError((res) =>
          of(getAverageHabitationLengthFailed(), errorMessageApi(res))
        )
      );
    })
  );

const handleFilterUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(updatePropertyDashboardFilter),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const filters = state.dashboard.filter;

      return from(Promise.all([
        dashboardService.getProperties(filters)
          .then(res => getPropertiesSuccess(res.data))
          .catch(() => getPropertiesFailed()),

        dashboardService.getUnitsOccupancy(filters)
          .then(res => getUnitsOccupancySuccess(res.data))
          .catch(() => getUnitsOccupancyFailed()),

        dashboardService.getPropertyApprovalStatus(filters)
          .then(res => getPropertyApprovalStatusSuccess(res.data))
          .catch(() => getPropertyApprovalStatusFailed()),

        dashboardService.getTenantTrends(filters)
          .then(res => getTenantTrendsSuccess(res.data))
          .catch(() => getTenantTrendsFailed()),

        dashboardService.getUnitTypeDistribution(filters)
          .then(res => getUnitTypeDistributionSuccess(res.data))
          .catch(() => getUnitTypeDistributionFailed()),

        dashboardService.getAverageHouseholdIncome(filters)
          .then(res => getAverageHouseholdIncomeSuccess(res.data))
          .catch(() => getAverageHouseholdIncomeFailed()),

        dashboardService.getPropertyDistribution(filters)
          .then(res => getPropertyDistributionSuccess(res.data))
          .catch(() => getPropertyDistributionFailed()),

        dashboardService.getAverageHabitationLength(filters)
          .then(res => getAverageHabitationLengthSuccess(res.data))
          .catch(() => getAverageHabitationLengthFailed()),
      ])).pipe(
        mergeMap(actions => of(...actions)),
        catchError(error => of(errorMessageApi(error)))
      );
    })
  );

export const dashboardEpic = [
  getProperties,
  getUnitsOccupancy,
  getPropertyApprovalStatus,
  getTenantTrends,
  getUnitTypeDistribution,
  getAverageHouseholdIncome,
  getPropertyDistribution,
  getAverageHabitationLength,
  handleFilterUpdateEpic,
];
