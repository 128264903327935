import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "../textField";
import { styled } from '@mui/material';
import style from "./style";

const AutoCompleteComponent = (props) => {

    // props
    const { className, name, label, options, required, value, matchBy, display, handleChange, validation, disabled, variant, placeholder,onInputChange, ...rest } = props;

    return (
        <Autocomplete
        className={className}
            id={`mui-ac-${name}`}
            options={options}
            value={value}
            disabled={disabled}
            isOptionEqualToValue={(option, val) => {
                return option[matchBy] === val[matchBy]
            }}
            getOptionLabel={(option) => {
                if (value && (value[matchBy] === 0 || value[matchBy])) {
                    return option?.[display] || options.find(f => (f[matchBy].toString() === value[matchBy].toString()))?.[display] || "";
                } else {
                    return option?.[display] || "";
                }
            }}
            onChange={(e, val) => handleChange({ name, value: val })}
            onInputChange={(e, val) => onInputChange?.(e, val)}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        aria-label={placeholder}
                        label={label}
                        variant={variant}
                        InputLabelProps={{
                            required,
                            shrink: true,
                        }}
                        InputProps={{
                            ...params.InputProps,
                        }}
                        validation={validation}
                    />
                );
            }}
            {...rest}
        />
    );
}

AutoCompleteComponent.defaultProps = {
    name: "ac",
    label: "",
    options: [],
    matchBy: "id",
    display: "value",
    handleChange: () => { },
    validation: "",
    disabled: false,
    variant: "outlined",
    required: false,
    value: {},
    placeholder: ""
};

AutoCompleteComponent.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    matchBy: PropTypes.string,
    display: PropTypes.string,
    handleChange: PropTypes.func,
    validation: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.any,
    required: PropTypes.bool,
    value: PropTypes.any,
    placeholder: PropTypes.string,

};

export const AutoComplete = styled(AutoCompleteComponent)(style);
