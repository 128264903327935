import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import BarChart1 from "../charts-metrics/barChart1";
import BarChart from "../charts-metrics/barChart";

import { getApplicantAgeSegmentationRequest } from '../../../redux/reducer/applicantsDashboardReducer';

const GenderSegmentation = () => {
  const dispatch = useDispatch();
  const { applicants } = useSelector((s) => s.applicantsDashboard);

  useEffect(() => {
    dispatch(getApplicantAgeSegmentationRequest());
  }, [dispatch]);

  const processedData = [];
  if (applicants?.gender_age) {
    const genders = Object.keys(applicants.gender_age);

    genders.forEach((gender) => {
      const totalPopulation = Object.values(applicants.gender_age[gender]).reduce(
        (sum, count) => sum + (count || 0),
        0
      );

      processedData.push({
        name: gender,
        count: totalPopulation,
      });
    });
  }

  const hasData = processedData.some(item => item.count !== 0);
  return (
    <Box className="container" sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: 1,fontSize:"16px" }}>
        Applicant Gender
      </Typography>
      {hasData ? (
        <BarChart1 data={processedData} />
      ) : (
        <Typography variant="h2" sx={{ height:"80%", display:"grid", placeItems:"center" ,fontSize:"16px"}}>
     No Data Found
    </Typography>
      )}
    </Box>
  );
};

export default styled(GenderSegmentation)(() => ({}));