import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const LineChart = ({ data, labels = [] }) => {
  const svgRef = useRef();
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const axisPadding = 10;

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (dimensions.width === 0 || dimensions.height === 0) return;

    const svg = d3.select(svgRef.current);
    const margin = { top: 20, right: 70, bottom: 50, left: 40 };

    svg.selectAll("*").remove();

    const { width, height } = dimensions;

    const xScale = d3
      .scaleBand()
      .domain(labels)
      .range([margin.left + axisPadding, width - margin.right])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.y)])
      .nice(6)
      .range([height - margin.bottom - 10, margin.top]);

    // Add grid lines
    const gridLines = svg
      .append("g")
      .attr("class", "grid-lines")
      .attr("transform", `translate(${margin.left + 20},0)`)

    gridLines
      .selectAll("line")
      .data(yScale.ticks(3))
      .enter()
      .append("line")
      .attr("x1", 0)
      .attr("x2", dimensions.width - margin.left - margin.right)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#f2f2f2")
      .attr("stroke-width", "1px");


    // X-Axis
    const xAxis = d3.axisBottom(xScale).tickSize(0);
    const xAxisGroup = svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height - margin.bottom + 10})`)
      .call(xAxis);

    xAxisGroup
      .selectAll(".tick text")
      .text((d) => (d.length > 10 ? `${d.slice(0, 10)}...` : d))
      .style("font-size", "12px")
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-45)");

    xAxisGroup.selectAll(".tick line").remove();
    xAxisGroup.select(".domain").remove();

    // Y-Axis
    const yAxis = d3.axisLeft(yScale).ticks(3).tickSize(0);
    const yAxisGroup = svg
      .append("g")
      .attr("class", "y-axis")
      .attr("transform", `translate(${margin.left},0)`)
      .call(yAxis);

    yAxisGroup.selectAll("text").style("font-size", "12px");
    yAxisGroup.selectAll(".tick line").remove();
    yAxisGroup.select(".domain").remove();

    // Line Generator
    const lineGenerator = d3
      .line()
      .curve(d3.curveMonotoneX)
      .x((d) => xScale(labels[d.x - 1]) + xScale.bandwidth() / 2)
      .y((d) => yScale(d.y));

    // Draw Line
    svg
      .append("path")
      .datum(data)
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", "#A2F0C6")
      .attr("stroke-width", 2)
      .attr("d", lineGenerator);

    // Draw Circles
    svg
      .selectAll(".circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "circle")
      .attr("cx", (d) => xScale(labels[d.x - 1]) + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.y))
      .attr("r", 4)
      .attr("fill", "#A2F0C6")
      .on("mouseover", function (event, d) {
        const content = {
          "Average Duration (in years)": d.y
        };

        const svgRect = svgRef.current.getBoundingClientRect();
        const x = event.clientX - svgRect.left;
        const y = event.clientY - svgRect.top;

        // Adjust tooltip position based on y value
        if (d.y === d3.max(data, (item) => item.y)) {
            setTooltipPosition({ top: y + 30, left: x });
        } else {
            setTooltipPosition({ top: y + 60, left: x });
        }

        setTooltipContent(content);

        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", 6);
      })
      .on("mouseout", function () {
        setTooltipContent(null);

        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", 4);
      });

  }, [data, labels, dimensions]);

  return (
    <div ref={containerRef} style={{ width: "100%", height: "280px",position: "relative" }}>
      <svg ref={svgRef} style={{ width: "100%", height: "100%" }} />
      {tooltipContent && (
        <div
        className="tooltip"
        style={{
          position: "absolute",
          top: tooltipPosition.top - 120,
          left: tooltipPosition.left,
          transform: "translateX(-50%)",
          backgroundColor: "white",
          padding: "12px 16px",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          fontSize: "14px",
          zIndex: 10,
          minWidth: "150px",
          pointerEvents: "none",
          display: "flex",
          flexDirection: "column",
          gap: "4px"
        }}
      >
        {Object.entries(tooltipContent).map(([label, value]) => (
          <div key={label} style={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
            <span style={{ color: "#666" }}>{label}</span>
            <span style={{ fontWeight: "600" }}>{value}</span>
          </div>
        ))}
        <div
          style={{
            position: "absolute",
            bottom: "-8px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "0",
            height: "0",
            borderLeft: "8px solid transparent",
            borderRight: "8px solid transparent",
            borderTop: "8px solid white",
            filter: "drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1))"
          }}
        />
      </div>
      )}

    </div>
  );
};

export default LineChart;
