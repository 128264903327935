import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import moment from "moment";
import 'moment-timezone'

//style
import Styles from './style'

//Components
import { FilterPanel, MultiSelectAsync, GroupingSelect } from "../../components";
import PropertyOwnerInvite from "./inviteOwner";
import FilterDrawer from "./filterDrawer";

// reducer
import { getPODetailRequest, updatePOViewType, downloadPORequest, updatePOFilter, getPOLeftPanelListRequest, clearPOFilter } from "../../redux/reducer/propertyOwnerReducer";

// service
import { globalFilterService } from "../../redux/service";

// helpers
import { hasFilter, poListViewSort, poPanelViewSort } from "../../helpers";

const TopPanel = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { IconsList, handleFilter, handleSorting, sortValue } = props;

    // navigator
    const navigate = useNavigate();
    const { pathname } = useLocation()

    // redux
    const { viewType, panelView: { data, pagination, pagination: { count } }, ownerDetail, filter, tmpfilter, clearfilter } = useSelector(s => (s.propertyOwner))
    const { loggedUser } = useSelector(s => (s.profile))

    // state
    const [invitePopup, setInvitePopup] = useState(null);
    const [openFilter, setOpenFilter] = useState(false)

    //variables
    const isCityStaff = loggedUser.user_group_id === 3;
    const tz = moment.tz.guess()

    // handle page changes
    useEffect(() => {
        if (viewType === "list" && pathname === "/property-owner") {
            navigate(`/property-owner/list`)
        } else if (viewType === "panel" && pathname === "/property-owner/list") {
            navigate(`/property-owner`)
        }
    }, [dispatch, pathname])

       // call api whenever clear filter is triggered
       useEffect(() => {
        if (clearfilter) {
            handleFilter()
        }
        }, [clearfilter])

    //Previous
    const handlePrevious = () => {
        const currentOwnerIndex = data.findIndex(f => (f.id === ownerDetail.id))
        if (currentOwnerIndex > 0) {
            dispatch(getPODetailRequest(data[currentOwnerIndex - 1]))
        }
    };

    //Next
    const handleNext = () => {
        const currentOwnerIndex = data.findIndex(f => (f.id === ownerDetail.id))
        if (currentOwnerIndex < data.length - 1) {
            dispatch(getPODetailRequest(data[currentOwnerIndex + 1]))
        }
        if ((currentOwnerIndex === data.length - 2) && (data.length < count)) {
            dispatch(getPOLeftPanelListRequest({ ...pagination, filter: filter }))
        }
    };

    const updateView = (value) => {
        dispatch(updatePOViewType(value))
        if (value === "panel") {

            navigate(`/property-owner`)
        } else {

            navigate(`/property-owner/list`)
        }
    }

    const getPropertyManagerFilter = async (value) => {
        const response = await globalFilterService.getPMFilterList({ name: value || "" })
        return response
    }

    const getAllPropertyOwnerFilter = async (value) => {
        const response = await globalFilterService.getAllPOFilterList({ name: value || "" })
        return response
    }

    return (
        <FilterPanel
            IconsList={IconsList}
            viewType={viewType}
            isFilterApplied = {hasFilter(tmpfilter)}
            setViewType={(value) => { if (value !== null) {updateView(value)} }}
            handleAdd={(e) => {   console.log("add clicked", e.currentTarget);
                setInvitePopup(e.currentTarget) }}
            handleFilter={(event) => { setOpenFilter(event.currentTarget) }}
            handleDownload={() => { dispatch(downloadPORequest({ ...pagination, filter: filter, timezone: tz })) }}
            handlePrevious={() => { handlePrevious() }}
            handleNext={() => { handleNext() }}
            handleClearFilter={() => { dispatch(clearPOFilter()) }}
            downloadDisabled={count===0}
        >
            <Grid className="filterFields" container spacing={2} sx={{ pl: 2 }}>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        variant={"outlined"}
                        id={"ownerFilter"}
                        name={"ownerFilter"}
                        placeholder={"Property Owner"}
                        className={`sm`}
                        apiCallback={getAllPropertyOwnerFilter}
                        value={filter.ownerFilter}
                        handleChange={(e) => { dispatch(updatePOFilter(e)) }}
                        initialValue={filter.ownerFilter}
                        matchBy={"id"}
                        display={"name"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={3} className="pt-0 selectBox">
                    <GroupingSelect
                        value={sortValue}
                        handleSorting={handleSorting}
                        placeholder={"sort by"}
                        options={viewType === "panel" ? poPanelViewSort : poListViewSort}
                    />
                </Grid>
            </Grid>
            <PropertyOwnerInvite
                invitePopup={invitePopup}
                setInvitePopup={setInvitePopup}
            />
            {openFilter && <FilterDrawer openFilter={openFilter} setOpenFilter={setOpenFilter} handleFilter={handleFilter} />}
        </FilterPanel>
    );
}

// default props
TopPanel.defaultProps = {
    classes: {},
    IconsList: [],
    handleFilter: () => { },
    handleSorting: () => { },
    sortValue: ""
};

// prop types
TopPanel.propTypes = {
    classes: PropTypes.object,
    handleFilter: PropTypes.func
};

export default styled(TopPanel)(Styles);