import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ReCAPTCHA from "react-google-recaptcha";

//style
import Styles from "./style";
import {
  EmailIcon,
  UserIcon,
  PhoneIcon,
  CompanyIcon,
  HomeIcon,
} from "../../assets/svg";

//Components
import {
  Button,
  TextField,
  AsyncAutoComplete,
  InputMaskField,
} from "../../components";

//epic
import {
  updateSignupData,
  signupRequest,
  updateIsSignup,
  setSelectedUserGroupId,
} from "../../redux/reducer/authReducer";
import { errorMessage } from "../../redux/reducer/uiReducer";

//service
import { locationService } from "../../redux/service";

// helpers
import {
  commonValidation,
  emailToLower,
  isEmailValid,
  isPhoneNumber,
  isRequired,
  isZipCode,
} from "../../helpers";

function Signup(props) {
  //define dispatch
  const dispatch = useDispatch();
  // route
  const navigate = useNavigate();

  // props
  const { className } = props;

  //get reducer data
  const { signupForm, isSignup, selectedUserGroupId } = useSelector(
    (state) => state.auth
  );

  //set state
  const [validate, setValidate] = useState(false);
  const [captcha, setCaptcha] = useState(null);

  // Set default user_group_id
  useEffect(() => {
    if (!selectedUserGroupId) {
      // Default to Property Manager
      dispatch(setSelectedUserGroupId("2"));
    }
  }, [dispatch, selectedUserGroupId]);

  // Navigate to success page after signup
  useEffect(() => {
    if (isSignup) {
      navigate("/success");
      dispatch(updateIsSignup());
    }
  }, [isSignup, dispatch, navigate]);

  // Fetch city options
  async function getCityOptions(key) {
    const regionId = signupForm.state?.id || "";
    const cities = await locationService.getCities(key, regionId);
    return cities;
  }

  // Fetch state options
  async function getStateOptions(key) {
    const cityId = signupForm.city?.id || "";
    const states = await locationService.getStates(key, cityId);
    return states;
  }

  // Update form data
  const handleChange = (e) => {
    dispatch(updateSignupData({ ...signupForm, [e.name]: e.value }));
  };

  // Submit form
  const handleSubmit = () => {
    const isInValid = commonValidation(signupForm, "signup");
    setValidate(true);

    if (!isInValid && signupForm.phone && !isPhoneNumber(signupForm.phone)) {
      dispatch(errorMessage("Please enter a valid phone number"));
    } else if (
      !isInValid &&
      signupForm.zipcode &&
      !isZipCode(signupForm.zipcode)
    ) {
      dispatch(errorMessage("Please enter a valid zipcode"));
    } else if (isInValid === "" && !captcha) {
      dispatch(errorMessage("Please accept the captcha"));
    } else if (!isInValid) {
      dispatch(
        signupRequest({
          ...signupForm,
          email: emailToLower(signupForm.email),
          user_group_id: selectedUserGroupId,
        })
      );
      setValidate(false);
    }
  };

  // Determine title based on user_group_id
  const isPropertyOwner = selectedUserGroupId === "4";
  const title = isPropertyOwner
    ? "Sign up as a Property Owner"
    : "Sign up as a Property Manager";

  return (
    <Grid className={className}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid item>
              <Button
                sx={{ fontWeight: 500 }}
                className="backLoginBtn"
                variant="text"
                href="#/"
                startIcon={<ChevronLeftIcon />}
                aria-label="Back to Login page"
              >
                Back to Login page
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="first_name"
            name="first_name"
            aria-label="Enter First Name"
            placeholder="First Name"
            startIcon={<UserIcon />}
            value={signupForm.first_name}
            handleChange={handleChange}
            validation={isRequired(validate, signupForm.first_name)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="last_name"
            name="last_name"
            aria-label="Enter Last Name"
            placeholder="Last Name"
            startIcon={<UserIcon />}
            value={signupForm.last_name}
            handleChange={handleChange}
            validation={isRequired(validate, signupForm.last_name)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="company"
            name="company"
            aria-label="Enter Company Name"
            placeholder="Company Name"
            value={signupForm.company}
            startIcon={<CompanyIcon />}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            id="email"
            name="email"
            aria-label="Enter Email id"
            placeholder="Email id"
            startIcon={<EmailIcon />}
            value={signupForm.email}
            handleChange={handleChange}
            validation={isEmailValid(validate, signupForm.email)}
          />
        </Grid>
        <Grid item xs={4}>
          <InputMaskField
            id="phone"
            name="phone"
            value={signupForm.phone}
            aria-label="Enter Phone Number"
            placeholder="Phone Number"
            startIcon={<PhoneIcon />}
            handleChange={(e) => {
              e.value = e?.value.replace(/[^0-9]/g, "");
              handleChange(e);
            }}
            mask="(999) 999-9999"
            sx={{ marginTop: "0px" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address"
            name="address"
            value={signupForm.address}
            aria-label="Enter Address"
            placeholder="Address"
            startIcon={<HomeIcon />}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <AsyncAutoComplete
            id="city"
            name="city"
            aria-label="Select city"
            value={signupForm.city}
            handleChange={handleChange}
            initialValue={signupForm.city}
            placeholder="City"
            apiCallback={getCityOptions}
            matchBy="id"
            display="name"
          />
        </Grid>
        <Grid item xs={4}>
          <AsyncAutoComplete
            id="state"
            name="state"
            aria-label="Select state"
            value={signupForm.state}
            handleChange={handleChange}
            initialValue={signupForm.state}
            placeholder="State"
            apiCallback={getStateOptions}
            matchBy="id"
            display="name"
          />
        </Grid>
        <Grid item xs={4} className="zipcodeField">
          <TextField
            id="zipcode"
            name="zipcode"
            aria-label="Enter Zip code"
            value={signupForm.zipcode}
            placeholder="Zipcode"
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid className="pt-3">
        <Grid sx={{ transform: "scale(0.77)", transformOrigin: "0 0" }}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_G_CAPTCHA}
            onChange={setCaptcha}
            onExpired={() => setCaptcha(null)}
            onErrored={() => setCaptcha(null)}
          />
        </Grid>
        <Grid align="right">
          <Button
            variant="contained"
            size="large"
            className="loginBtn"
            onClick={handleSubmit}
            aria-label="Signup"
          >
            Sign up
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

Signup.defaultProps = {
  className: {},
};

Signup.propTypes = {
  className: PropTypes.object,
};

export default styled(Signup)(Styles);
