// Import API Helper
import { getRequest, postRequest, deleteRequest } from "../../helpers";

//Get left panel property owner details
async function getPOLeftPanelList(params) {
  const response = await postRequest(`property-owner/left-panel/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, {"filter" : params.filter});
  return response;
}

//Get property owner detail
async function getPODetail(params) {
  const response = await getRequest(`property-owner/detail/${params.id}/`);
  return response;
}

//update property owner flag
async function updatePOFlag(params) {
  const response = await postRequest(`property-owner/update-flag/${params.id}/${params.is_flag}/`);
  return response;
}

//property owner Approval
async function propertyOwnerApproval(params) {
  const response = await postRequest(`property-owner/approval/`, params);
  return response;
}

//Get property owner list
async function getPOList(params) {
  const response = await postRequest(`property-owner/list/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, {"filter" : params.filter});
  return response;
}

/** get property owner total count */
async function getPOCount(params) {
  const response = await postRequest(`property-owner/count/`, {"filter" : params.filter});
  return response;
}

//Send invite emails to property owners
async function sendPOInvites(params) {
  const response = await postRequest('property-owner/invite-property-owners/', params);
  return response;
}

/**
 * get owner Comments
 */
async function getPOComments(params) {
  const response = await getRequest(`property-owner/comments/${params.id}/`);
  return response;
}

/**
 * update owner Comments
 */
async function updatePOComments(params) {
  const response = await postRequest(`property-owner/update-comments/`, params);
  return response;
}

/**
 * delete owner Comments
 */
async function deletePOComments(params) {
  const response = await deleteRequest(`property-owner/delete-comments/${params.id}/`);
  return response;
}

/**
* download property owner reports
*/
async function downloadPOReport(params) {
  const response = await postRequest(`property-owner/download/?sortField=${params.sortField}&sortBy=${params.sortBy}`, {"filter" : params.filter, 'timezone': params.timezone}, false, "arraybuffer");
  return response;
}

// export all property owner functions
export const propertyOwnerService = {
  getPOLeftPanelList,
  getPODetail,
  updatePOFlag,
  propertyOwnerApproval,
  getPOList,
  getPOCount,
  sendPOInvites,
  getPOComments,
  updatePOComments,
  deletePOComments,
  downloadPOReport
};