export const genderOptions = [
  { id: 1, value: "Male" },
  { id: 2, value: "Female" },
  { id: 3, value: "Transgendered Female to Male" },
  { id: 4, value: "Transgendered Male to Female" },
  { id: 5, value: "Non-Binary" },
  { id: 6, value: "Other" },
  { id: 7, value: "Prefer not to say" },
]

export const statusOptions = [
  { id: "1", value: "Active" },
  { id: "0", value: "Inactive" },
]

export const pmStatusOptions = [
  { id: "1", value: "Review Pending" },
  { id: "2", value: "Approved" },
  { id: "3", value: "Rejected" },
]

export const poStatusOptions = [
  { id: "1", value: "Review Pending" },
  { id: "2", value: "Approved" },
  { id: "3", value: "Rejected" },
]

export const propertyManagerStatusOptions = {
  // "1": "Review Pending",
  "2": "Approve",
  "3": "Reject"
}

export const propertyOwnerStatusOptions = {
  // "1": "Review Pending",
  "2": "Approve",
  "3": "Reject"
}

export const propertyManagerStatusPlOptions = {
  "1": "Review Pending",
  "2": "Approved",
  "3": "Rejected"
}

export const propertyOwnerStatusPlOptions = {
  "1": "Review Pending",
  "2": "Approved",
  "3": "Rejected"
}

export const applicationsStatusOptions = {
  // "1": "Review Pending",
  "2": "Pre-Approve",
  "3": "Reject",
}

export const tenantsStatusOptions = {
  "2": "Move-In",
  "3": "Move-Out"
}

export const tenantsStatusPlOptions = {
  "1": "Tenant",
  "2": "Moved-In",
  "3": "Moved-Out"
}

export const applicationsStatusPlOptions = {
  "1": "Review Pending",
  "2": "Pre-Approved",
  "3": "Rejected",
  "4": "Waiting List",
  "5": "Document Requested",
  "6": "Document Verification",
  "7": "Document Approved",
  "8": "Document Rejected",
  "9": "Offered",
  "10": "Tenant",
  "11": "Offer Denied",
  "12": "Application Withdrawn",
  "13": "Offer Declined",
  "14": "Application Rejected",
}

export const waitingListStatusOptions = {
  "4": "Waiting List",
  "5": "Document Requested",
  "6": "Document Verification",
  "7": "Document Approved",
  "8": "Document Rejected",
  "9": "Offered",
  "10": "Tenant",
  "11": "Offer Denied",
  "12": "Application Withdrawn",
  "13": "Offer Declined",
  "14": "Application Rejected",
}

export const waitingListStatusPlOptions = {
  "4": "Waiting List",
  "5": "Document Requested",
  "6": "Document Verification",
  "7": "Document Approved",
  "8": "Document Rejected",
  "9": "Offered",
  "10": "Tenant",
  "11": "Offer Denied",
  "12": "Application Withdrawn",
  "13": "Offer Declined",
  "14": "Application Rejected",
}

export const preferenceOptions = [
  { value: "Senior-Citizen", id: 1 },
  { value: "Disability", id: 2 },
  { value: "Veteran", id: 3 },
  { value: "Artist", id: 5 },
  { value: "N/A", id: 4}
]

export const reportPeriodOptions = [
  { value: "Quarter", id: 1 },
  { value: "Bi-Annual", id: 2 },
  { value: "Quarter and Bi-Annual", id: 3 },
]

export const amiRmiOptions = [
  { value: "AMI%", id: 1 },
  { value: "RMI%", id: 2 },
]

export const adaOptions = [
  { value: "Yes", id: "1" },
  { value: "No", id: "0" },
]

export const propertiesStatusOptions = {
  // "1": "Review Pending",
  "2": "Approve",
  "3": "Reject"
}

export const propertiesStatusPlOptions = {
  "": "Draft",
  "0" : "Draft",
  "1": "Review Pending",
  "2": "Approved",
  "3": "Rejected"
}


export const affordabilityLevelOptions = [
  { value: "Very Low", id: 1 },
  { value: "Low", id: 2 },
  { value: "Moderate", id: 3 },
  { value: "Workforce", id: 4 },
]

export const unitTypesOptions = [
  { id: 1, name: "1-Bedroom" },
  { id: 2, name: "2-Bedroom" },
  { id: 3, name: "3-Bedroom" },
  { id: 4, name: "4-Bedroom" },
  { id: 7, name: "Studio" },
]

export const defaultFlagOptions = [
  { value: "Flagged", id: "1" },
  { value: "Un-Flagged", id: "0" },
]

export const defaultPropertyCountOptions = [
  { value: "0", id: 0 },
  { value: "1", id: 1 },
  { value: "2", id: 2 },
  { value: "3", id: 3 },
  { value: "4", id: 4 },
  { value: "5", id: 5 },
  { value: "6", id: 6 },
  { value: "7", id: 7 },
  { value: "8", id: 8 },
  { value: "9", id: 9 },
  { value: "10", id: 10 },
  { value: "11", id: 11 },
  { value: "12", id: 12 },
  { value: "13", id: 13 },
  { value: "14", id: 14 },
  { value: "15", id: 15 },
]

export const defaultUnitTypeOptions = [
  { value: "1-Bedroom", id: 1 },
  { value: "2-Bedroom", id: 2 },
  { value: "3-Bedroom", id: 3 },
  { value: "4-Bedroom", id: 4 },
  { value: "Studio", id: 7 },
]

export const defaultHouseholdSizeOptions = [
  { value: "1", id: 1 },
  { value: "1-2", id: 2 },
  { value: "2-4", id: 3 },
  { value: "4-6", id: 4 },
  { value: "8-10", id: 5 },
  { value: "10-12", id: 6 }, ,
]

export const  offerValidityOptions = [
  { value: "7 Days", id: "7" },
  { value: "8 Days", id: "8" },
  { value: "9 Days", id: "9" },
  { value: "10 Days", id: "10" },
  { value: "11 Days", id: "11" },
  { value: "12 Days", id: "12" },
  { value: "13 Days", id: "13" },
  { value: "14 Days", id: "14" },
  { value: "15 Days", id: "15" },
  { value: "16 Days", id: "16" },
  { value: "17 Days", id: "17" },
  { value: "18 Days", id: "18" },
  { value: "19 Days", id: "19" },
  { value: "20 Days", id: "20" },
  { value: "21 Days", id: "21" },
  { value: "22 Days", id: "22" },
  { value: "23 Days", id: "23" },
  { value: "24 Days", id: "24" },
  { value: "25 Days", id: "25" },
  { value: "26 Days", id: "26" },
  { value: "27 Days", id: "27" },
  { value: "28 Days", id: "28" },
  { value: "29 Days", id: "29" },
  { value: "30 Days", id: "30" },
]

export const defaultApplicationStatusOptions = [
  { value: "Review Pending", id: "1" },
  { value: "Pre-approved", id: "2" },
  { value: "Rejected", id: "3" },
]

export const defaultWaitingListStatusOptions = [
  { value: "Waiting List", id: "4" },
  { value: "Document Requested", id: "5" },
  { value: "Document Verification", id: "6" },
  { value: "Document Approved", id: "7" },
  { value: "Document Rejected", id: "8" },
  { value: "Offered", id: "9" },
  { value: "Tenant", id: "10" },
  { value: "Offer Denied", id: "11" },
  { id: "12", value: "Application Withdrawn" },
  { id: "13", value: "Offer Declined" },
  { id: "14", value: "Application Rejected" },
]

export const pmListViewSort = [
  {
    name: "name",
    display: "Name",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "approval_status",
    display: "Approval Status",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "id",
    display: "Submitted Date",
    fields: [
      { name: "asc", display: "Oldest to Newest" }, { name: "desc", display: "Newest to Oldest" }
    ]
  },
]

export const pmPanelViewSort = [
  {
    name: "name",
    display: "Name",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "viewed",
    display: "Viewed",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "ap_status",
    display: "Approval Status",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "id",
    display: "Submitted Date",
    fields: [
      { name: "asc", display: "Oldest to Newest" }, { name: "desc", display: "Newest to Oldest" }
    ]
  },
]

export const poListViewSort = [
  {
    name: "name",
    display: "Name",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "approval_status",
    display: "Approval Status",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "id",
    display: "Submitted Date",
    fields: [
      { name: "asc", display: "Oldest to Newest" }, { name: "desc", display: "Newest to Oldest" }
    ]
  },
]

export const poPanelViewSort = [
  {
    name: "name",
    display: "Name",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "viewed",
    display: "Viewed",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "ap_status",
    display: "Approval Status",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "id",
    display: "Submitted Date",
    fields: [
      { name: "asc", display: "Oldest to Newest" }, { name: "desc", display: "Newest to Oldest" }
    ]
  },
]

export const propertiesListViewSort = [
  {
    name: "name",
    display: "Name",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "ap_status",
    display: "Approval Status",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "updated_date",
    display: "Updated Date",
    fields: [
      { name: "asc", display: "Oldest to Newest" }, { name: "desc", display: "Newest to Oldest" }
    ]
  },
]

export const propertiesPanelViewSort = [
  {
    name: "name",
    display: "Name",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "ap_status",
    display: "Approval Status",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "viewed",
    display: "Viewed",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "updated_date",
    display: "Updated Date",
    fields: [
      { name: "asc", display: "Oldest to Newest" }, { name: "desc", display: "Newest to Oldest" }
    ]
  },
]

export const applicationsListViewSort = [
  {
    name: "name",
    display: "Name",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "ap_status",
    display: "Approval Status",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "submitted_at",
    display: "Submitted Date",
    fields: [
      { name: "asc", display: "Oldest to Newest" }, { name: "desc", display: "Newest to Oldest" }
    ]
  },
]

export const applicationsPanelViewSort = [
  {
    name: "name",
    display: "Name",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "ap_status",
    display: "Approval Status",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "viewed",
    display: "Viewed",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "submitted_at",
    display: "Submitted Date",
    fields: [
      { name: "asc", display: "Oldest to Newest" }, { name: "desc", display: "Newest to Oldest" }
    ]
  },
]

export const waitingListListViewSort = [
  {
    name: "name",
    display: "Name",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "ap_status",
    display: "Approval Status",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "lottery_number",
    display: "Lottery Number",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
  {
    name: "lottery_index",
    display: "Lottery Date",
    fields: [
      { name: "asc", display: "ASC" }, { name: "desc", display: "DESC" }
    ]
  },
]

export const rejectionReasonOptions = [
  { id: 1, name: "Applicant did not respond to the notification of an available unit within the specified period of time" },
  { id: 2, name: "Applicant is not interested in the available unit" },
  { id: 3, name: "Applicant did not provide the required income documentation within the specified period of time" },
  { id: 4, name: "Applicant's income documentation indicates that they do not income-qualify" },
  { id: 5, name: "Applicant did not provide the required documentation to confirm household composition within the specified period of time" },
  { id: 6, name: "Applicant did not provide the required documentation to confirm assets within the specified period of time" },
  { id: 7, name: "There was a willful or material misstatement of fact made by the applicant" },
  { id: 8, name: "Applicant did not have sufficient funds for a security deposit" },
  { id: 9, name: "Applicant did not have sufficient funds for a down payment (sales units)" },
  { id: 10, name: "Applicant's credit score was too low" },
  { id: 11, name: "After a conditional offer was made to the applicant, the conditional offer was withdrawn following an individualized assessment of the applicant's criminal background, and a Notice of Withdrawal was issued in accordance with the NJ Fair Chance in Housing Act (N.J.S.A. 46:8-52)" }
]

export const preapprovalRejectionReasonOptions = [
  { id: 1, name: "The household did not complete the application" },
  { id: 2, name: "The household does not income qualify" }
]

export const moveOutReasonOptions = [
  { id: 1, name: "The tenant has chosen not to renew their lease. " },
  { id: 2, name: "The tenant has failed to pay rent." },
  { id: 3, name: "The tenant has damaged the unit beyond normal wear and tear. " },
  { id: 4, name: "Tenants are not using the unit as their primary residence. " },
  { id: 5, name: "Tenant is subletting the entire or a portion of the unit. " },
]

export const incomeRentDocsCategoryOptions = [
  { id: 1, name: "UHAC" },
  {id: 2, name: "NJHMFA"},
  {id: 3, name: "Other"}
]

export const incomeRentDocsYearOptions = [
  { id: 2019, name: 2019 },
  { id: 2020, name: 2020 },
  { id: 2021, name: 2021 },
  { id: 2022, name: 2022 },
  { id: 2023, name: 2023 },
  { id: 2024, name: 2024 },
]

export const reportsPeriodOptions = [
  { id: 1, name: 'Quarter 1' },
  { id: 2, name: 'Quarter 2'},
  { id: 3, name: 'Quarter 3'},
  { id: 4, name: 'Quarter 4'},
  { id: 5, name: 'Bi-Annual 1'},
  { id: 6, name: 'Bi-Annual 2'},
  { id: 7, name: 'Annual'},
]

export const referenceOptions = [
  {id: 1, name: 'Obligation & City Policy'},
  {id: 2, name: 'Income & Rent Limit'},
  {id: 3, name: 'Resources'}
]